import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Delete, Edit } from "@mui/icons-material";
import { Link } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import axios from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import { switchUrls } from "../../api/";
import { Snackbar, Alert as MuiAlert } from "@mui/material";

const baseURL = switchUrls("cylinder");

const getAllCompanies = async () => {
  const { data } = await axios.post(
    `${baseURL}/api/company/getAllCompanies`,
    {},
    {
      "Content-Type": "application/json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return data;
};
const CompanyDetails = () => {
  // QUERY : Get Companies Data
  const { data, error, isLoading } = useQuery({
    queryKey: ["allCylinders"],
    queryFn: getAllCompanies,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
    retry: 2,
  });

  // MUTATION : DELETE COMPANY
  const { mutate } = useMutation({
    mutationFn: async (companyId) => {
      return await axios.post(
        `${baseURL}/api/company/deleteCompany`,
        { companyId },
        {
          "Content-Type": "application/json",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    },
    onSuccess: (data) => {
      // console.log("success", data);
      setSnackbarInfo({
        isOpenClose: true,
        message: "Company Deleted Successfully",
        color: "success",
      });
      windowsReload();
    },
    onError: (error) => {
      setSnackbarInfo({
        isOpenClose: true,
        message: error.response.data.msg,
        color: "error",
      });
    },
  });

  //  SNACKBAR
  const [snackbarInfo, setSnackbarInfo] = useState({
    isOpenClose: false,
    message: "",
    color: "",
  });
  const handleCloseSnackbar = () => {
    setSnackbarInfo({
      isOpenClose: false,
    });
  };
  const autoCloseSnackbar = () => {
    setSnackbarInfo({
      isOpenClose: false,
    });
  };

  const onClickDelteCompany = (id) => {
    console.log("delete copany");
    mutate(id);
  };

  const windowsReload = () => {
    window.location.reload();
  };
  const ActionBtnRenderer = ({ value }) => {
    return (
      <p>
        <Link to={`/company/${value}`}>
          <Edit color="success" className="cursor-pointer me-2" />
        </Link>
        <Link onClick={() => onClickDelteCompany(value)}>
          <Delete color="error" className="cursor-pointer ms-2" />
        </Link>
      </p>
    );
  };

  const RenderTimeDate = ({ value }) => {
    const toReadableDateTime = (st) => {
      if (typeof st !== "string") return null;

      const [date, time] = st?.split("T");

      if (!time && date) return date.split("-").reverse().join("-");

      if (!date || !time) return null;

      return `${date.split("-").reverse().join("-")} ${time.slice(0, 5)}`;
    };

    return <p className="">{toReadableDateTime(value)}</p>;
  };

  const columnDefs = [
    { headerName: "Company Name", field: "name" },
    { headerName: "Company Type", field: "companyType" },
    { headerName: "Email", field: "email" },
    { headerName: "Owner First Name", field: "ownerFirstName" },
    { headerName: "Owner Last Name", field: "ownerLastName" },
    { headerName: "Phone", field: "phone" },
    { headerName: "Company ID", field: "companyId" },
    { headerName: "Company Role", field: "role" },
    {
      headerName: "Date Stamps",
      field: "createdOn",
      cellRenderer: RenderTimeDate,
    },
    {
      headerName: "Actions",
      field: "companyId",
      cellRenderer: ActionBtnRenderer,
    },
  ];
  const [rowData, setRowData] = useState([]);

  // USEEFFECT : Companies Details
  useEffect(() => {
    if (error) {
      console.log("error: ", error);
    }
    if (!isLoading && !error) {
      if (data) {
        // console.log("getAllCompanies all : ", data);
        setRowData(data?.data);
      }
    }
  }, [isLoading, error, data]);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <>
      <Navbar />
      {/* ______________ Data Grid ______________ */}
      <div className="data-grid-main-div d-flex justify-content-center align-content-center">
        <div
          className="ag-theme-alpine data-grid-div"
          style={{ height: "33.5rem", width: "99%" }}
        >
          <AgGridReact
            columnDefs={columnDefs}
            rowData={rowData}
            pagination={true}
            paginationPageSize={10}
            defaultColDef={{
              // flex: 1,
              resizable: true,
            }}
            overlayLoadingTemplate={
              '<span className="ag-overlay-loading-center">Please wait while your rows are loading...</span>'
            }
            overlayNoRowsTemplate={
              '<span className="ag-overlay-loading-center">No data found to display.</span>'
            }
          />
        </div>
      </div>

      {/* Snack Bar */}
      <Snackbar
        open={snackbarInfo.isOpenClose}
        onClose={autoCloseSnackbar}
        autoHideDuration={5000}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        style={{ top: "25%" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarInfo.color}
          sx={{ width: "100%" }}
        >
          {snackbarInfo.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CompanyDetails;
