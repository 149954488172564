import React, { useState, useEffect } from "react";
import "./scan.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  styled,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { PinDrop } from "@mui/icons-material";
// import Empty from "../../assets/empty.png";
// import Full from "../../assets/full.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import axios from "axios";
import { switchUrls } from "../../api";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
const baseURL = switchUrls("cylinder");

// get MasterData function
const getMasterData = async () => {
  const { data } = await axios.post(
    `${baseURL}/api/cylinderqr/getMasterCylinderDetails`,
    {},
    {
      "Content-Type": "application/json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return data;
};
const getCylinderInfo = async (tagnumber) => {
  const res = await axios.post(`${baseURL}/api/cylinderqr/getCylinderDetails`, {
    tagNumber: tagnumber,
  });
  return res.data;
};

// Get Transaction : STATUS & LOCATION
const getTransactionInfo = async (tagnumber) => {
  const { data } = await axios.post(
    `${baseURL}/api/cylinderqr/getTagTransactionHistoryLocation`,
    { tagNumber: tagnumber }
  );
  return data;
};

// Get Maintenance
const getMaintenanceInfo = async (tagnumber) => {
  const { data } = await axios.post(
    `${baseURL}/api/cylinderqr/getTagTransactionMaintenance`,
    { tagNumber: tagnumber }
  );
  return data;
};

const Scan = () => {
  // Flag Related State
  // eslint-disable-next-line
  const [flag, setFlag] = useState({
    isAdmin:
      localStorage.getItem("role") === "admin" ||
      localStorage.getItem("role") === "superadmin"
        ? true
        : false,
    editOnlyAdmin:
      localStorage.getItem("role") === "admin" ||
      localStorage.getItem("role") === "superadmin"
        ? true
        : false,
    editOnlyUser:
      localStorage.getItem("role") === "user" ||
      localStorage.getItem("role") === "admin" ||
      localStorage.getItem("role") === "superadmin"
        ? true
        : false,
    isActive: true,
    isAuth: localStorage.getItem("token") ? true : false,
  });
  // QUERY : Get Master Data
  const {
    data: masterDataDetails,
    error: errorMasterData,
    isLoading: isLoadingMasterData,
  } = useQuery({
    queryKey: ["masterdatas"],
    queryFn: getMasterData,
    enabled: !!flag.editOnlyUser,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
    retry: 2,
  });

  let { tag_number } = useParams();
  // QUERY : Get Cylinder Data
  const { data, error, isLoading } = useQuery({
    queryKey: ["cylinder"],
    queryFn: () => getCylinderInfo(tag_number),
    enabled: !!tag_number,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
    retry: 2,
  });

  // QUERY : Get Transaction details
  const {
    data: transactionDetails,
    error: errorTransaction,
    isLoading: isLoadingtransaction,
  } = useQuery({
    queryKey: ["transactionInfo"],
    queryFn: () => getTransactionInfo(tag_number),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
    retry: 2,
  });

  // QUERY : Get Maintenance details
  const {
    data: maintenanceDetails,
    error: errorMaintenance,
    isLoading: isLoadingMaintenance,
  } = useQuery({
    queryKey: ["maintenanceInfo"],
    queryFn: () => getMaintenanceInfo(tag_number),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
    retry: 2,
  });

  // MUTATION : STATUS & LOCATION
  const { mutate: addingTransaction, isPending: isPendingTransaction } =
    useMutation({
      mutationFn: async (transactionObj) => {
        return await axios.post(
          `${baseURL}/api/cylinderqr/cylinderCheckinCheckout`,
          transactionObj,
          {
            "Content-Type": "application/json",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      },
      onSuccess: (data) => {
        // console.log("success", data);

        setSnackbarInfo({
          isOpenClose: true,
          message: "Master data Added Successfully",
          color: "success",
        });
        windowsReload();
      },
      onError: (error) => {
        // console.log("error", error);
        setSnackbarInfo({
          isOpenClose: true,
          message: error.response.data.msg,
          color: "error",
        });
      },
    });

  // MUTATION : MAINTENANCE
  const { mutate: addingMaintenance, isPending: isPendingMaintenance } =
    useMutation({
      mutationFn: async (maintenanceObj) => {
        return await axios.post(
          `${baseURL}/api/cylinderqr/addMaintenanceDetails`,
          maintenanceObj,
          {
            "Content-Type": "application/json",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      },
      onSuccess: (data) => {
        // console.log("success", data);
        // setTestParameters((preState) => ({
        //   ...preState,
        //   testParameterDetails: [
        //     data.data.data,
        //     ...preState.testParameterDetails,
        //   ],
        //   hydrotesting_date: "",
        //   tare_weight: "",
        //   test_comment: "",
        // }));
        setSnackbarInfo({
          isOpenClose: true,
          message: "Maintenance History Added Successfully",
          color: "success",
        });
        windowsReload();
      },
      onError: (error) => {
        // console.log("error", error);
        setSnackbarInfo({
          isOpenClose: true,
          message: error.response.data.msg,
          color: "error",
        });
      },
    });

  // MASTER DATA STATE
  const [masterDataInfoState, setMasterDataInfoState] = useState({
    cylinderTypeArray: [],
    cylinderMaterialArray: [],
    cylinderBrandArray: [],
    valveBrandArray: [],
    cylinderLocationArray: [],
    plantLocationArray: [],
  });

  const [addEditCylinder, setAddEditCylinder] = useState("addCylinderDetails");

  let currentYear = new Date();
  currentYear = dayjs(currentYear);

  //  SNACKBAR
  const [snackbarInfo, setSnackbarInfo] = useState({
    isOpenClose: false,
    message: "",
    color: "",
  });
  const handleCloseSnackbar = () => {
    setSnackbarInfo({
      isOpenClose: false,
    });
  };
  const autoCloseSnackbar = () => {
    setSnackbarInfo({
      isOpenClose: false,
    });
  };

  // Company Name state
  const [tagCompanyName, setTagCompanyName] = useState("");
  // CYLINDER DETAILS STATE & FUNCTIONS
  const [cylinderDetails, setCylinderDetails] = useState({
    cylinder_code: "",
    cylinder_type: "",
    cylinder_brand: "",
    cylinder_mfg_date: "",
    cylinder_material: "",
    cylinder_location: "",
    peso_approval_no: "",
    peso_approval_date: "",
    original_tare_weight: "",
    creation_date: "",
    created_by: "",
    tested_by: "",
  });

  const handleChangeTextField = (e) => {
    setCylinderDetails({ ...cylinderDetails, [e.target.name]: e.target.value });
    if (e.target.name === "cylinder_code") {
      setcylinderNo(e.target.value);
    }
  };
  const handleCylinderDetailsDateChange = (v) => {
    let d = new Date(v).toLocaleDateString().split("/");
    const dbDateFormate = `${d[2]}-${d[0] >= 10 ? d[0] : `0${d[0]}`}-${
      d[1] >= 10 ? d[1] : `0${d[1]}`
    }`;
    // console.log("date: ", dbDateFormate);
    setCylinderDetails({
      ...cylinderDetails,
      cylinder_mfg_date: dbDateFormate,
    });
  };
  const handleCylinderPesoDateChange = (v) => {
    let d = new Date(v).toLocaleDateString().split("/");
    const dbDateFormate = `${d[2]}-${d[0] >= 10 ? d[0] : `0${d[0]}`}-${
      d[1] >= 10 ? d[1] : `0${d[1]}`
    }`;
    // console.log("date: ", dbDateFormate);
    setCylinderDetails({
      ...cylinderDetails,
      peso_approval_date: dbDateFormate,
    });
  };

  const [cylinderNo, setcylinderNo] = useState("");
  // useEffect For Searching Cylinder Details by Entering cylinder code
  useEffect(() => {
    const fetchData = async (cylinderSerialNo) => {
      const { data } = await axios.post(
        `${baseURL}/api/cylinderqr/getCylinderDetailsByCylinderNo`,
        {
          cylinderSerialNo,
        },
        {
          "Content-Type": "application/json",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return data;
    };
    const timeToSearch = setTimeout(async () => {
      if (localStorage.getItem("token") && cylinderNo) {
        const data = await fetchData(cylinderNo);
        // console.log("data cylinder: ", data);
        if (!data.isError) {
          if (data.tagStatus === "active") {
            if (tag_number !== data?.data?.tagNumber) {
              setFlag((preState) => ({ ...preState, isActive: false }));
              setSnackbarInfo({
                isOpenClose: true,
                message: `This Cylinder No is already attached with the Tag No ${data?.data?.tagNumber} `,
                color: "error",
              });
            } else {
              setFlag((preState) => ({ ...preState, isActive: true }));
            }
          } else {
            setFlag((preState) => ({ ...preState, isActive: true }));
          }

          setCylinderDetails({
            cylinder_code: data?.data?.cylinderSerialNo,
            cylinder_type: data?.data?.typeOfCylinder,
            cylinder_brand: data?.data?.cylinderBrand,
            cylinder_mfg_date: data?.data?.cylinderMfgDate,
            cylinder_material: data?.data?.cylinderMaterial,
            cylinder_location: data?.data?.cylinderLocation,
            peso_approval_no: data?.data?.pesoApprovalNo,
            peso_approval_date: data?.data?.pesoApprovalDate,
            original_tare_weight: data?.data?.originalTareWeight,
            creation_date: data?.data?.creationDate?.split("T")[0],
            created_by: data?.data?.createdBy,
            tested_by: data?.data?.testedBy,
          });
          setValveDetails({
            valve_brand: data?.data?.valveBrand,
            valve_mfg_year: data?.data?.valveMfgDate,
          });
          setSpecification({
            working_pressure: data?.data?.workingPressure,
            test_pressure: data?.data?.testPressure,
            water_capacity: data?.data?.waterCapacity,
            test_frequency: data?.data?.testFrequency,
            gas_capacity: data?.data?.gasCapacity,
            specification: data?.data?.specification,
            test_frequency_num: data?.data?.testFrequency?.split(" ")[0],
            test_frequency_text: data?.data?.testFrequency?.split(" ")[1],
            hydrotest_due_date:
              data?.data?.testFrequencyDueDate === undefined
                ? ""
                : data?.data?.testFrequencyDueDate?.split("T")[0],
          });
        } else {
          setCylinderDetails({
            cylinder_code: cylinderNo,
            cylinder_type: "",
            cylinder_brand: "",
            cylinder_mfg_date: "",
            cylinder_material: "",
            cylinder_location: "",
            peso_approval_no: "",
            peso_approval_date: "",
            creation_date: "",
            created_by: "",
            tested_by: "",
          });
          setValveDetails({
            valve_brand: "",
            valve_mfg_year: "",
          });
          setSpecification({
            working_pressure: "",
            test_pressure: "",
            water_capacity: "",
            test_frequency: "",
            test_frequency_num: "",
            test_frequency_text: "",
            hydrotest_due_date: "",
          });
        }
      }
    }, 500);
    return () => {
      clearTimeout(timeToSearch);
    };
    // eslint-disable-next-line
  }, [cylinderNo]);

  // VALVE STATE & FUNCTIONS
  const [valveDetails, setValveDetails] = useState({
    valve_brand: "",
    valve_mfg_year: "",
  });

  const onchangeValveMfgYear = (v) => {
    let vy = new Date(v);
    // vy = vy.toDateString();
    vy = vy.getFullYear();
    // console.log(vy);
    let y = vy.toString();
    setValveDetails((preState) => ({ ...preState, valve_mfg_year: y }));
  };

  // SPECIFICATION STATE & FUNCTIONS
  const [specification, setSpecification] = useState({
    working_pressure: "",
    test_pressure: "",
    water_capacity: "",
    gas_capacity: "",
    specification: "",
    test_frequency_num: "",
    test_frequency_text: "",
    test_frequency: "",
    hydrotest_due_date: "",
  });

  const handleChangeSpecification = (e) => {
    setSpecification({ ...specification, [e.target.name]: e.target.value });

    if (e.target.name === "test_frequency_num") {
      setSpecification((preState) => ({
        ...preState,
        test_frequency: e.target.value + " " + preState.test_frequency_text,
      }));
    } else if (e.target.name === "test_frequency_text") {
      setSpecification((preState) => ({
        ...preState,
        test_frequency: preState.test_frequency_num + " " + e.target.value,
      }));
    }
  };

  // LOCATION & STATUS STATES FUNCTIONS
  const [statusLocation, setStatusLocation] = useState({
    status: null,
    location: null,
    in_plant_location: "",
    out_of_plant_location: "",
    from_location: "",
    transactionsArray: [],
  });

  const handleChangeStatusLocation = (e) => {
    if (e.target.name === "location") {
      setStatusLocation((preState) => ({
        ...preState,
        location: e.target.value,
        in_plant_location: "",
        out_of_plant_location: "",
      }));
    } else {
      setStatusLocation({ ...statusLocation, [e.target.name]: e.target.value });
    }
  };

  // TEST PARAMETERS STATE & FUNCTIONS
  const [testParameters, setTestParameters] = useState({
    hydrotesting_date: "",
    tare_weight: "",
    test_comment: "",
    testParameterDetails: [],
  });

  const handleChangeTestParameters = (e) => {
    setTestParameters({ ...testParameters, [e.target.name]: e.target.value });
  };

  const handleHydortestingDateChange = (v) => {
    let d = new Date(v).toLocaleDateString().split("/");
    const dbDateFormate = `${d[2]}-${d[0] >= 10 ? d[0] : `0${d[0]}`}-${
      d[1] >= 10 ? d[1] : `0${d[1]}`
    }`;
    setTestParameters({ ...testParameters, hydrotesting_date: dbDateFormate });
  };

  const onClickAddTransaction = () => {
    const { status, location, in_plant_location, out_of_plant_location } =
      statusLocation;
    if (status && location && (in_plant_location || out_of_plant_location)) {
      const transObj = {
        qrCode: tag_number,
        cylinderCode: cylinderDetails.cylinder_code,
        location: location,
        toLocation: in_plant_location || out_of_plant_location,
        cylinderStatus: status,
      };

      addingTransaction(transObj);
    } else {
      setSnackbarInfo({
        isOpenClose: true,
        message: "Please Fill the Required Field ",
        color: "error",
      });
    }
  };
  const onClickAddTestParameters = () => {
    const { hydrotesting_date, tare_weight, test_comment } = testParameters;
    if (hydrotesting_date && tare_weight && test_comment) {
      const transObj = {
        qrCode: tag_number,
        cylinderCode: cylinderDetails.cylinder_code,
        hydrotestDate: hydrotesting_date,
        tareWeight: tare_weight,
        testComment: test_comment,
      };
      addingMaintenance(transObj);
    } else {
      setSnackbarInfo({
        isOpenClose: true,
        message: "Please Fill the Required Field ",
        color: "error",
      });
    }
  };

  const [clickToLogin, setClickToLogin] = useState();
  const handlLogin = () => {
    setClickToLogin(!clickToLogin);
  };

  const windowsReload = () => {
    window.location.reload();
  };

  // CYLINDER FORM SUBMIT HANDLE
  const submitHandle = async (e) => {
    e.preventDefault();
    try {
      const formData = {};
      formData["tagNumber"] = tag_number;
      formData["cylinderSerialNo"] = cylinderDetails.cylinder_code;
      formData["typeOfCylinder"] = cylinderDetails.cylinder_type;
      formData["cylinderBrand"] = cylinderDetails.cylinder_brand;
      formData["cylinderMfgDate"] = cylinderDetails.cylinder_mfg_date;
      formData["cylinderMaterial"] = cylinderDetails.cylinder_material;
      formData["cylinderLocation"] = cylinderDetails.cylinder_location;
      formData["pesoApprovalNo"] = cylinderDetails.peso_approval_no;
      formData["pesoApprovalDate"] = cylinderDetails.peso_approval_date;
      formData["originalTareWeight"] = cylinderDetails.original_tare_weight;

      formData["valveBrand"] = valveDetails.valve_brand;
      formData["valveMfgDate"] = valveDetails.valve_mfg_year;

      formData["workingPressure"] = specification.working_pressure;
      formData["testPressure"] = specification.test_pressure;
      formData["waterCapacity"] = specification.water_capacity;
      formData["gasCapacity"] = specification.gas_capacity;
      formData["specification"] = specification.specification;
      formData["testFrequency"] = specification.test_frequency;

      // formData["status"] = statusLocation.status;
      // formData["location"] = statusLocation.location;
      // formData["inPlantLocation"] = statusLocation.in_plant_location;
      // formData["outOfPlantLocation"] = statusLocation.out_of_plant_location;

      // formData["hydrotestingDate"] = testParameters.hydrotesting_date;
      // formData["tareWeight"] = testParameters.tare_weight;
      // formData["testComment"] = testParameters.test_comment;

      console.log("formData: ", formData.valveMfgDate);
      const { data } = await axios.post(
        `${baseURL}/api/cylinderqr/${addEditCylinder}`,
        formData,
        {
          "Content-Type": "application/json",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      // console.log("res: ", data);
      setSnackbarInfo({
        isOpenClose: true,
        message: data?.msg,
        color: "success",
      });
      window.location.reload();
    } catch (error) {
      console.log("error: ", error);
      setSnackbarInfo({
        isOpenClose: true,
        message: error?.response?.data?.msg,
        color: "error",
      });
    }
  };

  // USEEFFECT : Masterdata Details
  useEffect(() => {
    if (errorMasterData) {
      console.log("error: ", errorMasterData);
    }
    if (!isLoadingMasterData && !errorMasterData) {
      // console.log("master data detials: ", masterDataDetails);
      if (masterDataDetails) {
        setMasterDataInfoState((preState) => ({
          ...preState,
          cylinderTypeArray: [...masterDataDetails.data.typeOfCylinder],
          cylinderMaterialArray: [...masterDataDetails.data.cylinderMaterial],
          cylinderBrandArray: [...masterDataDetails.data.cylinderBrand],
          valveBrandArray: [...masterDataDetails.data.valveBrand],
          cylinderLocationArray: [
            ...masterDataDetails.data.cylinderLocation,
            ...masterDataDetails.data.plantLocation,
          ],
          plantLocationArray: [
            ...masterDataDetails.data.cylinderLocation,
            ...masterDataDetails.data.plantLocation,
          ],
        }));
      }
    }
  }, [isLoadingMasterData, errorMasterData, masterDataDetails]);

  // USEEFFECT : Transaction Details
  useEffect(() => {
    if (errorTransaction) {
      console.log("error: ", errorTransaction);
    }
    if (!isLoadingtransaction && !errorTransaction) {
      // console.log("transaction detials: ", transactionDetails.data);
      if (transactionDetails?.data?.length > 0) {
        setStatusLocation((preState) => ({
          ...preState,
          transactionsArray: [...transactionDetails?.data],
        }));
      }
    }
  }, [isLoadingtransaction, errorTransaction, transactionDetails]);

  // USEEFFECT : Maintenance Details
  useEffect(() => {
    if (errorMaintenance) {
      console.log("error: ", errorMaintenance);
    }
    if (!isLoadingMaintenance && !errorMaintenance) {
      // console.log(" detials: ", maintenanceDetails);
      if (maintenanceDetails) {
        setTestParameters((preState) => ({
          ...preState,
          testParameterDetails: [...maintenanceDetails.data],
        }));
      }
    }
  }, [isLoadingMaintenance, errorMaintenance, maintenanceDetails]);

  // USEEFFECT : Cylinder Details
  useEffect(() => {
    if (error) {
      // console.log("error: ", error);
      if (error?.response?.data?.tagStatus === "invalid") {
        setTagCompanyName("Unknown");
        setSnackbarInfo({
          isOpenClose: true,
          message: "Invalid Tag Number",
          color: "error",
        });
      } else if (error.response?.data?.msg === "No Data Found.") {
        if (error.response?.data?.tagInitial === "AGL") {
          setTagCompanyName("Anaesthetic Gases Ltd (AGL)");
        } else if (error.response?.data?.tagInitial === "SUN") {
          setTagCompanyName("Sunflag");
        }
        setAddEditCylinder("addCylinderDetails");
      }
    }
    if (!isLoading && !error) {
      if (data?.tagInitial === "AGL") {
        setTagCompanyName("Anaesthetic Gases Ltd (AGL)");
      } else if (data?.tagInitial === "SFT") {
        setTagCompanyName("Sunflag");
      }
      if (data?.tagStatus === "inactive") {
        setIsTagInactive(true);
        setSnackbarInfo({
          isOpenClose: true,
          message: "Please fill the required field and Activate Tag",
          color: "success",
        });
      }
      if (data?.tagStatus === "active") {
        setAddEditCylinder("updateCylinderDetails");
        // console.log("cylinder details", data);
        setCylinderDetails({
          cylinder_code: data?.data?.cylinderSerialNo,
          cylinder_type: data?.data?.typeOfCylinder,
          cylinder_brand: data?.data?.cylinderBrand,
          cylinder_mfg_date: data?.data?.cylinderMfgDate,
          cylinder_material: data?.data?.cylinderMaterial,
          cylinder_location: data?.data?.cylinderLocation,
          peso_approval_no: data?.data?.pesoApprovalNo,
          peso_approval_date: data?.data?.pesoApprovalDate,
          original_tare_weight: data?.data?.originalTareWeight,
          creation_date: data?.data?.creationDate?.split("T")[0],
          created_by: data?.data?.createdBy,
          tested_by: data?.data?.testedBy,
        });

        setValveDetails({
          valve_brand: data?.data?.valveBrand,
          valve_mfg_year: data?.data?.valveMfgDate,
        });
        setSpecification({
          working_pressure: data?.data?.workingPressure,
          test_pressure: data?.data?.testPressure,
          water_capacity: data?.data?.waterCapacity,
          test_frequency: data?.data?.testFrequency,
          gas_capacity: data?.data?.gasCapacity,
          specification: data?.data?.specification,
          test_frequency_num: data?.data?.testFrequency?.split(" ")[0],
          test_frequency_text: data?.data?.testFrequency?.split(" ")[1],
          hydrotest_due_date: data?.data?.testFrequencyDueDate?.split("T")[0],
        });
      }
    }
    // becaouse of currentYear
    // eslint-disable-next-line
  }, [isLoading, error, data, addEditCylinder]);

  // FOR STATUS LOCATION
  const toReadableDateTime = (st) => {
    if (typeof st !== "string") return null;

    const [date, time] = st?.split("T");

    if (!time && date) return date.split("-").reverse().join("-");

    if (!date || !time) return null;

    return `${date.split("-").reverse().join("-")} ${time.slice(0, 5)}`;
  };

  const [isTagInactive, setIsTagInactive] = useState(false);
  const [isUpdateRequest, setIsUpdateRequest] = useState(false);

  // Accessing User Tag Activation for users having access
  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user !== null) {
      const userInfo = JSON.parse(user);

      const isUserActivateTag =
        userInfo?.accessActivities?.includes("Tag Activation");
      const isUserUpdateTag =
        userInfo?.accessActivities?.includes("Tag Edition");

      // if (isUserActivateTag || isUserUpdateTag) {
      //   setFlag((preState) => ({
      //     ...preState,
      //     editOnlyAdmin: true,
      //     isAdmin: true,
      //   }));
      // }
      console.log(isUserActivateTag);
      if (!isUserUpdateTag) {
        if (
          userInfo?.accessActivities?.includes("Hydrotesting") ||
          userInfo?.accessActivities?.includes("Transaction")
        ) {
          setIsUpdateRequest(true);
        }
      }
    }
  }, []);

  // MUTATION : CYLINDER SUBMIT REQUEST
  const {
    mutate: cylinderUpdateRequest,
    isPending: isPendingCylinderUpdateRequest,
  } = useMutation({
    mutationFn: async (requestForm) => {
      return await axios.post(
        `${baseURL}/api/cylinderqr/updateCylinderDetailsRequestByUser`,
        requestForm,
        {
          "Content-Type": "application/json",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    },
    onSuccess: (data) => {
      console.log("success", data);
      setSnackbarInfo({
        isOpenClose: true,
        message: data.data.msg,
        color: "success",
      });

      windowsReload();
    },
    onError: (error) => {
      // console.log("error", error);
      setSnackbarInfo({
        isOpenClose: true,
        message: error.response.data.msg,
        color: "error",
      });
    },
  });

  const submitCylinderUpdateRequest = () => {
    if (!tag_number || !cylinderDetails.cylinder_code) {
      setSnackbarInfo({
        isOpenClose: true,
        message: "Please Fill the Required Field ",
        color: "error",
      });
    } else {
      const formData = {
        tagNumber: tag_number,
        cylinderSerialNo: cylinderDetails.cylinder_code,
        typeOfCylinder: cylinderDetails.cylinder_type,
        cylinderBrand: cylinderDetails.cylinder_brand,
        cylinderMfgDate: cylinderDetails.cylinder_mfg_date,
        cylinderMaterial: cylinderDetails.cylinder_material,
        cylinderLocation: cylinderDetails.cylinder_location,
        pesoApprovalNo: cylinderDetails.peso_approval_no,
        pesoApprovalDate: cylinderDetails.peso_approval_date,
        originalTareWeight: cylinderDetails.original_tare_weight,

        valveBrand: valveDetails.valve_brand,
        valveMfgDate: valveDetails.valve_mfg_year,

        workingPressure: specification.working_pressure,
        testPressure: specification.test_pressure,
        waterCapacity: specification.water_capacity,
        gasCapacity: specification.gas_capacity,
        specification: specification.specification,
        testFrequency: specification.test_frequency,
      };
      cylinderUpdateRequest(formData);
    }
  };

  const Root = styled("div")(({ theme }) => ({
    width: "100%",
    ...theme.typography.body2,
    "& > :not(style) ~ :not(style)": {
      marginTop: theme.spacing(2),
    },
  }));

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  // console.log("tagCompanyName: ", tagCompanyName);
  return (
    <>
      <Navbar isScanPage={true} clickToLogin={clickToLogin} />
      {/* _____________________ CYLINDER DETAILS ROW _____________________*/}
      <div className="container-fluid">
        <form className="cylinder-form-submit" onSubmit={submitHandle}>
          <div className="row-main-wrapper row border m-2">
            {/* _____________________ CYLINDER VECTOR IMG COL _____________________*/}
            <div className="col-10 m-auto d-flex flex-column justify-content-center align-items-center">
              <div className="cylinder-img-div">
                <img
                  className="cylinder-img"
                  src="https://storage.googleapis.com/pettag/qr/assets/oxygen.png"
                  alt="cylinder_img"
                />
              </div>
              <div className="d-flex my-2" style={{ gap: "6px" }}>
                <img
                  src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                  style={{ width: "30px", height: "30px" }}
                  alt="qr-vector-img"
                />
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "1.2rem",
                    margin: "auto",
                  }}
                >
                  <b>{tag_number && tag_number !== "-" ? tag_number : "N/A"}</b>
                </p>
              </div>

              <h4 className="text-center">
                This <span className="tag8-color">Cylinder</span> belongs to
                <span className="tag8-color"> {tagCompanyName}</span>
              </h4>
            </div>
            {/* _____________________* CYLINDER DETAILS *_____________________*/}
            <div className="col-10 m-auto d-flex justify-content-center align-items-center">
              <Accordion className="mui-accordation">
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Root>
                    <Divider>Cylinder Details</Divider>
                  </Root>
                </AccordionSummary>
                <AccordionDetails>
                  {/* Cylinder Code and Cylinder Type */}
                  <div className="two-field-wrapper">
                    <div className="mui-text-field">
                      <TextField
                        label="Cylinder Code *"
                        type="search"
                        placeholder="Search By Cylinder Code"
                        variant="outlined"
                        name="cylinder_code"
                        value={cylinderDetails.cylinder_code}
                        onChange={handleChangeTextField}
                        inputProps={{
                          maxLength: 20,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{ readOnly: !flag.isAuth }}
                      />
                    </div>
                    <div className="mui-text-field">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Cylinder Type *
                        </InputLabel>
                        <Select
                          label="Cylinder Type *"
                          name="cylinder_type"
                          value={cylinderDetails.cylinder_type}
                          onChange={handleChangeTextField}
                          inputProps={{ readOnly: !flag.isAuth }}
                        >
                          {!flag.isAuth && (
                            <MenuItem value={cylinderDetails.cylinder_type}>
                              {cylinderDetails.cylinder_type}
                            </MenuItem>
                          )}
                          {masterDataInfoState.cylinderTypeArray.map(
                            (item, index) => {
                              return (
                                <MenuItem value={item.value} key={index}>
                                  {item.value}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  {/* Cylinder Brand and Cylinder Mfg Date */}
                  <div className="two-field-wrapper">
                    <div className="mui-text-field">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Cylinder Brand *
                        </InputLabel>
                        <Select
                          label="Cylinder Brand *"
                          name="cylinder_brand"
                          value={cylinderDetails.cylinder_brand}
                          onChange={handleChangeTextField}
                          inputProps={{ readOnly: !flag.isAuth }}
                        >
                          {!flag.isAuth && (
                            <MenuItem value={cylinderDetails.cylinder_brand}>
                              {cylinderDetails.cylinder_brand}
                            </MenuItem>
                          )}
                          {masterDataInfoState.cylinderBrandArray.map(
                            (item, index) => {
                              return (
                                <MenuItem value={item.value} key={index}>
                                  {item.value}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="mui-text-field">
                      <DatePicker
                        label="Cylinder Mfg Date *"
                        name="cylinder_mfg_date"
                        value={dayjs(cylinderDetails.cylinder_mfg_date)}
                        format="DD-MM-YYYY"
                        maxDate={currentYear}
                        onChange={handleCylinderDetailsDateChange}
                        readOnly={!flag.isAuth}
                      />
                    </div>
                  </div>
                  {/* Cylinder Material and Cylinder Location */}
                  <div className="two-field-wrapper">
                    <div className="mui-text-field">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Cylinder Material *
                        </InputLabel>
                        <Select
                          label="Cylinder Material *"
                          name="cylinder_material"
                          value={cylinderDetails.cylinder_material}
                          onChange={handleChangeTextField}
                          inputProps={{ readOnly: !flag.isAuth }}
                        >
                          {!flag.isAuth && (
                            <MenuItem value={cylinderDetails.cylinder_material}>
                              {cylinderDetails.cylinder_material}
                            </MenuItem>
                          )}
                          {masterDataInfoState.cylinderMaterialArray.map(
                            (item, index) => {
                              return (
                                <MenuItem value={item.value} key={index}>
                                  {item.value}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="mui-text-field">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Home Plant Location *
                        </InputLabel>
                        <Select
                          label="Home Plant Location *"
                          name="cylinder_location"
                          value={cylinderDetails.cylinder_location}
                          onChange={handleChangeTextField}
                          inputProps={{ readOnly: !flag.isAuth }}
                        >
                          {!flag.isAuth && (
                            <MenuItem value={cylinderDetails.cylinder_location}>
                              {cylinderDetails.cylinder_location}
                            </MenuItem>
                          )}
                          {masterDataInfoState.cylinderLocationArray.map(
                            (item, index) => {
                              return (
                                <MenuItem value={item.value} key={index}>
                                  {item.value}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  {/* Cylinder Peso Approval No and  Date */}
                  <div className="two-field-wrapper">
                    <div className="mui-text-field">
                      <TextField
                        label="Peso Approval Number *"
                        type="text"
                        variant="outlined"
                        name="peso_approval_no"
                        value={cylinderDetails.peso_approval_no}
                        // inputProps={{ maxLength: 20 }}
                        onChange={handleChangeTextField}
                        InputProps={{ readOnly: !flag.isAuth }}
                      />
                    </div>
                    <div className="mui-text-field">
                      <DatePicker
                        label="Peso Approval Date *"
                        name="peso_approval_date"
                        value={dayjs(cylinderDetails.peso_approval_date)}
                        format="DD-MM-YYYY"
                        onChange={handleCylinderPesoDateChange}
                        readOnly={!flag.isAuth}
                      />
                    </div>
                  </div>
                  {/* Original T.W and ...*/}
                  <div className="two-field-wrapper">
                    <div className="mui-text-field">
                      <TextField
                        label="Original Tare Weight*"
                        type="text"
                        variant="outlined"
                        name="original_tare_weight"
                        value={cylinderDetails.original_tare_weight}
                        onChange={handleChangeTextField}
                        InputProps={{ readOnly: !flag.isAuth }}
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
            {/* _____________________ VALVE DETAILS _____________________ */}
            <div className="col-10 m-auto d-flex justify-content-center align-items-center">
              <Accordion className="mui-accordation">
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Root>
                    <Divider>Valve Details</Divider>
                  </Root>
                </AccordionSummary>
                <AccordionDetails>
                  {/* Valve Brand and Valve Mfg Year */}
                  <div className="two-field-wrapper">
                    <div className="mui-text-field">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Valve Brand *
                        </InputLabel>
                        <Select
                          label="Valve Brand *"
                          name="valve_brand"
                          value={valveDetails.valve_brand}
                          onChange={(e) =>
                            setValveDetails({
                              ...valveDetails,
                              valve_brand: e.target.value,
                            })
                          }
                          inputProps={{ readOnly: !flag.isAuth }}
                        >
                          {!flag.isAuth && (
                            <MenuItem value={valveDetails.valve_brand}>
                              {valveDetails.valve_brand}
                            </MenuItem>
                          )}
                          {masterDataInfoState.valveBrandArray.map(
                            (item, index) => {
                              return (
                                <MenuItem value={item.value} key={index}>
                                  {item.value}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="mui-text-field">
                      <DatePicker
                        label="Valve Mfg Year *"
                        name="valve_mfg_year"
                        views={["year"]}
                        maxDate={currentYear}
                        // value={dayjs("2023")}
                        value={dayjs(valveDetails.valve_mfg_year)}
                        onChange={onchangeValveMfgYear}
                        readOnly={!flag.isAuth}
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
            {/* _____________________ SPECIFICATION _____________________ */}
            <div className="col-10 m-auto d-flex justify-content-center align-items-center">
              <Accordion className="mui-accordation">
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Root>
                    <Divider>Specification</Divider>
                  </Root>
                </AccordionSummary>
                <AccordionDetails>
                  {/* Working Pressure  and Test Pressure*/}
                  <div className="two-field-wrapper">
                    <div className="mui-text-field">
                      <TextField
                        label="Working Pressure (Kg/Cm2) *"
                        type="number"
                        variant="outlined"
                        name="working_pressure"
                        value={specification.working_pressure}
                        inputProps={{ maxLength: 2 }}
                        onChange={handleChangeSpecification}
                        InputProps={{ readOnly: !flag.isAuth }}
                      />
                    </div>
                    <div className="mui-text-field">
                      <TextField
                        label="Test Pressure (Kg/Cm2) *"
                        type="number"
                        variant="outlined"
                        name="test_pressure"
                        value={specification.test_pressure}
                        inputProps={{ maxLength: 3 }}
                        onChange={handleChangeSpecification}
                        InputProps={{ readOnly: !flag.isAuth }}
                      />
                    </div>
                  </div>
                  {/* Water Capacity and  Gas Capacity */}
                  <div className="two-field-wrapper">
                    <div className="mui-text-field">
                      <TextField
                        label="Water Capacity (Litre) *"
                        type="number"
                        variant="outlined"
                        name="water_capacity"
                        value={specification.water_capacity}
                        inputProps={{ maxLength: 3 }}
                        onChange={handleChangeSpecification}
                        InputProps={{ readOnly: !flag.isAuth }}
                      />
                    </div>
                    <div className="mui-text-field">
                      <TextField
                        label="Gas Capacity (Cu.M) *"
                        type="number"
                        variant="outlined"
                        name="gas_capacity"
                        value={specification.gas_capacity}
                        inputProps={{ maxLength: 3 }}
                        onChange={handleChangeSpecification}
                        InputProps={{ readOnly: !flag.isAuth }}
                      />
                    </div>
                  </div>
                  {/*Spacificatoins & Hydro Test Frequency  */}
                  <div className="two-field-wrapper mb-4">
                    <div className="mui-text-field">
                      <TextField
                        label="Specificatoin*"
                        type="text"
                        variant="outlined"
                        name="specification"
                        value={specification.specification}
                        onChange={handleChangeSpecification}
                        InputProps={{ readOnly: !flag.isAuth }}
                      />
                    </div>
                    <div className="mui-text-field d-flex ">
                      <div className="hydrotest-period-no">
                        <TextField
                          label="Hydrotest Period  *"
                          name="test_frequency_num"
                          type="text"
                          value={specification.test_frequency_num}
                          onChange={handleChangeSpecification}
                          inputProps={{ maxLength: 3 }}
                          readOnly={!flag.isAuth}
                        />
                      </div>
                      <div className="hydrotest-period-text">
                        <FormControl fullWidth>
                          {/* <InputLabel id="demo-simple-select-label">
                            Hydrotest Period *
                          </InputLabel> */}
                          <Select
                            // label="Hydrotest Period  *"
                            name="test_frequency_text"
                            value={specification.test_frequency_text}
                            onChange={handleChangeSpecification}
                            inputProps={{ readOnly: !flag.isAuth }}
                          >
                            <MenuItem value="Days">Days</MenuItem>
                            <MenuItem value="Month">Months</MenuItem>
                            <MenuItem value="Year">Year</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  {/* Hydrotest Due Date */}
                  <div className="two-field-wrapper">
                    <div className="mui-text-field-single">
                      <DatePicker
                        label="Hydrotest Due Date"
                        name="hydrotest_due_date"
                        value={dayjs(specification.hydrotest_due_date)}
                        format="DD-MM-YYYY"
                        readOnly={true}
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
            {/* _____________________ LOCATION & STATUS _____________________ */}
            <div className="col-10 m-auto d-flex justify-content-center align-items-center">
              <Accordion className="mui-accordation">
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Root>
                    <Divider>Status & Location </Divider>
                  </Root>
                </AccordionSummary>
                <AccordionDetails>
                  {flag.editOnlyUser && (
                    <div className="">
                      {/* Working Pressure  and Test Pressure*/}
                      <div className="two-field-wrapper">
                        <div className="mui-text-field">
                          <FormControl>
                            <FormLabel>Capacity Status *</FormLabel>
                            <RadioGroup
                              row
                              value={statusLocation.status}
                              name="status"
                              onChange={handleChangeStatusLocation}
                            >
                              <FormControlLabel
                                value="Full"
                                control={<Radio />}
                                label="Full"
                                disabled={!flag.isAuth}
                              />
                              <FormControlLabel
                                value="Empty"
                                control={<Radio />}
                                label="Empty"
                                disabled={!flag.isAuth}
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                        <div className="mui-text-field">
                          <FormControl>
                            <FormLabel>Transfer Location *</FormLabel>
                            <RadioGroup
                              row
                              name="location"
                              value={statusLocation.location}
                              onChange={handleChangeStatusLocation}
                            >
                              <FormControlLabel
                                value="In Plant"
                                control={<Radio />}
                                label="In Plant"
                                disabled={!flag.isAuth}
                              />
                              <FormControlLabel
                                value="Out Of Plant"
                                control={<Radio />}
                                label="Out Of Plant"
                                disabled={!flag.isAuth}
                              />
                            </RadioGroup>
                          </FormControl>
                          {statusLocation.location === "In Plant" && (
                            <div className="under-mui-text-field">
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  In Plant Location *
                                </InputLabel>
                                <Select
                                  label="In Plant Location *"
                                  name="in_plant_location"
                                  value={statusLocation.in_plant_location}
                                  onChange={handleChangeStatusLocation}
                                  inputProps={{ readOnly: !flag.isAuth }}
                                >
                                  {masterDataInfoState.cylinderLocationArray.map(
                                    (item, index) => {
                                      return (
                                        <MenuItem
                                          value={item.value}
                                          key={index}
                                        >
                                          {item.value}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                                </Select>
                              </FormControl>
                            </div>
                          )}

                          {statusLocation.location === "Out Of Plant" && (
                            <div className="under-mui-text-field">
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Out Of Plant Location *
                                </InputLabel>
                                <Select
                                  label="Out Of Plant Location *"
                                  name="out_of_plant_location"
                                  value={statusLocation.out_of_plant_location}
                                  onChange={handleChangeStatusLocation}
                                  inputProps={{ readOnly: !flag.isAuth }}
                                >
                                  {masterDataInfoState.plantLocationArray.map(
                                    (item, index) => {
                                      return (
                                        <MenuItem
                                          value={item.value}
                                          key={index}
                                        >
                                          {item.value}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                                </Select>
                              </FormControl>
                            </div>
                          )}
                        </div>
                      </div>

                      {/* button  */}
                      {flag.editOnlyUser && (
                        <div
                          className="two-field-wrapper"
                          style={{ justifyContent: "center" }}
                        >
                          <div className="mui-btn">
                            <Button
                              type="button"
                              variant="contained"
                              className="test-add-btn"
                              onClick={onClickAddTransaction}
                              disabled={isPendingTransaction}
                            >
                              {isPendingTransaction
                                ? "Adding..."
                                : "Add Transaction"}
                            </Button>
                          </div>
                        </div>
                      )}
                      {/* Transaction Logs */}
                      <div className="col-12 m-auto">
                        <Accordion className="mui-accordation">
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Root>
                              <Divider>Transaction Logs</Divider>
                            </Root>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack
                              spacing={0.2}
                              style={{
                                height:
                                  statusLocation.transactionsArray.length > 5
                                    ? "17.1rem"
                                    : "auto",
                                overflow:
                                  statusLocation.transactionsArray.length > 5
                                    ? "overlay"
                                    : "visible",
                              }}
                            >
                              {statusLocation.transactionsArray.map(
                                (item, index) => {
                                  return (
                                    <Item className="item-card" key={index}>
                                      <div className="transaction-info d-flex flex-column justify-content-between  gap-2">
                                        <div className="location-ifo">
                                          <span className="text-content-lebal">
                                            {item?.location === "In Plant"
                                              ? "IN"
                                              : "OUT"}{" "}
                                            :{" "}
                                          </span>
                                          <span className="text-content">
                                            {toReadableDateTime(
                                              item.createDate
                                            )}
                                          </span>
                                          <span className="text-content-lebal ms-2">
                                            Status :{" "}
                                          </span>
                                          <span className="">
                                            {item.cylinderStatus}
                                          </span>
                                          {/* <span className="">
                                            {item.cylinderStatus === "Empty" ? (
                                              <img
                                                src={Empty}
                                                alt="emptystatus"
                                                className="empty-full-img"
                                              />
                                            ) : (
                                              <img
                                                src={Full}
                                                alt="fullstatus"
                                                className="empty-full-img"
                                              />
                                            )}
                                          </span> */}
                                        </div>

                                        <div className="location-info">
                                          <span className="text-content">
                                            <PinDrop />
                                          </span>
                                          <span className="text-content ms-1">
                                            {item.toLocation}
                                          </span>
                                        </div>
                                        {/* </div> */}
                                      </div>
                                    </Item>
                                  );
                                }
                              )}
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  )}

                  {/* Login button  */}
                  {!flag.editOnlyUser && (
                    <div
                      className="two-field-wrapper"
                      style={{ justifyContent: "center" }}
                    >
                      <div className="mui-btn">
                        <Button
                          type="button"
                          variant="contained"
                          className="test-add-btn"
                          onClick={handlLogin}
                        >
                          Login
                        </Button>
                      </div>
                    </div>
                  )}
                  {!flag.editOnlyUser && (
                    <Stack
                      spacing={0.2}
                      style={{
                        height:
                          statusLocation.transactionsArray.length > 5
                            ? "17.1rem"
                            : "auto",
                        overflow:
                          statusLocation.transactionsArray.length > 5
                            ? "overlay"
                            : "visible",
                      }}
                    >
                      {statusLocation.transactionsArray
                        .slice(0, 1)
                        .map((item, index) => {
                          return (
                            <Item className="item-card" key={index}>
                              <div className="transaction-info d-flex flex-column justify-content-between  gap-2">
                                <div className="location-ifo">
                                  <span className="text-content-lebal">
                                    {item?.location === "In Plant"
                                      ? "IN"
                                      : "OUT"}{" "}
                                    :{" "}
                                  </span>
                                  <span className="text-content">
                                    {toReadableDateTime(item.createDate)}
                                  </span>
                                  <span className="text-content-lebal ms-2">
                                    Status :{" "}
                                  </span>
                                  <span className="">
                                    {item.cylinderStatus}
                                    {/* {item.cylinderStatus === "Empty" ? (
                                      <img
                                        src={Empty}
                                        alt="emptystatus"
                                        className="empty-full-img"
                                      />
                                    ) : (
                                      <img
                                        src={Full}
                                        alt="fullstatus"
                                        className="empty-full-img"
                                      />
                                    )} */}
                                  </span>
                                </div>

                                <div className="location-info">
                                  <span className="text-content">
                                    <PinDrop />
                                  </span>
                                  <span className="text-content ms-1">
                                    {item.toLocation}
                                  </span>
                                </div>
                                {/* </div> */}
                              </div>
                            </Item>
                          );
                        })}
                    </Stack>
                  )}
                </AccordionDetails>
              </Accordion>
            </div>
            {/* _____________________ TEST PARAMETERS _____________________ */}
            <div className="col-10 m-auto d-flex justify-content-center align-items-center">
              <Accordion className="mui-accordation">
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Root>
                    <Divider>Maintenance</Divider>
                  </Root>
                </AccordionSummary>
                <AccordionDetails>
                  {flag.editOnlyUser && (
                    <div className="">
                      {/* Hydrotesting Date and Tare Weight */}
                      <div className="two-field-wrapper">
                        <div className="mui-text-field">
                          <DatePicker
                            label="Hydrotesting Date *"
                            name="hydrotesting_date"
                            value={dayjs(testParameters.hydrotesting_date)}
                            format="DD-MM-YYYY"
                            maxDate={currentYear}
                            onChange={handleHydortestingDateChange}
                            readOnly={!flag.isAuth}
                          />
                        </div>
                        <div className="mui-text-field">
                          <TextField
                            label="Tare Weight (Kgs) *"
                            type="number"
                            variant="outlined"
                            name="tare_weight"
                            value={testParameters.tare_weight}
                            inputProps={{ maxLength: 3 }}
                            onChange={handleChangeTestParameters}
                            InputProps={{
                              readOnly: !flag.editOnlyUser,
                            }}
                          />
                        </div>
                      </div>
                      {/* Test message and  .....  */}
                      <div className="two-field-wrapper mb-4">
                        <div
                          className="mui-text-field-single"
                          style={{ position: "relative" }}
                        >
                          <TextField
                            label="Test Comment"
                            type="text"
                            variant="outlined"
                            name="test_comment"
                            value={testParameters.test_comment}
                            inputProps={{ maxLength: 40 }}
                            onChange={handleChangeTestParameters}
                            InputProps={{
                              readOnly: !flag.editOnlyUser,
                            }}
                          />
                          <span
                            style={{
                              position: "absolute",
                              bottom: "-2px",
                              right: "2px",
                              color: "gray",
                            }}
                          >{`${testParameters.test_comment.length}/40`}</span>
                        </div>
                      </div>
                      {/* button  */}
                      {flag.editOnlyUser && (
                        <div
                          className="two-field-wrapper"
                          style={{ justifyContent: "center" }}
                        >
                          <div className="mui-btn">
                            <Button
                              type="button"
                              variant="contained"
                              className="test-add-btn"
                              onClick={onClickAddTestParameters}
                              disabled={isPendingMaintenance}
                            >
                              {!isPendingMaintenance ? "Add Test" : "Adding..."}
                            </Button>
                          </div>
                        </div>
                      )}
                      {/* Test Logs */}
                      <div className="col-12 m-auto">
                        <Accordion className="mui-accordation">
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Root>
                              <Divider>History Logs</Divider>
                            </Root>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack
                              spacing={0.2}
                              style={{
                                height:
                                  testParameters.testParameterDetails.length > 5
                                    ? "18.5rem"
                                    : "auto",
                                overflow:
                                  testParameters.testParameterDetails.length > 5
                                    ? "overlay"
                                    : "visible",
                              }}
                            >
                              <div className="test-info d-flex justify-content-between aligin-items-center px-2 gap-1">
                                <h6 className="tag8-color test-log-lebal">
                                  Hydrotesting Date
                                </h6>
                                <h6 className="tag8-color test-log-lebal">
                                  Tare Weight (Kgs)
                                </h6>
                              </div>

                              {testParameters.testParameterDetails.map(
                                (test, index) => {
                                  return (
                                    <Item className="item-card" key={index}>
                                      <div className="test-details d-flex flex-column gap-2">
                                        <div className="test-info d-flex justify-content-between aligin-items-center">
                                          <span className="test-text">
                                            {toReadableDateTime(
                                              test.hydrotestDate
                                            )}
                                          </span>
                                          <span className="test-text">
                                            {test.tareWeight}
                                          </span>
                                        </div>
                                        <div className="test-message d-flex justify-content-between">
                                          <span className="">
                                            {test.testComment}
                                          </span>
                                          <span className="testedby-text">
                                            __ {test.updatedBy}
                                          </span>
                                        </div>
                                      </div>
                                    </Item>
                                  );
                                }
                              )}
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  )}

                  {/* Login button  */}
                  {!flag.editOnlyUser && (
                    <div
                      className="two-field-wrapper"
                      style={{ justifyContent: "center" }}
                    >
                      <div className="mui-btn">
                        <Button
                          type="button"
                          variant="contained"
                          className="test-add-btn"
                          onClick={handlLogin}
                        >
                          Login
                        </Button>
                      </div>
                    </div>
                  )}
                  {!flag.editOnlyUser && (
                    <Stack
                      spacing={0.1}
                      style={{
                        height:
                          testParameters.testParameterDetails.length > 5
                            ? "18.5rem"
                            : "auto",
                        overflow:
                          testParameters.testParameterDetails.length > 5
                            ? "overlay"
                            : "visible",
                      }}
                    >
                      <div className="test-info d-flex justify-content-between aligin-items-center px-2 gap-1">
                        <h6 className="tag8-color test-log-lebal">
                          Hydrotesting Date
                        </h6>
                        <h6 className="tag8-color test-log-lebal">
                          Tare Weight (Kgs)
                        </h6>
                      </div>

                      {testParameters.testParameterDetails
                        .slice(0, 1)
                        .map((test, index) => {
                          return (
                            <Item className="item-card" key={index}>
                              <div className="test-details d-flex flex-column gap-2">
                                <div className="test-info d-flex justify-content-between aligin-items-center">
                                  <span className="test-text">
                                    {test.hydrotestDate}
                                  </span>
                                  <span className="test-text">
                                    {test.tareWeight}
                                  </span>
                                </div>
                                <div className="test-message d-flex justify-content-between">
                                  <span className="">{test.testComment}</span>
                                  <span className="testedby-text">
                                    __ {test.updatedBy}
                                  </span>
                                </div>
                              </div>
                            </Item>
                          );
                        })}
                    </Stack>
                  )}
                </AccordionDetails>
              </Accordion>
            </div>
            {/* _____________________ ONBOARDING DETAILS _____________________ */}
            {flag.isAdmin && (
              <div className="col-10 m-auto d-flex justify-content-center align-items-center">
                <Accordion className="mui-accordation">
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Root>
                      <Divider>Onboarding Details</Divider>
                    </Root>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* Onboarding Date and Onboarded By */}
                    <div className="two-field-wrapper">
                      <div className="mui-text-field">
                        <DatePicker
                          label="Onboarding Date *"
                          name="onboarding_date"
                          value={dayjs(cylinderDetails.creation_date)}
                          format="DD-MM-YYYY"
                          readOnly={true}
                        />
                      </div>
                      <div className="mui-text-field">
                        <TextField
                          label="Onboarded By *"
                          type="text"
                          variant="outlined"
                          name="onboarded_by"
                          value={cylinderDetails.created_by}
                          inputProps={{ maxLength: 20 }}
                          InputProps={{ readOnly: true }}
                        />
                      </div>
                    </div>
                    {/* Tested By */}
                    <div className="two-field-wrapper">
                      <div className="mui-text-field-single">
                        <TextField
                          label="Tested By *"
                          type="text"
                          variant="outlined"
                          name="tested_by"
                          value={cylinderDetails.tested_by}
                          inputProps={{ maxLength: 20 }}
                          InputProps={{ readOnly: true }}
                        />
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            )}

            {/* Submit & Cancel button  */}
            {/* for Tag activation */}
            {localStorage.getItem("token") && isTagInactive && (
              <div className="col-5 m-auto d-flex gap-3 p-2 mt-5">
                <Button
                  type="button"
                  variant="contained"
                  className="form-cancel-btn"
                  onClick={windowsReload}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className="form-submit-btn"
                >
                  Submit
                </Button>
              </div>
            )}
            {localStorage.getItem("token") &&
              !isTagInactive &&
              !isUpdateRequest && (
                <div className="col-5 m-auto d-flex gap-3 p-2 mt-5">
                  <Button
                    type="button"
                    variant="contained"
                    className="form-cancel-btn"
                    onClick={windowsReload}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    className="form-submit-btn"
                  >
                    {/* {addEditCylinder === "addCylinderDetails"
                    ? "Submit"
                    : "Update"} */}
                    Update
                  </Button>
                </div>
              )}

            {/* Submit Request & Cancel button  */}
            {isUpdateRequest && !isTagInactive && (
              <div className="col-10 m-auto d-flex gap-3 p-2 mt-5">
                <Button
                  type="button"
                  variant="contained"
                  className="form-cancel-btn"
                  onClick={windowsReload}
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  className="form-submit-btn"
                  disabled={isPendingCylinderUpdateRequest}
                  onClick={submitCylinderUpdateRequest}
                >
                  Change Request
                </Button>
              </div>
            )}
          </div>
        </form>
      </div>

      {/* Loader */}
      {isLoading && isLoadingMasterData && (
        <div className="loader-container">
          <div className="loader">
            <img
              src={"https://storage.googleapis.com/pettag/qr/assets/loader.gif"}
              alt="Loading..."
              loading="lazy"
              style={{ width: "100px", height: "100px" }}
            />
          </div>
        </div>
      )}

      {/* Snack Bar */}
      <Snackbar
        open={snackbarInfo.isOpenClose}
        onClose={autoCloseSnackbar}
        autoHideDuration={5000}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        style={{ top: "25%" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarInfo.color}
          sx={{ width: "100%" }}
        >
          {snackbarInfo.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Scan;
