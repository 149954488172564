import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Delete, Edit, Close } from "@mui/icons-material";
import {
  DialogContent,
  Modal,
  Snackbar,
  Alert as MuiAlert,
} from "@mui/material";
import Navbar from "../../Navbar/Navbar";
import "./usermanagement.css";
import axios from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import { switchUrls } from "../../../api/";
import UserCreateUpdate from "./UserCreateUpdate";

const baseURL = switchUrls("cylinder");

const getAllUsers = async () => {
  const { data } = await axios.post(
    `${baseURL}/api/cylinderqr/getAllUsers`,
    {},
    {
      "Content-Type": "application/json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return data;
};
const UserManagement = () => {
  const [userId, setUserId] = useState();
  const [isUserId, setIsUserId] = useState(false);
  // QUERY : Get all user Data
  const { data, error, isLoading } = useQuery({
    queryKey: ["AllUsers"],
    queryFn: getAllUsers,
    refetchOnWindowFocus: false,
    // staleTime: 1000 * 60 * 5,
    retry: 2,
  });

  // MUTATION : DELETE USER
  const { mutate } = useMutation({
    mutationFn: async (uid) => {
      return await axios.post(
        `${baseURL}/api/cylinderqr/deleteUser`,
        { uid },
        {
          "Content-Type": "application/json",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    },
    onSuccess: (data) => {
      console.log("success", data);
      setSnackbarInfo({
        isOpenClose: true,
        message: "User Deleted Successfully",
        color: "success",
      });
      windowsReload();
    },
    onError: (error) => {
      setSnackbarInfo({
        isOpenClose: true,
        message: error.response.data.msg,
        color: "error",
      });
    },
  });

  const windowsReload = () => {
    window.location.reload();
  };
  const RenderUserRole = ({ value }) => {
    return (
      <p className={`${value === "admin" ? "text-success text-bold" : ""}`}>
        {value}
      </p>
    );
  };
  const ActionBtnRenderer = ({ value }) => {
    const editDetails = () => {
      if (
        localStorage.getItem("role") === "admin" &&
        rowData.find((item) => item.uid === value)?.role === "admin"
      ) {
        setSnackbarInfo({
          isOpenClose: true,
          message: "Admin Can't Update Admin",
          color: "error",
        });
      } else {
        setUserId(value);
        setIsUserId(true);
        openModal();
      }
    };
    const onClickDelteUser = () => {
      if (
        localStorage.getItem("role") === "admin" &&
        rowData.find((item) => item.uid === value)?.role === "admin"
      ) {
        setSnackbarInfo({
          isOpenClose: true,
          message: "Admin Can't Delete Admin",
          color: "error",
        });
      } else {
        mutate(value);
      }
    };
    return (
      <p>
        <span onClick={editDetails}>
          <Edit color="success" className="cursor-pointer me-2" />
        </span>
        <span onClick={onClickDelteUser}>
          <Delete color="error" className="cursor-pointer ms-2" />
        </span>
      </p>
    );
  };

  const RenderTimeDate = ({ value }) => {
    const toReadableDateTime = (st) => {
      if (typeof st !== "string") return null;

      const [date, time] = st?.split("T");

      if (!time && date) return date.split("-").reverse().join("-");

      if (!date || !time) return null;

      return `${date.split("-").reverse().join("-")} ${time.slice(0, 5)}`;
    };

    return <p className="">{toReadableDateTime(value)}</p>;
  };

  // const RenderAcctivities = ({ value }) => {

  //   return <p className="">{value}</p>;
  // };

  const columnDefs = [
    { headerName: "First Name", field: "firstName" },
    { headerName: "Middle Name", field: "middleName" },
    { headerName: "Last Name", field: "lastName" },
    { headerName: "Email", field: "email" },
    { headerName: "Phone", field: "phone" },
    { headerName: "Role", field: "role", cellRenderer: RenderUserRole },
    { headerName: "User Id", field: "uid" },
    {
      headerName: "Activities Access",
      field: "accessActivities",
      // cellRenderer: RenderAcctivities,
    },
    {
      headerName: "Mapped Admins",
      field: "mappedAdmins",
      // cellRenderer: RenderAcctivities,
    },

    {
      headerName: "Create Date",
      field: "createdOn",
      cellRenderer: RenderTimeDate,
    },
    {
      headerName: "Actions",
      field: "uid",
      cellRenderer: ActionBtnRenderer,
    },
  ];
  const [rowData, setRowData] = useState([]);

  // USEEFFECT : users Details
  useEffect(() => {
    if (error) {
      console.log("error: ", error);
    }
    if (!isLoading && !error) {
      if (data) {
        // console.log("users all : ", data);
        setTimeout(() => {
          setRowData(data?.data);
        }, 500);
      }
    }
  }, [isLoading, error, data]);

  const [flag, setFlag] = useState({
    openLoginModal: false,
  });
  const openModal = () => {
    setFlag((preState) => ({
      ...preState,
      openLoginModal: true,
    }));
  };
  const handleCloseModal = () => {
    setFlag((preState) => ({
      ...preState,
      openLoginModal: false,
    }));
    setUserId();
    setIsUserId(false);
  };

  //  SNACKBAR
  const [snackbarInfo, setSnackbarInfo] = useState({
    isOpenClose: false,
    message: "",
    color: "",
  });
  const handleCloseSnackbar = () => {
    setSnackbarInfo({
      isOpenClose: false,
    });
  };
  const autoCloseSnackbar = () => {
    setSnackbarInfo({
      isOpenClose: false,
    });
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    bgcolor: "background.paper",
    border: "2px solid #ffc107",
    borderRadius: "5px",
    boxShadow: 24,
    padding: 0,
  };
  return (
    <>
      <div className="main-container">
        <Navbar />

        {/*Create user button */}
        <div
          className="d-flex flex-row justify-content-between align-items-center px-2 py-1 gap-3"
          style={{ width: "100%" }}
        >
          <div className="left">
            {/* <span className="text-bold">
                <span className="text-secondary">Total Qr Tags: </span>
                {totalTags}
              </span>
              <span className="text-bold">
                {" "}
                <span className="text-secondary">Total Cylinders: </span>
                {cylinderAssets}
              </span>
              <span className="text-bold">
                {" "}
                <span className="text-secondary">Total Tagged Cylinders: </span>
                {activeTags}
              </span> */}
          </div>
          <div className="right d-flex flex-row justify-content-center align-items-center">
            <div className="d-flex align-items-center justify-content-center gap-2">
              <button className="nav-btn" onClick={openModal}>
                Create User
              </button>
            </div>
          </div>
        </div>
        {/* ______________ Data Grid ______________ */}
        <div className="data-grid-main-div d-flex justify-content-center align-content-center">
          <div
            className="ag-theme-alpine data-grid-div"
            style={{ height: "33.5rem", width: "99%" }}
          >
            <AgGridReact
              columnDefs={columnDefs}
              rowData={rowData}
              pagination={true}
              paginationPageSize={10}
              defaultColDef={{
                // flex: 1,
                resizable: true,
              }}
              overlayLoadingTemplate={
                '<span className="ag-overlay-loading-center">Please wait while your rows are loading...</span>'
              }
              overlayNoRowsTemplate={
                '<span className="ag-overlay-loading-center">No data found to display.</span>'
              }
            />
          </div>
        </div>
      </div>

      {/* _____________________* Create or Update User MODAL *_____________________ */}
      <Modal
        open={flag.openLoginModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogContent sx={style}>
          <UserCreateUpdate isUserId={isUserId} userId={userId} />
          <div className="close-btn-div" onClick={handleCloseModal}>
            <Close className="close-btn" />
          </div>
        </DialogContent>
      </Modal>

      {/* Loader */}
      {isLoading && (
        <div className="loader-container">
          <div className="loader">
            <img
              src={"https://storage.googleapis.com/pettag/qr/assets/loader.gif"}
              alt="Loading..."
              loading="lazy"
              style={{ width: "100px", height: "100px" }}
            />
          </div>
        </div>
      )}

      {/* Snack Bar */}
      <Snackbar
        open={snackbarInfo.isOpenClose}
        onClose={autoCloseSnackbar}
        autoHideDuration={5000}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        style={{ top: "25%" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarInfo.color}
          sx={{ width: "100%" }}
        >
          {snackbarInfo.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default UserManagement;
