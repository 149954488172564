import React, { useEffect, useState } from "react";
import axios from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import "./datamaster.css";
import { Delete, Edit, Add, Check, Close } from "@mui/icons-material";
import Navbar from "../../Navbar/Navbar";
import {
  Fab,
  Modal,
  DialogContent,
  Button,
  TextField,
  Snackbar,
  Tooltip,
  Alert as MuiAlert,
} from "@mui/material";
import DemoCsvFile from "../../../assets/masterdata.csv";
import FileDownloadIcon from "../../../assets/filedownload.png";
import { switchUrls } from "../../../api";
const baseURL = switchUrls("cylinder");

// get MasterData function
const getMasterData = async () => {
  const { data } = await axios.post(
    `${baseURL}/api/cylinderqr/getMasterCylinderDetails`,
    {},
    {
      "Content-Type": "application/json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return data;
};

const Datamaster = () => {
  // QUERY : Get Master Data
  const {
    data,
    error: getMasterDataError,
    isLoading,
  } = useQuery({
    queryKey: ["masterdatas"],
    queryFn: getMasterData,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
    retry: 2,
  });

  // MUTATION
  const { mutate, isPending } = useMutation({
    mutationFn: async (cylinderObj) => {
      return await axios.post(
        `${baseURL}/api/cylinderqr/addCylinderMasterDetails`,
        cylinderObj,
        {
          "Content-Type": "application/json",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    },
    onSuccess: (data) => {
      // console.log("success", data);
      setMasterDataInfo((preState) => ({
        ...preState,
        cylinder_type: "",
        cylinder_material: "",
        cylinder_brand: "",
        valve_brand: "",
        cylinder_location: "",
        plant_location: "",
      }));
      setSnackbarInfo({
        isOpenClose: true,
        message: "Master data Added Successfully",
        color: "success",
      });
      setModalOpenClose(false);
      setFlag((preState) => ({
        ...preState,
        isEditing: false,
        isCylinderType: false,
        isMaterialType: false,
        isCylinderBrand: false,
        isValveBrand: false,
        isCylinderLocation: false,
        isPlantLocation: false,
      }));
      windowsReload();
    },
    onError: (error) => {
      // console.log("error", error);
      setSnackbarInfo({
        isOpenClose: true,
        message: error.response.data.msg,
        color: "error",
      });
    },
  });

  const { mutate: updateMasterDataMutate } = useMutation({
    mutationFn: async (cylinderObj) => {
      return await axios.post(
        `${baseURL}/api/cylinderqr/updateCylinderMasterDetails`,
        cylinderObj,
        {
          "Content-Type": "application/json",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    },
    onSuccess: (data) => {
      // console.log("success", data);
      setSnackbarInfo({
        isOpenClose: true,
        message: "Master data Update Successfully",
        color: "success",
      });
      windowsReload();
    },
    onError: (error) => {
      // console.log("error", error);
      setSnackbarInfo({
        isOpenClose: true,
        message: error.response.data.msg,
        color: "error",
      });
    },
  });
  const { mutate: deleteMasterDataMutate, isPending: isPendingUpdate } =
    useMutation({
      mutationFn: async (cylinderObj) => {
        return await axios.post(
          `${baseURL}/api/cylinderqr/deleteCylinderMasterDetails`,
          cylinderObj,
          {
            "Content-Type": "application/json",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      },
      onSuccess: (data) => {
        // console.log("success", data);
        setSnackbarInfo({
          isOpenClose: true,
          message: "Master data Deleted Successfully",
          color: "success",
        });
        windowsReload();
      },
      onError: (error) => {
        // console.log("error", error);
        setSnackbarInfo({
          isOpenClose: true,
          message: error.response.data.msg,
          color: "error",
        });
      },
    });

  const [masterDataInfo, setMasterDataInfo] = useState({
    cylinder_type: "",
    cylinder_material: "",
    cylinder_brand: "",
    valve_brand: "",
    cylinder_location: "",
    plant_location: "",
    cylinderTypeArray: [],
    cylinderMaterialArray: [],
    cylinderBrandArray: [],
    valveBrandArray: [],
    cylinderLocationArray: [],
    plantLocationArray: [],
    previousValue: "",
    editingInfoId: "",
  });

  const handleChangeTextField = (event) => {
    console.log(event.target.name);
    setMasterDataInfo((preState) => ({
      ...preState,
      [event.target.name]: event.target.value,
    }));
  };

  // Flag
  const [flag, setFlag] = useState({
    isEditing: false,
    isCylinderType: false,
    isMaterialType: false,
    isCylinderBrand: false,
    isValveBrand: false,
    isCylinderLocation: false,
    isPlantLocation: false,
  });
  //  SNACKBAR
  const [snackbarInfo, setSnackbarInfo] = useState({
    isOpenClose: false,
    message: "",
    color: "",
  });
  const handleCloseSnackbar = () => {
    setSnackbarInfo({
      isOpenClose: false,
    });
  };
  const autoCloseSnackbar = () => {
    setSnackbarInfo({
      isOpenClose: false,
    });
  };

  // ADD MASTER DATA
  const handleFormSubmit = (event) => {
    event.preventDefault();
    let masterDataObj = {};
    const {
      cylinder_type,
      cylinder_material,
      cylinder_brand,
      valve_brand,
      cylinder_location,
      plant_location,
    } = masterDataInfo;
    if (cylinder_type) {
      masterDataObj = {
        type: "typeOfCylinder",
        value: cylinder_type,
      };
    } else if (cylinder_material) {
      masterDataObj = {
        type: "cylinderMaterial",
        value: cylinder_material,
      };
    } else if (cylinder_brand) {
      masterDataObj = {
        type: "cylinderBrand",
        value: cylinder_brand,
      };
    } else if (valve_brand) {
      masterDataObj = {
        type: "valveBrand",
        value: valve_brand,
      };
    } else if (cylinder_location) {
      masterDataObj = {
        type: "cylinderLocation",
        value: cylinder_location,
      };
    } else if (plant_location) {
      masterDataObj = {
        type: "plantLocation",
        value: plant_location,
      };
    }

    mutate(masterDataObj);
  };

  //  CANCEL MASTER DATA FORM MODAL
  const cancelMasterDataForm = () => {
    setModalOpenClose(false);
    setFlag((preState) => ({
      ...preState,
      isEditing: false,
      isCylinderType: false,
      isMaterialType: false,
      isCylinderBrand: false,
      isValveBrand: false,
      isCylinderLocation: false,
      isPlantLocation: false,
    }));
    setMasterDataInfo((preState) => ({
      ...preState,
      cylinder_type: "",
      cylinder_material: "",
      cylinder_brand: "",
      valve_brand: "",
      cylinder_location: "",
      plant_location: "",
      previousValue: "",
    }));
  };

  // UPDATE MASTER DATA
  const editCylinderInfo = (value, msdType) => {
    if (msdType === "typeOfCylinder") {
      setFlag((preState) => ({
        ...preState,
        isEditing: true,
        isCylinderType: true,
      }));
      setModalOpenClose(true);
      setMasterDataInfo((preState) => ({
        ...preState,
        cylinder_type: value,
        previousValue: value,
      }));
    } else if (msdType === "cylinderMaterial") {
      setFlag((preState) => ({
        ...preState,
        isEditing: true,
        isMaterialType: true,
      }));
      setModalOpenClose(true);
      setMasterDataInfo((preState) => ({
        ...preState,
        cylinder_material: value,
        previousValue: value,
      }));
    } else if (msdType === "cylinderBrand") {
      setFlag((preState) => ({
        ...preState,
        isEditing: true,
        isCylinderBrand: true,
      }));
      setModalOpenClose(true);
      setMasterDataInfo((preState) => ({
        ...preState,
        cylinder_brand: value,
        previousValue: value,
      }));
    } else if (msdType === "valveBrand") {
      setFlag((preState) => ({
        ...preState,
        isEditing: true,
        isValveBrand: true,
      }));
      setModalOpenClose(true);
      setMasterDataInfo((preState) => ({
        ...preState,
        valve_brand: value,
        previousValue: value,
      }));
    } else if (msdType === "cylinderLocation") {
      setFlag((preState) => ({
        ...preState,
        isEditing: true,
        isCylinderLocation: true,
      }));
      setModalOpenClose(true);
      setMasterDataInfo((preState) => ({
        ...preState,
        cylinder_location: value,
        previousValue: value,
      }));
    } else if (msdType === "plantLocation") {
      setFlag((preState) => ({
        ...preState,
        isEditing: true,
        isPlantLocation: true,
      }));
      setModalOpenClose(true);
      setMasterDataInfo((preState) => ({
        ...preState,
        plant_location: value,
        previousValue: value,
      }));
    }
  };

  // UPDATE MASTER DATA
  const updateMasterData = () => {
    const {
      cylinder_type,
      cylinder_material,
      cylinder_brand,
      valve_brand,
      cylinder_location,
      plant_location,
      previousValue,
    } = masterDataInfo;
    let masterDataObj = {};
    if (cylinder_type) {
      masterDataObj = {
        type: "typeOfCylinder",
        valueOld: previousValue,
        valueNew: cylinder_type,
      };
    } else if (cylinder_material) {
      masterDataObj = {
        type: "cylinderMaterial",
        valueOld: previousValue,
        valueNew: cylinder_material,
      };
    } else if (cylinder_brand) {
      masterDataObj = {
        type: "cylinderBrand",
        valueOld: previousValue,
        valueNew: cylinder_brand,
      };
    } else if (valve_brand) {
      masterDataObj = {
        type: "valveBrand",
        valueOld: previousValue,
        valueNew: valve_brand,
      };
    } else if (cylinder_location) {
      masterDataObj = {
        type: "cylinderLocation",
        valueOld: previousValue,
        valueNew: cylinder_location,
      };
    } else if (plant_location) {
      masterDataObj = {
        type: "plantLocation",
        valueOld: previousValue,
        valueNew: plant_location,
      };
    }
    updateMasterDataMutate(masterDataObj);
  };

  // DELETE MASTER DATA
  const deleteMasterData = (value, type) => {
    let masterDataObj = {
      type,
      value,
    };
    deleteMasterDataMutate(masterDataObj);
  };

  const windowsReload = () => {
    window.location.reload();
  };

  // USEEFFECT : FOR MASTER DATA
  useEffect(() => {
    if (getMasterDataError) {
      console.log("getMasterDataError: ", getMasterDataError);
    }
    if (!isLoading && !getMasterDataError) {
      // console.log("getMasterData: ", data);
      if (data) {
        setMasterDataInfo((preState) => ({
          ...preState,
          cylinderTypeArray: [...data.data.typeOfCylinder],
          cylinderMaterialArray: [...data.data.cylinderMaterial],
          cylinderBrandArray: [...data.data.cylinderBrand],
          valveBrandArray: [...data.data.valveBrand],
          cylinderLocationArray: [...data.data.cylinderLocation],
          plantLocationArray: [...data.data.plantLocation],
        }));
      }
    }
  }, [isLoading, getMasterDataError, data]);

  const [modalOpenClose, setModalOpenClose] = useState(false);
  const handleCloseModal = () => {
    setMasterDataInfo((preState) => ({
      ...preState,
      cylinder_type: "",
      cylinder_material: "",
      cylinder_brand: "",
      valve_brand: "",
      cylinder_location: "",
      plant_location: "",
      previousValue: "",
    }));
    setFlag((preState) => ({
      ...preState,
      isEditing: false,
      isCylinderType: false,
      isMaterialType: false,
      isCylinderBrand: false,
      isValveBrand: false,
      isCylinderLocation: false,
      isPlantLocation: false,
    }));
    setModalOpenClose(false);
  };

  const handleOpenFormModal = (msdType) => {
    setModalOpenClose(true);
    if (msdType === "CYLINDER_TYPE") {
      setFlag((preState) => ({
        ...preState,
        isCylinderType: true,
      }));
    } else if (msdType === "MATERIAL") {
      setFlag((preState) => ({
        ...preState,
        isMaterialType: true,
      }));
    } else if (msdType === "CYLINDER_BRAND") {
      setFlag((preState) => ({
        ...preState,
        isCylinderBrand: true,
      }));
    } else if (msdType === "VALVE_BRAND") {
      setFlag((preState) => ({
        ...preState,
        isValveBrand: true,
      }));
    } else if (msdType === "CYLINDER_LOCATION") {
      setFlag((preState) => ({
        ...preState,
        isCylinderLocation: true,
      }));
    } else if (msdType === "PLANT_LOCATION") {
      setFlag((preState) => ({
        ...preState,
        isPlantLocation: true,
      }));
    }
  };

  const [csvFile, setCsvFile] = useState(null);
  const [fileName, setFileName] = useState("");

  // MUTATION : CYLINDERS UPLOAD
  const { mutate: uploadMasterData, isPending: isPendingUploadMasterData } =
    useMutation({
      mutationFn: async (formData) => {
        return await axios.post(
          `${baseURL}/api/cylinderqr/bulkUploadCylinderMasterData`,
          formData,
          {
            "Content-Type": "multipart/form-data",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      },
      onSuccess: (data) => {
        console.log("data", data);
        // setCsvFile(null);
        setSnackbarInfo({
          isOpenClose: true,
          message: data.data.msg,
          color: "success",
        });
        windowsReload();
      },
      onError: (error) => {
        // console.log("error", error);
        setSnackbarInfo({
          isOpenClose: true,
          message: error.response.data.msg,
          color: "error",
        });
      },
    });
  const handleChangeCSVfile = (e) => {
    const file = e.target.files[0];

    if (file && file.name.toLowerCase().endsWith(".csv")) {
      const reader = new FileReader();

      if (file.size > 0) {
        reader.onload = (evt) => {
          setCsvFile(file);
          setFileName(file.name);
        };
        reader.readAsBinaryString(file);
      } else {
        alert(`${file.name} file size is ${file.size} which is empty`);
      }
    } else {
      alert("Please select a CSV file.");
    }
  };

  const onClickUploadFile = (v) => {
    let formData = new FormData();
    formData.append("csv", csvFile);

    uploadMasterData(formData);
  };

  const [deletingValue, setDeletingValue] = useState("");
  const [deletingType, setDeletingType] = useState("");
  const [deleteAlert, setDeleteAlert] = useState(false);
  const handleClickDeletemasterdata = (value, type) => {
    setDeletingValue(value);
    setDeletingType(type);
    setDeleteAlert(true);
  };
  const skipAlert = () => {
    setDeleteAlert(false);
  };
  // modal style
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    height: "25%",
    bgcolor: "background.paper",
    border: "2px solid #ffc107",
    borderRadius: "5px",
    boxShadow: 24,
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  // console.log("master data info :", masterDataInfo);
  return (
    <>
      <Navbar />
      {/* Csv files button */}
      <div
        className="d-flex flex-row justify-content-between align-items-center px-2 py-1 gap-3"
        style={{ width: "100%" }}
      >
        <div className="left">
          {/* <span className="text-bold">
                <span className="text-secondary">Total Qr Tags: </span>
                {totalTags}
              </span>
              <span className="text-bold">
                {" "}
                <span className="text-secondary">Total Cylinders: </span>
                {cylinderAssets}
              </span>
              <span className="text-bold">
                {" "}
                <span className="text-secondary">Total Tagged Cylinders: </span>
                {activeTags}
              </span> */}
        </div>
        <div className="right d-flex flex-row justify-content-center align-items-center">
          <div className="d-flex align-items-center justify-content-center gap-2">
            <input
              id="upload"
              type="file"
              onChange={handleChangeCSVfile}
              className="input-csv-tag-none "
              accept=".csv"
            />
            {!csvFile ? (
              <label htmlFor="upload" className="csv-upload-btn">
                Upload Master Data
              </label>
            ) : (
              <label className="csv-upload-btn d-flex align-items-center justify-content-center gap-2">
                {fileName}
                <span className="delete-btn" onClick={() => setCsvFile(null)}>
                  <Close color="error" className="cursor-pointer" />
                </span>
                <span className="check-btn" onClick={onClickUploadFile}>
                  <Check color="success" className="cursor-pointer" />
                </span>
              </label>
            )}

            <Tooltip title="Download Masterdata CSV Template">
              <a
                href={DemoCsvFile}
                // className="csv-upload-btn"
                download="cylindercsv"
              >
                <img
                  src={FileDownloadIcon}
                  alt="check_list_png"
                  style={{ width: "2rem", height: "2rem" }}
                  className="cursor-pointer"
                />
              </a>
            </Tooltip>
          </div>
        </div>
      </div>
      {/* ______________ Data Grid ______________ */}
      <div className="masterdata-main-div container-fluid d-flex justify-content-center align-content-center">
        <div className="masterdata-row-div row width-100">
          <div className="col-8 border">
            <div className="row row-cols-4">
              {/* ________________________ TYPE ________________________ */}
              <div className="col border-end">
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="text-center">Cylinder Type</h6>
                  <Fab
                    className="fab-btn"
                    onClick={() => handleOpenFormModal("CYLINDER_TYPE")}
                  >
                    <Add color="success" className="cursor-pointer" />
                  </Fab>
                </div>
                {/* details */}
                {masterDataInfo.cylinderTypeArray.map((item, index) => {
                  return (
                    <div
                      className="master-info d-flex justify-content-between mt-3"
                      key={index}
                    >
                      <p className="mb-0">
                        {item.value}

                        <Edit
                          color="success"
                          className="edit-icon me-1"
                          onClick={() =>
                            editCylinderInfo(item.value, item.type)
                          }
                        />
                        <Delete
                          color="error"
                          className="delete-icon ms-1"
                          onClick={() =>
                            handleClickDeletemasterdata(item.value, item.type)
                          }
                        />
                      </p>
                    </div>
                  );
                })}
              </div>
              {/* ________________________ MATERIAL ________________________ */}
              <div className="col border-end">
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="text-center">Cylinder Material</h6>
                  <Fab
                    className="fab-btn"
                    onClick={() => handleOpenFormModal("MATERIAL")}
                  >
                    <Add color="success" className="cursor-pointer" />
                  </Fab>
                </div>
                {masterDataInfo.cylinderMaterialArray.map((item, index) => {
                  return (
                    <div
                      className="master-info d-flex justify-content-between mt-3"
                      key={index}
                    >
                      <p className="mb-0">
                        {item.value}

                        <Edit
                          color="success"
                          className="edit-icon me-1"
                          onClick={() =>
                            editCylinderInfo(item.value, item.type)
                          }
                        />
                        <Delete
                          color="error"
                          className="delete-icon ms-1"
                          onClick={() =>
                            handleClickDeletemasterdata(item.value, item.type)
                          }
                        />
                      </p>
                    </div>
                  );
                })}
              </div>
              {/* ________________________ BRAND ________________________ */}
              <div className="col border-end">
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="text-center">Cylinder Brand</h6>
                  <Fab
                    className="fab-btn"
                    onClick={() => handleOpenFormModal("CYLINDER_BRAND")}
                  >
                    <Add color="success" className="cursor-pointer" />
                  </Fab>
                </div>
                {masterDataInfo.cylinderBrandArray.map((item, index) => {
                  return (
                    <div
                      className="master-info d-flex justify-content-between mt-3"
                      key={index}
                    >
                      <p className="mb-0">
                        {item.value}

                        <Edit
                          color="success"
                          className="edit-icon me-1"
                          onClick={() =>
                            editCylinderInfo(item.value, item.type)
                          }
                        />
                        <Delete
                          color="error"
                          className="delete-icon ms-1"
                          onClick={() =>
                            handleClickDeletemasterdata(item.value, item.type)
                          }
                        />
                      </p>
                    </div>
                  );
                })}
              </div>
              {/* ________________________ VALVE ________________________ */}
              <div className="col border-end">
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="text-center">Valve Brand</h6>
                  <Fab
                    className="fab-btn"
                    onClick={() => handleOpenFormModal("VALVE_BRAND")}
                  >
                    <Add color="success" className="cursor-pointer" />
                  </Fab>
                </div>
                {masterDataInfo.valveBrandArray.map((item, index) => {
                  return (
                    <div
                      className="master-info d-flex justify-content-between mt-3"
                      key={index}
                    >
                      <p className="mb-0">
                        {item.value}

                        <Edit
                          color="success"
                          className="edit-icon me-1"
                          onClick={() =>
                            editCylinderInfo(item.value, item.type)
                          }
                        />
                        <Delete
                          color="error"
                          className="delete-icon ms-1"
                          onClick={() =>
                            handleClickDeletemasterdata(item.value, item.type)
                          }
                        />
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-4 border">
            <div className="row row-cols-2">
              {/* ________________________ CYLINDER LOCATION ________________________ */}
              <div className="col border-end">
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="text-center">Cylinder Location</h6>
                  <Fab
                    className="fab-btn"
                    onClick={() => handleOpenFormModal("CYLINDER_LOCATION")}
                  >
                    <Add color="success" className="cursor-pointer" />
                  </Fab>
                </div>
                {masterDataInfo.cylinderLocationArray.map((item, index) => {
                  return (
                    <div
                      className="master-info d-flex justify-content-between mt-3"
                      key={index}
                    >
                      <p className="mb-0">
                        {item.value}

                        <Edit
                          color="success"
                          className="edit-icon me-1"
                          onClick={() =>
                            editCylinderInfo(item.value, item.type)
                          }
                        />
                        <Delete
                          color="error"
                          className="delete-icon ms-1"
                          onClick={() =>
                            handleClickDeletemasterdata(item.value, item.type)
                          }
                        />
                      </p>
                    </div>
                  );
                })}
              </div>
              {/* ________________________ PLANT LOCATION ________________________ */}
              <div className="col border-end">
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="text-center">Plant Location</h6>
                  <Fab
                    className="fab-btn"
                    onClick={() => handleOpenFormModal("PLANT_LOCATION")}
                  >
                    <Add color="success" className="cursor-pointer" />
                  </Fab>
                </div>
                {masterDataInfo.plantLocationArray.map((item, index) => {
                  return (
                    <div
                      className="master-info d-flex justify-content-between mt-3"
                      key={index}
                    >
                      <p className="mb-0">
                        {item.value}

                        <Edit
                          color="success"
                          className="edit-icon me-1"
                          onClick={() =>
                            editCylinderInfo(item.value, item.type)
                          }
                        />
                        <Delete
                          color="error"
                          className="delete-icon ms-1"
                          onClick={() =>
                            handleClickDeletemasterdata(item.value, item.type)
                          }
                        />
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* _____________________* MODAL *_____________________ */}
      <Modal open={modalOpenClose} onClose={handleCloseModal}>
        <DialogContent sx={style}>
          <form>
            {/*  _______ CYLINDER TYPE MODAL _______ */}
            {flag.isCylinderType && (
              <div
                className="two-field-wrapper"
                style={{ flexDirection: "column" }}
              >
                <div style={{ width: "100%" }}>
                  <TextField
                    label="Cylinder Type *"
                    name="cylinder_type"
                    type="text"
                    variant="outlined"
                    value={masterDataInfo.cylinder_type}
                    onChange={handleChangeTextField}
                    inputProps={{
                      maxLength: 40,
                    }}
                  />
                </div>
              </div>
            )}
            {/*  _______ MATERIAL MODAL _______ */}
            {flag.isMaterialType && (
              <div
                className="two-field-wrapper"
                style={{ flexDirection: "column" }}
              >
                <div style={{ width: "100%" }}>
                  <TextField
                    label="Cylinder Material *"
                    name="cylinder_material"
                    type="text"
                    variant="outlined"
                    value={masterDataInfo.cylinder_material}
                    onChange={handleChangeTextField}
                    inputProps={{
                      maxLength: 40,
                    }}
                  />
                </div>
              </div>
            )}
            {/*  _______ CYLINDER BRAND MODAL _______ */}
            {flag.isCylinderBrand && (
              <div
                className="two-field-wrapper"
                style={{ flexDirection: "column" }}
              >
                <div style={{ width: "100%" }}>
                  <TextField
                    label="Cylinder Brand *"
                    name="cylinder_brand"
                    type="text"
                    variant="outlined"
                    value={masterDataInfo.cylinder_brand}
                    onChange={handleChangeTextField}
                    inputProps={{
                      maxLength: 40,
                    }}
                  />
                </div>
              </div>
            )}
            {/*  _______ VALVE BRAND MODAL _______ */}
            {flag.isValveBrand && (
              <div
                className="two-field-wrapper"
                style={{ flexDirection: "column" }}
              >
                <div style={{ width: "100%" }}>
                  <TextField
                    label="Valve Brand *"
                    name="valve_brand"
                    type="text"
                    variant="outlined"
                    value={masterDataInfo.valve_brand}
                    onChange={handleChangeTextField}
                    inputProps={{
                      maxLength: 40,
                    }}
                  />
                </div>
              </div>
            )}
            {/*  _______ CYLINDER LOCATION MODAL _______ */}
            {flag.isCylinderLocation && (
              <div
                className="two-field-wrapper"
                style={{ flexDirection: "column" }}
              >
                <div style={{ width: "100%" }}>
                  <TextField
                    label="Cylinder Location *"
                    name="cylinder_location"
                    type="text"
                    variant="outlined"
                    value={masterDataInfo.cylinder_location}
                    onChange={handleChangeTextField}
                    inputProps={{
                      maxLength: 40,
                    }}
                  />
                </div>
              </div>
            )}
            {/*  _______ PLANT LOCATION MODAL _______ */}
            {flag.isPlantLocation && (
              <div
                className="two-field-wrapper"
                style={{ flexDirection: "column" }}
              >
                <div style={{ width: "100%" }}>
                  <TextField
                    label="Plant Location *"
                    name="plant_location"
                    type="text"
                    variant="outlined"
                    value={masterDataInfo.plant_location}
                    onChange={handleChangeTextField}
                    inputProps={{
                      maxLength: 40,
                    }}
                  />
                </div>
              </div>
            )}
            {/* ADD & CANCEL BUTTON */}
            <div className="d-flex align-items-center justify-content-center gap-2">
              <Button
                type="button"
                variant="contained"
                className="cancel-btn"
                onClick={cancelMasterDataForm}
              >
                Cancel
              </Button>
              {!flag.isEditing ? (
                <Button
                  type="button"
                  variant="contained"
                  className="add-btn"
                  onClick={handleFormSubmit}
                  disabled={isPending}
                >
                  {isPending ? "Adding..." : "Add"}
                </Button>
              ) : (
                <Button
                  type="button"
                  variant="contained"
                  className="add-btn"
                  onClick={updateMasterData}
                  disabled={isPendingUpdate}
                >
                  {isPendingUpdate ? "Updating..." : "Update"}
                </Button>
              )}
            </div>
          </form>
        </DialogContent>
      </Modal>

      {/* Loader */}
      {isLoading ||
        (isPendingUploadMasterData && (
          <div className="loader-container">
            <div className="loader">
              <img
                src={
                  "https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                }
                alt="Loading..."
                loading="lazy"
                style={{ width: "100px", height: "100px" }}
              />
            </div>
          </div>
        ))}

      {/* Snack Bar */}
      <Snackbar
        open={snackbarInfo.isOpenClose}
        onClose={autoCloseSnackbar}
        autoHideDuration={5000}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        style={{ top: "25%" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarInfo.color}
          sx={{ width: "100%" }}
        >
          {snackbarInfo.message}
        </Alert>
      </Snackbar>

      {/* Delete Alert */}
      {deleteAlert && (
        <div className="loader-container ">
          <div
            className="loader d-flex flex-column align-items-center justify-content-center border rounded-3 p-3 "
            style={{ background: "#f5f5f5" }}
          >
            <h5 className="">Are you sure ? you want to delete.</h5>
            <div className="d-flex flex-row align-items-center justify-content-center mt-2 gap-4">
              <span
                className="cursor-pointer p-2 border rounded-3 text-center"
                onClick={skipAlert}
                style={{ width: "5rem", background: "#f3e0e0" }}
              >
                Cancel
              </span>
              <span
                className="cursor-pointer p-2 border rounded-3 text-center"
                onClick={() => deleteMasterData(deletingValue, deletingType)}
                style={{ width: "5rem", background: "#d8ffd8" }}
              >
                Ok
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Datamaster;
