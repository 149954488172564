import React, { useEffect, useState } from "react";
// import Navbar from "../Navbar/Navbar";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tab,
  Tabs,
  Snackbar,
  Alert as MuiAlert,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Link } from "react-router-dom";
import axios from "axios";
import { switchUrls } from "../../api";
import { useQuery, useMutation } from "@tanstack/react-query";
import QrReader from "react-qr-reader";
import Gascylinder from "../../assets/gascylinder.png";
import Footer from "../Footer/Footer";
const baseURL = switchUrls("cylinder");

// get MasterData function
const getMasterData = async () => {
  const { data } = await axios.post(
    `${baseURL}/api/cylinderqr/getSelectedCylinderMasterData`,
    { selectedKeys: ["cylinderLocation", "plantLocation"] },
    {
      "Content-Type": "application/json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return data;
};

const TransactionScan = () => {
  // QUERY : Get Master Data
  const {
    data: masterDataDetails,
    error: errorMasterData,
    isLoading: isLoadingMasterData,
  } = useQuery({
    queryKey: ["masterdatas"],
    queryFn: getMasterData,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
    retry: 1,
  });

  // MASTER DATA STATE
  const [masterDataInfoState, setMasterDataInfoState] = useState({
    cylinderLocationArray: [],
    plantLocationArray: [],
  });

  // USEEFFECT : Masterdata Details
  useEffect(() => {
    if (errorMasterData) {
      console.log("error: ", errorMasterData);
    }
    if (!isLoadingMasterData && !errorMasterData) {
      // console.log("master data detials: ", masterDataDetails);
      if (masterDataDetails) {
        setMasterDataInfoState((preState) => ({
          ...preState,
          cylinderLocationArray: [
            ...masterDataDetails.data.cylinderLocation,
            ...masterDataDetails.data.plantLocation,
          ],
          plantLocationArray: [
            ...masterDataDetails.data.cylinderLocation,
            ...masterDataDetails.data.plantLocation,
          ],
        }));
      }
    }
  }, [isLoadingMasterData, errorMasterData, masterDataDetails]);

  // LOCATION & STATUS STATES FUNCTIONS
  const [statusLocation, setStatusLocation] = useState({
    status: null,
    location: null,
    in_plant_location: "",
    out_of_plant_location: "",
  });

  const handleChangeStatusLocation = (e) => {
    if (e.target.name === "location") {
      setStatusLocation((preState) => ({
        ...preState,
        location: e.target.value,
        in_plant_location: "",
        out_of_plant_location: "",
      }));
    } else {
      setStatusLocation({ ...statusLocation, [e.target.name]: e.target.value });
    }
  };
  const [tabValue, setTabValue] = useState(0);
  const handleTabChang = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  const [scanQrCodes, setScanQrCodes] = useState(() => {
    const storedData = localStorage.getItem("scannedTags");
    return storedData ? JSON.parse(storedData) : [];
  });
  const [isScanned, setIsScanned] = useState(false);
  const [isCheckedTagStatus, setIsCheckedTagStatus] = useState(true);
  const [alreadyScanTag, SetAlreadyScanTag] = useState();
  const clickNextToScan = () => {
    setSetselectedTab(1);
    setTabValue(1);
  };

  const onScanResult = (result) => {
    if (result) {
      // console.log("result: ", result);
      const qrCode = result?.split("/scan/")[1];
      if (qrCode) {
        if (!scanQrCodes?.includes(qrCode)) {
          if (isCheckedTagStatus) {
            checkTagStatus(qrCode);
          }
        } else {
          setIsScanned(true);
          SetAlreadyScanTag(qrCode);
          setTimeout(() => {
            setIsScanned(false);
          }, 1000);
        }
      }
    }
  };

  const onScanError = (error) => {
    if (error) {
      console.info("scan error: ", error);
    }
  };

  // SAVE SCANNED TAG IN LOCAL STORAGE
  useEffect(() => {
    localStorage.setItem("scannedTags", JSON.stringify(scanQrCodes));
    if (scanQrCodes?.length >= 50) {
      setShowLimitAlert(true);
    }
  }, [scanQrCodes]);

  const [showLimitAlert, setShowLimitAlert] = useState(false);
  const [showRemoveTagAlert, setShowRemoveTagAlert] = useState(false);
  const [removingTag, setRemovingTag] = useState("");
  const skipAlert = () => {
    setShowLimitAlert(false);
    setShowRemoveTagAlert(false);
  };

  const handleLongPress = (tag) => {
    setRemovingTag(tag);
    setShowRemoveTagAlert(true);
  };

  // REMOVE SCANNED TAG
  const confirmRemoveScannedTag = () => {
    if (removingTag) {
      setScanQrCodes((preState) => {
        return [...preState].filter((item) => item !== removingTag);
      });
    }
    setShowRemoveTagAlert(false);
    setRemovingTag("");
  };

  // MUTATION : STATUS & LOCATION
  const { mutate, isPending } = useMutation({
    mutationFn: async (transactionObj) => {
      return await axios.post(
        `${baseURL}/api/cylinderqr/bulkcylinderCheckinCheckout`,
        transactionObj,
        {
          "Content-Type": "application/json",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    },
    onSuccess: (data) => {
      // console.log("success", data);
      setSnackbarInfo({
        isOpenClose: true,
        message: "Bulk Transaction Done",
        color: "success",
      });
      windowsReload();
      localStorage.removeItem("scannedTags");
    },
    onError: (error) => {
      setSnackbarInfo({
        isOpenClose: true,
        message: error.response.data.msg,
        color: "error",
      });
    },
  });
  // Get Tag Status
  const { mutate: checkTagStatus } = useMutation({
    mutationFn: async (tagNumber) => {
      setIsCheckedTagStatus(false);
      return await axios.post(`${baseURL}/api/cylinderqr/getTagStatus`, {
        tagNumber,
      });
    },
    onSuccess: (data) => {
      // console.log("data: ", data);
      if (!scanQrCodes?.includes(data?.data?.tagNumber)) {
        setScanQrCodes((preState) => [...preState, data?.data?.tagNumber]);
      }
      setTimeout(() => {
        setIsCheckedTagStatus(true);
      }, 300);
    },
    onError: (error) => {
      setSnackbarInfo({
        isOpenClose: true,
        message: error.response.data.msg,
        color: "error",
      });
      setTimeout(() => {
        setIsCheckedTagStatus(true);
      }, 1500);
    },
  });

  //  SNACKBAR
  const [snackbarInfo, setSnackbarInfo] = useState({
    isOpenClose: false,
    message: "",
    color: "",
  });
  const handleCloseSnackbar = () => {
    setSnackbarInfo({
      isOpenClose: false,
    });
  };
  const autoCloseSnackbar = () => {
    setSnackbarInfo({
      isOpenClose: false,
    });
  };

  const windowsReload = () => {
    window.location.href = `/cylinder-management`;
  };

  const addBulkTransaction = () => {
    const { status, location, in_plant_location, out_of_plant_location } =
      statusLocation;

    if (!status || !location || !scanQrCodes) {
      setSnackbarInfo({
        isOpenClose: true,
        message: "Please Fill the Transaction Value",
        color: "error",
      });
    } else if (!scanQrCodes.length > 0) {
      setSnackbarInfo({
        isOpenClose: true,
        message: "Please Scan QR Code",
        color: "error",
      });
    } else {
      const obj = {
        qrCodes: scanQrCodes,
        cylinderStatus: status,
        location: location,
        toLocation: in_plant_location || out_of_plant_location,
      };

      // console.log("obj: ", obj);
      mutate(obj);
    }
  };

  const [setselectedTab, setSetselectedTab] = useState(0);
  const handleTabClick = (v) => {
    if (v === 0) {
      setSetselectedTab(0);
    } else if (v === 1) {
      setSetselectedTab(1);
    }
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  // console.log("scanQrCodes: ", scanQrCodes);

  return (
    <>
      {/* <Navbar />  */}
      <div className="transaction-main-div">
        <div className="container-fluid" style={{ marginTop: "1.2rem" }}>
          <div className="row cylinder-management-row-div">
            <div className="col-12 d-flex align-items-center justify-content-center ">
              <div className="col-4">
                <Link to={"/cylinder-management"} className="col-4">
                  <img
                    src={Gascylinder}
                    alt="cylindermanagement"
                    style={{ width: "4rem" }}
                  />
                </Link>
              </div>
              <div className="col-4 d-flex flex-column align-items-center justify-content-center text-bold ">
                <span className="">CYLINDER</span>
                <span className=""> MANAGEMENT</span>
              </div>
              <div className="col-4"></div>
            </div>
          </div>
          <div className="row" style={{ color: "black", zIndex: 999 }}>
            <Tabs
              variant="fullWidth"
              value={tabValue}
              onChange={handleTabChang}
              style={{ padding: 0 }}
            >
              <Tab
                label="Transaction"
                value={0}
                className={`tab-btn0 ${
                  setselectedTab === 0 ? "bg-tag8-clr" : ""
                }`}
                onClick={() => handleTabClick(0)}
              />
              <Tab
                label="Scan"
                value={1}
                className={`tab-btn1 ${
                  setselectedTab === 1 ? "bg-tag8-clr" : ""
                }`}
                onClick={() => handleTabClick(1)}
              />
            </Tabs>
          </div>
          {/* transaction */}
          {tabValue === 0 && (
            <div className="row py-2">
              <div className="col-12 d-flex flex-column align-items-center justify-content-center gap-4 mt-4">
                <div className="capacity-status d-flex flex-column align-items-center justify-content-center">
                  {/* capacity stautus */}
                  <FormControl>
                    <FormLabel className="transaction-form-lebal">
                      CAPACITY STATUS *
                    </FormLabel>
                    <RadioGroup
                      row
                      value={statusLocation.status}
                      name="status"
                      onChange={handleChangeStatusLocation}
                      className="transaction-form-radio-grp"
                    >
                      <FormControlLabel
                        value="Full"
                        control={<Radio />}
                        label="Full"
                      />
                      <FormControlLabel
                        value="Empty"
                        control={<Radio />}
                        label="Empty"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                {/* location */}
                <div className="transfer-location d-flex flex-column align-items-center justify-content-center">
                  <FormControl>
                    <FormLabel className="transaction-form-lebal">
                      TRANSFER LOCATION *
                    </FormLabel>
                    <RadioGroup
                      row
                      name="location"
                      value={statusLocation.location}
                      onChange={handleChangeStatusLocation}
                      className="transaction-form-radio-grp"
                    >
                      <FormControlLabel
                        value="In Plant"
                        control={<Radio />}
                        label="In Plant"
                      />
                      <FormControlLabel
                        value="Out Of Plant"
                        control={<Radio />}
                        label="Out Of Plant"
                      />
                    </RadioGroup>
                  </FormControl>
                  {statusLocation.location === "In Plant" && (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        In Plant Location *
                      </InputLabel>
                      <Select
                        label="In Plant Location *"
                        name="in_plant_location"
                        value={statusLocation.in_plant_location}
                        onChange={handleChangeStatusLocation}
                      >
                        {masterDataInfoState.cylinderLocationArray.map(
                          (item, index) => {
                            return (
                              <MenuItem value={item.value} key={index}>
                                {item.value}
                              </MenuItem>
                            );
                          }
                        )}
                      </Select>
                    </FormControl>
                  )}

                  {statusLocation.location === "Out Of Plant" && (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Out Of Plant Location *
                      </InputLabel>
                      <Select
                        label="Out Of Plant Location *"
                        name="out_of_plant_location"
                        value={statusLocation.out_of_plant_location}
                        onChange={handleChangeStatusLocation}
                      >
                        {masterDataInfoState.plantLocationArray.map(
                          (item, index) => {
                            return (
                              <MenuItem value={item.value} key={index}>
                                {item.value}
                              </MenuItem>
                            );
                          }
                        )}
                      </Select>
                    </FormControl>
                  )}
                </div>
                <div
                  className="two-field-wrapper"
                  style={{ justifyContent: "center", marginTop: "2rem" }}
                >
                  <div className="mui-btn">
                    <Button
                      type="button"
                      variant="contained"
                      className="test-add-btn"
                      onClick={clickNextToScan}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Scan */}
          {tabValue === 1 && (
            <>
              <div className="row">
                <div className="col-12 mx-auto">
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <span className="">
                      Scan:{" "}
                      <span className="text-secondary">
                        {scanQrCodes.length}
                      </span>
                    </span>
                  </div>
                  <div className="scanner-main-div">
                    <div className="scanner-div">
                      <QrReader
                        delay={100}
                        onScan={onScanResult}
                        onError={onScanError}
                        legacyMode={false}
                        facingMode={"environment"}
                      />
                    </div>
                    {isScanned && (
                      <div className="scanned-message">
                        <h6 className="text-danger">Tag Already Scanned</h6>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {scanQrCodes.length !== 0 && (
                <div className="row bt-gray mt-2">
                  <div className=" d-flex flex-column align-items-center justify-content-center py-2">
                    <h6 className="text-center text-decoration-underline">
                      Scanned Tagged
                    </h6>
                    <div className={`scroll-container scan-tag-div `}>
                      <div className="scan-tags-wrapped">
                        {scanQrCodes.map((tag, index) => {
                          return (
                            <h6
                              className={`scan-tag-number ${
                                isScanned && alreadyScanTag === tag
                                  ? "bg-tag"
                                  : ""
                              } ${scanQrCodes.length < 4 ? "bb-1" : ""}`}
                              key={index}
                            >
                              <span className="dfjcac gap-4">
                                <span
                                  className=""
                                  style={{ width: "2rem", height: "1rem" }}
                                >
                                  {index + 1}
                                </span>
                                <span className="">{tag}</span>
                              </span>
                              <span
                                className="cursor-pointer"
                                onClick={() => handleLongPress(tag)}
                              >
                                <Close color="error" />
                              </span>
                            </h6>
                          );
                        })}
                      </div>
                    </div>

                    {/* <div className="">
                      <span className="note-heading">Note: </span>
                      <span className="note-message">
                        To eliminate a specific tag number, simply long press on
                        it.
                      </span>
                    </div> */}
                  </div>
                </div>
              )}

              <Button
                type="button"
                variant="contained"
                className="test-add-btn mt-2"
                disabled={isPending}
                onClick={addBulkTransaction}
              >
                Proceed
              </Button>
            </>
          )}
        </div>
        <Footer />
      </div>

      {/* Snack Bar */}
      <Snackbar
        open={snackbarInfo.isOpenClose}
        onClose={autoCloseSnackbar}
        autoHideDuration={5000}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        style={{ top: "25%" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarInfo.color}
          sx={{ width: "100%" }}
        >
          {snackbarInfo.message}
        </Alert>
      </Snackbar>

      {showLimitAlert && (
        <div className="loader-container ">
          <div
            className="loader d-flex flex-column align-items-center justify-content-center border rounded-3 p-3 "
            style={{ background: "#f5f5f5" }}
          >
            <h5 className="">The scan tag limit has been exceeded</h5>
            <div className="d-flex flex-row align-items-center justify-content-center mt-2 gap-4">
              <span
                className="cursor-pointer p-2 border rounded-3 text-center"
                onClick={skipAlert}
                style={{ width: "5rem", background: "#f3e0e0" }}
              >
                Cancel
              </span>
              <span
                className="cursor-pointer p-2 border rounded-3 text-center"
                onClick={skipAlert}
                style={{ width: "5rem", background: "#d8ffd8" }}
              >
                OK
              </span>
            </div>
          </div>
        </div>
      )}
      {showRemoveTagAlert && (
        <div className="loader-container ">
          <div
            className="loader d-flex flex-column align-items-center justify-content-center border rounded-3 p-3 "
            style={{ background: "#f5f5f5" }}
          >
            <h5 className="">Are you sure ? you want to remove the Tag</h5>
            <div className="d-flex flex-row align-items-center justify-content-center mt-2 gap-4">
              <span
                className="cursor-pointer p-2 border rounded-3 text-center"
                onClick={skipAlert}
                style={{ width: "5rem", background: "#f3e0e0" }}
              >
                Cancel
              </span>
              <span
                className="cursor-pointer p-2 border rounded-3 text-center"
                onClick={confirmRemoveScannedTag}
                style={{ width: "5rem", background: "#d8ffd8" }}
              >
                Ok
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TransactionScan;
