import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  Snackbar,
  Alert as MuiAlert,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import MuiPhoneInput from "material-ui-phone-number";
import axios from "axios";
import { switchUrls } from "../../../api";
import { useMutation, useQuery } from "@tanstack/react-query";
const baseURL = switchUrls("cylinder");

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 286,
      width: 210,
    },
  },
};

const activities = [
  "Tag Activation",
  "Tag Edition",
  // "Tag Deletion",
  "Hydrotesting",
  "Transaction",
];

const getUserDetails = async (userid) => {
  const { data } = await axios.post(
    `${baseURL}/api/cylinderqr/getUser`,
    {
      uid: userid,
    },
    {
      "Content-Type": "application/json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return data;
};

const getAllAdmins = async () => {
  const { data } = await axios.post(
    `${baseURL}/api/cylinderqr/getAllAdmins`,
    {},
    {
      "Content-Type": "application/json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return data;
};

const UserCreateUpdate = ({ isUserId, userId }) => {
  const apiEndPoint = userId ? "updateUser" : "userRegister";

  // console.log("props: ", isUserId, userId);

  // QUERY : Get Cylinder Data
  const { data, error, isLoading } = useQuery({
    queryKey: ["userDetails"],
    queryFn: () => getUserDetails(userId),
    enabled: !!userId,
    refetchOnWindowFocus: false,
    // staleTime: 1000 * 60 * 5,
    retry: 2,
  });

  // QUERY : All Admins
  const {
    data: allAdmins,
    error: errorAllAdmin,
    isLoading: isLoadingAllAdmin,
  } = useQuery({
    queryKey: ["allAdmins"],
    queryFn: () => getAllAdmins(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
    retry: 2,
  });

  const [userInfo, setUserInfo] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    role: "",
    phone: "",
    email: "",
    password: "",
    showPassword: false,
    userId: userId,
  });
  const handleClickShowPassword = () => {
    setUserInfo((preState) => ({
      ...preState,
      showPassword: !userInfo.showPassword,
    }));
  };

  // Input Change Handler
  const handleChangeInput = (e) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
    if (e.target.name === "role" && userInfo.role === "admin") {
      setAccessActivities([]);
    }
  };

  // Only Phone Change Handler
  const handleChangePhone = (value) => {
    setUserInfo({ ...userInfo, phone: value });
  };

  // USEEFFECT : user Details
  useEffect(() => {
    if (error) {
      console.log("error: ", error);
      // if (error?.response?.data?.tagStatus === "invalid") {
      //   setSnackbarInfo({
      //     isOpenClose: true,
      //     message: "Invalid Tag Number",
      //     color: "error",
      //   });
      // }
    }
    if (!isLoading && !error) {
      if (data) {
        console.log("data: ", data);
        setUserInfo(() => ({
          firstName: data?.data?.firstName,
          middleName: data?.data?.middleName,
          lastName: data?.data?.lastName,
          role: data?.data?.role,
          email: data?.data?.email,
          phone: data?.data?.phone,
          userId: data?.data?.uid,
        }));
        setAccessActivities(data?.data?.accessActivities);
        setMappedAdminVlaue(data?.data?.mappedAdmins);
      }
    }
  }, [isLoading, error, data]);

  // USEEFFECT : Get All Admins
  useEffect(() => {
    if (errorAllAdmin) {
      console.log("errorAllAdmin: ", errorAllAdmin);
      setSnackbarInfo({
        isOpenClose: true,
        message: errorAllAdmin.response.data.msg,
        color: "error",
      });
    }
    if (!isLoadingAllAdmin && !errorAllAdmin) {
      if (allAdmins) {
        console.log("allAdmins: ", allAdmins);
        setMappedAdmin(allAdmins?.data);
      }
    }
  }, [allAdmins, isLoadingAllAdmin, errorAllAdmin]);

  useEffect(() => {
    if (!isUserId) {
      setUserInfo(() => ({
        firstName: "",
        middleName: "",
        lastName: "",
        role: "",
        phone: "",
        email: "",
        password: "",
        userId: "",
      }));
      setAccessActivities([]);
    }
  }, [isUserId]);

  // MUTATION
  const { mutate, isPending } = useMutation({
    mutationFn: async (formData) => {
      return await axios.post(
        `${baseURL}/api/cylinderqr/${apiEndPoint}`,
        formData,
        {
          "Content-Type": "application/json",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    },
    onSuccess: (data) => {
      console.log("success", data);
      setSnackbarInfo({
        isOpenClose: true,
        message: data.data.msg,
        color: "success",
      });
      windowsReload();
    },
    onError: (error) => {
      console.log("error", error);
      setSnackbarInfo({
        isOpenClose: true,
        message: error.response.data.msg,
        color: "error",
      });
    },
  });

  const windowsReload = () => {
    window.location.reload();
  };
  //   Submit Form
  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      firstName,
      middleName,
      lastName,
      role,
      phone,
      email,
      password,
      userId,
    } = userInfo;
    if (
      firstName &&
      middleName &&
      lastName &&
      role &&
      phone &&
      email &&
      accessActivities &&
      mappedAdminValue
    ) {
      const userInfoObj = {
        firstName,
        middleName,
        lastName,
        role,
        accessActivities,
        mappedAdminValue,
        phone,
        email,
        password,
        userId,
      };
      mutate(userInfoObj);
    } else {
      //   setSnackbarInfo({
      //     isOpenClose: true,
      //     message: "Please Fill the Required Field",
      //     color: "error",
      //   });
    }
  };

  const [accessActivities, setAccessActivities] = useState([]);
  const [mappedAdmin, setMappedAdmin] = useState([]);
  const [mappedAdminValue, setMappedAdminVlaue] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setAccessActivities(typeof value === "string" ? value.split(",") : value);
  };
  const handleChangeMappedAdmin = (event) => {
    const {
      target: { value },
    } = event;
    setMappedAdminVlaue(typeof value === "string" ? value.split(",") : value);
  };

  //  SNACKBAR
  const [snackbarInfo, setSnackbarInfo] = useState({
    isOpenClose: false,
    message: "",
    color: "",
  });
  const handleCloseSnackbar = () => {
    setSnackbarInfo({
      isOpenClose: false,
    });
  };
  const autoCloseSnackbar = () => {
    setSnackbarInfo({
      isOpenClose: false,
    });
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  // console.log("mappedAdminValue: ", mappedAdminValue);
  return (
    <>
      <div className="container-navbar">
        <div className="row mx-auto" style={{ width: "100%" }}>
          <div className="col-10 m-auto py-3">
            <h6 className="text-center">
              Please enter your details to {isUserId ? "Update " : "Create "}
              User
            </h6>
          </div>
          <div className="col-11 col-md-8 col-lg-6 mx-auto">
            <form onSubmit={handleSubmit} className="login-form">
              {/* First Name and Middle Name */}
              <div className="two-field-wrapper">
                <div className="mui-text-field">
                  <TextField
                    variant="outlined"
                    label="First Name"
                    name="firstName"
                    type="text"
                    required
                    value={userInfo.firstName}
                    onChange={handleChangeInput}
                  />
                </div>
                <div className="mui-text-field">
                  <TextField
                    variant="outlined"
                    label="Middle Name"
                    name="middleName"
                    type="text"
                    required
                    value={userInfo.middleName}
                    onChange={handleChangeInput}
                  />
                </div>
              </div>

              {/* Last Name and Phone No */}
              <div className="two-field-wrapper">
                <div className="mui-text-field">
                  <TextField
                    variant="outlined"
                    label="Last Name"
                    name="lastName"
                    type="text"
                    required
                    value={userInfo.lastName}
                    onChange={handleChangeInput}
                  />
                </div>
                <div className="mui-text-field-single">
                  <MuiPhoneInput
                    label="Phone"
                    defaultCountry="in"
                    value={userInfo.phone}
                    regions={[
                      "america",
                      "caribbean",
                      "asia",
                      "middle-east",
                      "oceania",
                      "europe",
                      "africa",
                    ]}
                    name="phone"
                    variant="outlined"
                    onChange={handleChangePhone}
                    required
                  />
                </div>
              </div>
              {/* Role & Activities Access  */}
              <div className="two-field-wrapper">
                <div className="mui-text-field">
                  <TextField
                    select
                    variant="outlined"
                    label="User Role"
                    name="role"
                    value={userInfo.role}
                    onChange={handleChangeInput}
                    required
                  >
                    {localStorage.getItem("role") === "superadmin" && (
                      <MenuItem value="admin">Admin</MenuItem>
                    )}
                    {localStorage.getItem("role") === "admin" && (
                      <MenuItem value="user">User</MenuItem>
                    )}
                  </TextField>
                </div>
                {userInfo.role === "user" || !userInfo.role ? (
                  <div className="mui-text-field">
                    <FormControl fullWidth>
                      <InputLabel id="demo-multiple-checkbox-label">
                        Activities Access
                      </InputLabel>
                      <Select
                        multiple
                        value={accessActivities}
                        onChange={handleChange}
                        input={<OutlinedInput label="Activities Access" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {activities.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox
                              checked={accessActivities?.indexOf(name) > -1}
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                ) : (
                  <div className="mui-text-field">
                    <FormControl fullWidth>
                      <InputLabel id="demo-multiple-checkbox-label">
                        Activities Access
                      </InputLabel>
                      <Select
                        multiple
                        value={["All"]}
                        // onChange={handleChange}
                        input={<OutlinedInput label="Activities Access" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                        readOnly
                      >
                        {/* {activities.map((name) => ( */}
                        <MenuItem value={"All"}>
                          <Checkbox checked={true} />
                          <ListItemText primary={"All"} />
                        </MenuItem>
                        {/* ))} */}
                      </Select>
                    </FormControl>
                  </div>
                )}
              </div>
              {/* Mapped Addmin & Email  */}
              {localStorage.getItem("role") === "admin" && (
                <div className="two-field-wrapper">
                  <div className="mui-text-field">
                    <FormControl fullWidth>
                      <InputLabel id="demo-multiple-checkbox-label">
                        Mapped Admins
                      </InputLabel>
                      <Select
                        multiple
                        value={mappedAdminValue}
                        onChange={handleChangeMappedAdmin}
                        input={<OutlinedInput label="Mapped Admins" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {mappedAdmin.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox
                              checked={mappedAdminValue?.indexOf(name) > -1}
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="mui-text-field">
                    <TextField
                      label="Email"
                      required
                      variant="outlined"
                      name="email"
                      value={userInfo.email}
                      onChange={handleChangeInput}
                    />
                  </div>
                </div>
              )}
              {/* email & password */}
              <div className="two-field-wrapper">
                {localStorage.getItem("role") === "superadmin" && (
                  <div
                    className={`${
                      userId ? "mui-text-field" : "mui-text-field-single"
                    } `}
                  >
                    <TextField
                      label="Email"
                      required
                      variant="outlined"
                      name="email"
                      value={userInfo.email}
                      onChange={handleChangeInput}
                    />
                  </div>
                )}
                {!userId && (
                  <div className="mui-text-field">
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                      <InputLabel htmlFor="outlined-adornment-password">
                        Password *
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={userInfo.showPassword ? "text" : "password"}
                        name="password"
                        value={userInfo.password}
                        onChange={handleChangeInput}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {userInfo.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password *"
                      />
                    </FormControl>
                  </div>
                )}
              </div>
              {/* button  */}
              <div
                className="two-field-wrapper"
                style={{ justifyContent: "center" }}
              >
                <div className="mui-text-field">
                  <Button
                    type="submit"
                    variant="contained"
                    className="login-sumit-btn"
                    disabled={isPending}
                  >
                    {isUserId ? "Update" : "Create"}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Snack Bar */}
      <Snackbar
        open={snackbarInfo.isOpenClose}
        onClose={autoCloseSnackbar}
        autoHideDuration={5000}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        style={{ top: "25%" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarInfo.color}
          sx={{ width: "100%" }}
        >
          {snackbarInfo.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default UserCreateUpdate;
