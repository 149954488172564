import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Snackbar,
  Alert as MuiAlert,
  MenuItem,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import MuiPhoneInput from "material-ui-phone-number";
import axios from "axios";
import { switchUrls } from "../../api";
import { useMutation, useQuery } from "@tanstack/react-query";
import Navbar from "../Navbar/Navbar";
import { useParams } from "react-router-dom";
const baseURL = switchUrls("cylinder");

const getCompanyDetails = async (companyid) => {
  const { data } = await axios.post(
    `${baseURL}/api/company/getCompany`,
    {
      companyId: companyid,
    },
    {
      "Content-Type": "application/json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return data;
};
const Company = () => {
  const { companyId } = useParams();

  const apiEndPoint = companyId ? "updateCompanyData" : "companyRegister";
  // QUERY : Get Cylinder Data
  const { data, error, isLoading } = useQuery({
    queryKey: ["cylinders"],
    queryFn: () => getCompanyDetails(companyId),
    enabled: !!companyId,
    refetchOnWindowFocus: false,
    // staleTime: 1000 * 60 * 5,
    retry: 2,
  });

  const [companyInfo, setCompanyInfo] = useState({
    name: "",
    companyType: "",
    ownerFirstName: "",
    ownerLastName: "",
    phone: "",
    email: "",
    password: "",
    companyImgUrl: "",
    showPassword: false,
    compId: companyId,
  });
  const handleClickShowPassword = () => {
    setCompanyInfo((preState) => ({
      ...preState,
      showPassword: !companyInfo.showPassword,
    }));
  };

  // Input Change Handler
  const handleChangeInput = (e) => {
    setCompanyInfo({ ...companyInfo, [e.target.name]: e.target.value });
  };

  // Only Phone Change Handler
  const handleChangePhone = (value) => {
    setCompanyInfo({ ...companyInfo, phone: value });
  };
  const handleChangeFile = (e) => {
    setCompanyInfo({ ...companyInfo, companyImgUrle: e.target.files[0] });
  };

  //  SNACKBAR
  const [snackbarInfo, setSnackbarInfo] = useState({
    isOpenClose: false,
    message: "",
    color: "",
  });
  const handleCloseSnackbar = () => {
    setSnackbarInfo({
      isOpenClose: false,
    });
  };
  const autoCloseSnackbar = () => {
    setSnackbarInfo({
      isOpenClose: false,
    });
  };

  // USEEFFECT : Compay Details
  useEffect(() => {
    if (error) {
      console.log("error: ", error);
      // if (error?.response?.data?.tagStatus === "invalid") {
      //   setSnackbarInfo({
      //     isOpenClose: true,
      //     message: "Invalid Tag Number",
      //     color: "error",
      //   });
      // }
    }
    if (!isLoading && !error) {
      if (data) {
        // console.log("data: ", data);
        setCompanyInfo(() => ({
          companyType: data?.data?.companyType,
          name: data?.data?.name,
          email: data?.data?.email,
          phone: data?.data?.phone,
          ownerFirstName: data?.data?.ownerFirstName,
          ownerLastName: data?.data?.ownerLastName,
          compId: data?.data?.companyId,
          companyImgUrl: data?.data?.companyImgUrl,
        }));
      }
    }
  }, [isLoading, error, data]);
  useEffect(() => {
    if (!companyId) {
      setCompanyInfo(() => ({
        companyType: "",
        name: "",
        email: "",
        phone: "",
        ownerFirstName: "",
        ownerLastName: "",
        password: "",
        compId: "",
      }));
    }
  }, [companyId]);

  // MUTATION
  const { mutate, isPending } = useMutation({
    mutationFn: async (formData) => {
      return await axios.post(
        `${baseURL}/api/company/${apiEndPoint}`,
        formData,
        {
          "Content-Type": "application/json",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    },
    onSuccess: (data) => {
      console.log("success", data);

      setSnackbarInfo({
        isOpenClose: true,
        message: "Company Created Successfully",
        color: "success",
      });
      windowsReload();
    },
    onError: (error) => {
      setSnackbarInfo({
        isOpenClose: true,
        message: error.response.data.msg,
        color: "error",
      });
    },
  });

  const windowsReload = () => {
    window.location.href = "/company-details";
  };
  //   Submit Form
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, companyType, ownerFirstName, ownerLastName, phone, email } =
      companyInfo;
    if (
      name &&
      companyType &&
      ownerFirstName &&
      ownerLastName &&
      phone &&
      email
    ) {
      mutate(companyInfo);
    } else {
      setSnackbarInfo({
        isOpenClose: true,
        message: "Please Fill the Required Field",
        color: "error",
      });
    }
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  console.log("company details: ", companyInfo);
  return (
    <>
      <Navbar />
      <div className="container-navbar">
        <div className="row mx-auto" style={{ width: "100%" }}>
          <div className="col-10 m-auto py-3">
            <h2 className="text-center">Welcome To Tag8</h2>
            <h6 className="text-center">
              Please enter your details to {companyId ? "Update " : "Create "}
              Company
            </h6>
          </div>
          <div className="col-11 col-md-8 col-lg-6 mx-auto">
            <form onSubmit={handleSubmit} className="login-form">
              {/* Company Name and Type */}
              <div className="two-field-wrapper">
                <div className="mui-text-field">
                  <TextField
                    variant="outlined"
                    label="Company Name"
                    name="name"
                    type="text"
                    required
                    value={companyInfo.name}
                    onChange={handleChangeInput}
                  />
                </div>
                <div className="mui-text-field">
                  <TextField
                    select
                    variant="outlined"
                    label="Company Type"
                    name="companyType"
                    value={companyInfo.companyType}
                    onChange={handleChangeInput}
                    required
                  >
                    <MenuItem value="Cylinder">Cylinder</MenuItem>
                    <MenuItem value="Passport">Passport</MenuItem>
                  </TextField>
                </div>
              </div>

              {/* Owner First Name and Last Name */}
              <div className="two-field-wrapper">
                <div className="mui-text-field">
                  <TextField
                    variant="outlined"
                    label="Owner First Name"
                    name="ownerFirstName"
                    type="text"
                    required
                    value={companyInfo.ownerFirstName}
                    onChange={handleChangeInput}
                  />
                </div>
                <div className="mui-text-field">
                  <TextField
                    variant="outlined"
                    label="Owner Last Name"
                    name="ownerLastName"
                    type="text"
                    required
                    value={companyInfo.ownerLastName}
                    onChange={handleChangeInput}
                  />
                </div>
              </div>
              {/* Phone No & Email */}
              <div className="two-field-wrapper">
                <div className="mui-text-field">
                  <MuiPhoneInput
                    label="Phone"
                    defaultCountry="in"
                    value={companyInfo.phone}
                    regions={[
                      "america",
                      "caribbean",
                      "asia",
                      "middle-east",
                      "oceania",
                      "europe",
                      "africa",
                    ]}
                    name="phone"
                    variant="outlined"
                    onChange={handleChangePhone}
                    required
                  />
                </div>
                <div className="mui-text-field">
                  <TextField
                    label="Company Image"
                    type="file"
                    variant="outlined"
                    name="file"
                    onChange={handleChangeFile}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </div>
              {/* email & password */}
              {!companyId && (
                <div className="two-field-wrapper">
                  <div className="mui-text-field">
                    <TextField
                      label="Email"
                      required
                      variant="outlined"
                      name="email"
                      value={companyInfo.email}
                      onChange={handleChangeInput}
                    />
                  </div>
                  <div className="mui-text-field">
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                      <InputLabel htmlFor="outlined-adornment-password">
                        Password *
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={companyInfo.showPassword ? "text" : "password"}
                        name="password"
                        value={companyInfo.password}
                        onChange={handleChangeInput}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {companyInfo.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password *"
                      />
                    </FormControl>
                  </div>
                </div>
              )}
              {/* button  */}
              <div
                className="two-field-wrapper"
                style={{ justifyContent: "center" }}
              >
                <div className="mui-text-field">
                  <Button
                    type="submit"
                    variant="contained"
                    className="login-sumit-btn"
                    disabled={isPending}
                  >
                    {companyId ? "Update" : "Create"}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Snack Bar */}
      <Snackbar
        open={snackbarInfo.isOpenClose}
        onClose={autoCloseSnackbar}
        autoHideDuration={5000}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        style={{ top: "25%" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarInfo.color}
          sx={{ width: "100%" }}
        >
          {snackbarInfo.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Company;
