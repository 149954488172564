import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Gascylinder from "../../assets/gascylinder.png";
import { styled, Paper, Stack } from "@mui/material";
// import { PinDrop } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { switchUrls } from "../../api";
import { Link } from "react-router-dom";
const baseURL = switchUrls("cylinder");

const getAllMaintenace = async () => {
  const { data } = await axios.post(
    `${baseURL}/api/cylinderqr/getAllTransactionMaintenance`,
    {},
    {
      "Content-Type": "application/json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return data;
};

const MaintenanceLogs = () => {
  const { data, error, isLoading } = useQuery({
    queryKey: ["maintenance"],
    queryFn: getAllMaintenace,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
    retry: 2,
  });

  const [maintenances, setMaintenances] = useState([]);
  // USEEFFECT : Maintenance
  useEffect(() => {
    if (error) {
      console.log("error: ", error);
    }
    if (!isLoading && !error) {
      if (data) {
        // console.log("Maintenance all : ", data);
        setMaintenances(data?.data);
      }
    }
  }, [isLoading, error, data]);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <>
      <div className="">
        <Navbar />
        <div className="container-fluid py-3 d-flex flex-column gap-2">
          <div className="row cylinder-management-row-div">
            <div className="col-12 d-flex align-items-center justify-content-center ">
              <div className="col-4">
                <Link to={"/cylinder-management"}>
                  <img
                    src={Gascylinder}
                    alt="cylindermanagement"
                    style={{ width: "4rem" }}
                  />
                </Link>
              </div>
              <div className="col-4 d-flex flex-column align-items-center justify-content-center text-bold ">
                <span className="">CYLINDER</span>
                <span className=""> MANAGEMENT</span>
              </div>
              <div className="col-4"></div>
            </div>
          </div>

          <div className="row d-flex align-items-center justify-content-center">
            {/* <div className="col-12 col-md-6"> */}
            {!maintenances?.length > 0 ? (
              <p className="text-muted text-center">No Logs </p>
            ) : (
              <Stack spacing={0.1}>
                <div className="test-info d-flex justify-content-between aligin-items-center px-2 gap-1">
                  <h6 className="qrcode-testdate-h6">
                    <h6 className="tag8-color test-log-lebal">Qr Code</h6>
                    <h6 className="tag8-color test-log-lebal">
                      Hydrotesting Date
                    </h6>
                  </h6>
                  <h6 className="tag8-color test-log-lebal">
                    Tare Weight (Kgs)
                  </h6>
                </div>

                {maintenances?.map((test, index) => {
                  return (
                    <Item className="item-card" key={index}>
                      <div className="test-details d-flex flex-column gap-2">
                        <div className="test-info d-flex justify-content-between aligin-items-center">
                          <span className="qrcode-testdate-span">
                            <span className="test-text">{test.qrCode}</span>
                            <span className="test-text">
                              {test.hydrotestDate}
                            </span>
                          </span>
                          <span className="test-text">{test.tareWeight}</span>
                        </div>
                        <div className="test-message d-flex justify-content-between">
                          <span className="">{test.testComment}</span>
                          <span className="testedby-text">
                            __ {test.updatedBy}
                          </span>
                        </div>
                      </div>
                    </Item>
                  );
                })}
              </Stack>
            )}
            {/* </div> */}
          </div>
          <Footer />
        </div>
      </div>

      {isLoading && (
        <div className="loader-container">
          <div className="loader">
            <img
              src={"https://storage.googleapis.com/pettag/qr/assets/loader.gif"}
              alt="Loading..."
              loading="lazy"
              style={{ width: "100px", height: "100px" }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default MaintenanceLogs;
