import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Close, Check } from "@mui/icons-material";
import { Snackbar, Alert as MuiAlert, Tooltip } from "@mui/material";
import "./cylinderdetails.css";
import Navbar from "../../Navbar/Navbar";
import axios from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import { switchUrls } from "../../../api/";
const baseURL = switchUrls("cylinder");

const getAllCylinderRequest = async () => {
  const { data } = await axios.post(
    `${baseURL}/api/cylinderqr/getAllCylinderDetailsUpdateRequest`,
    {},
    {
      "Content-Type": "application/json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return data;
};
const UpdateCylinderRequests = () => {
  // QUERY : Get Cylinder Update Requests Data
  const { data, error, isLoading } = useQuery({
    queryKey: ["allCylinderRequest"],
    queryFn: getAllCylinderRequest,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
    retry: 2,
  });

  // MUTATION : CYLINDERS Merge Request
  const { mutate } = useMutation({
    mutationFn: async (reqData) => {
      return await axios.post(
        `${baseURL}/api/cylinderqr/mergeUpdateCylinderDetailsRequestByUser`,
        reqData,
        {
          "Content-Type": "multipart/form-data",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    },
    onSuccess: (data) => {
      console.log("data", data);
      setSnackbarInfo({
        isOpenClose: true,
        message: data.data.msg,
        color: "success",
      });
      windowsReload();
    },
    onError: (error) => {
      // console.log("error", error);
      setSnackbarInfo({
        isOpenClose: true,
        message: error.response.data.msg,
        color: "error",
      });
    },
  });

  const windowsReload = () => {
    window.location.reload();
  };

  const RenderTimeDate = ({ value }) => {
    // FOR STATUS LOCATION
    const toReadableDateTime = (st) => {
      if (typeof st === "string") {
        const [date, time] = st.split("T");
        return `${date} ${time.slice(0, 5)}`;
      } else {
        return null;
      }
    };

    return <p className="">{toReadableDateTime(value)}</p>;
  };

  // Highlight updated value
  const RenderHighlightChangedValue = ({ value }) => {
    return (
      <p className={`${value?.length > 1 ? "bg-green" : ""}`}>
        {value && value[0] ? value[0] : null}
      </p>
    );
  };

  const ActionBtnRenderer = ({ value }) => {
    const data = rowData.filter((item) => {
      return value === item._id;
    });
    const acceptedRequest = () => {
      mutate({ _id: value, request: "accepted" });
    };
    const declinedRequest = () => {
      mutate({ _id: value, request: "declined" });
    };
    return (
      <>
        {data[0]?.updateStatus === "pending" ? (
          <p>
            <Tooltip title="Decline Request">
              <span onClick={declinedRequest}>
                <Close color="error" className="cursor-pointer me-2" />
              </span>
            </Tooltip>
            <Tooltip title="Accept Request">
              <span onClick={acceptedRequest}>
                <Check color="success" className="cursor-pointer ms-2" />
              </span>
            </Tooltip>
          </p>
        ) : data[0]?.updateStatus === "accepted" ? (
          <span className="accepted-text">Accepted</span>
        ) : (
          <span className="declined-text">Declined</span>
        )}
      </>
    );
  };

  const columnDefs = [
    { headerName: "QR Code", field: "tagNumber" },
    { headerName: "Cylinder Code", field: "cylinderSerialNo" },
    {
      headerName: "Cylinder Type",
      field: "typeOfCylinder",
      cellRenderer: RenderHighlightChangedValue,
    },
    {
      headerName: "Cylinder Brand",
      field: "cylinderBrand",
      cellRenderer: RenderHighlightChangedValue,
    },
    {
      headerName: "Cylinder Material",
      field: "cylinderMaterial",
      cellRenderer: RenderHighlightChangedValue,
    },
    {
      headerName: "Cylinder Location",
      field: "cylinderLocation",
      cellRenderer: RenderHighlightChangedValue,
    },
    {
      headerName: "Cylinder Mfg Date",
      field: "cylinderMfgDate",
      cellRenderer: RenderHighlightChangedValue,
    },
    {
      headerName: "Peso Approval No",
      field: "pesoApprovalNo",
      cellRenderer: RenderHighlightChangedValue,
    },
    {
      headerName: "Peso Approval Date",
      field: "pesoApprovalDate",
      cellRenderer: RenderHighlightChangedValue,
    },
    {
      headerName: "Valve Brand",
      field: "valveBrand",
      cellRenderer: RenderHighlightChangedValue,
    },
    {
      headerName: "Valve Mfg Date",
      field: "valveMfgDate",
      cellRenderer: RenderHighlightChangedValue,
    },
    {
      headerName: "Working Pressure",
      field: "workingPressure",
      cellRenderer: RenderHighlightChangedValue,
    },
    {
      headerName: "Test Pressure",
      field: "testPressure",
      cellRenderer: RenderHighlightChangedValue,
    },
    {
      headerName: "Water Capacity",
      field: "waterCapacity",
      cellRenderer: RenderHighlightChangedValue,
    },
    {
      headerName: "Gas Capacity",
      field: "gasCapacity",
      maxWidth: 130,
      cellRenderer: RenderHighlightChangedValue,
    },
    {
      headerName: "Original T.W",
      field: "originalTareWeight",
      maxWidth: 130,
      cellRenderer: RenderHighlightChangedValue,
    },
    {
      headerName: "Specification",
      field: "specification",
      maxWidth: 130,
      cellRenderer: RenderHighlightChangedValue,
    },
    {
      headerName: "Test Frequency",
      field: "testFrequency",
      cellRenderer: RenderHighlightChangedValue,
    },
    { headerName: "Requested By", field: "createdBy" },
    {
      headerName: "Create Date",
      field: "creationDate",
      cellRenderer: RenderTimeDate,
    },
    {
      headerName: "Request",
      field: "_id",
      cellRenderer: ActionBtnRenderer,
    },
  ];
  const [rowData, setRowData] = useState([]);

  //  SNACKBAR
  const [snackbarInfo, setSnackbarInfo] = useState({
    isOpenClose: false,
    message: "",
    color: "",
  });
  const handleCloseSnackbar = () => {
    setSnackbarInfo({
      isOpenClose: false,
    });
  };
  const autoCloseSnackbar = () => {
    setSnackbarInfo({
      isOpenClose: false,
    });
  };

  // USEEFFECT : Cylinder update request Details
  useEffect(() => {
    if (error) {
      console.log("error: ", error);
    }
    if (!isLoading && !error) {
      if (data) {
        // console.log("cylinder all : ", data);
        setTimeout(() => {
          setRowData(data?.data);
        }, 500);
      }
    }
  }, [isLoading, error, data]);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  return (
    <>
      <div className="main-container">
        <Navbar />
        {/* ______________ Data Grid ______________ */}
        <div className="data-grid-main-div d-flex justify-content-center align-content-center">
          <div
            className="ag-theme-alpine data-grid-div"
            style={{ height: "33.5rem", width: "99%" }}
          >
            <AgGridReact
              columnDefs={columnDefs}
              rowData={rowData}
              pagination={true}
              paginationPageSize={10}
              defaultColDef={{
                // flex: 1,
                resizable: true,
              }}
              overlayLoadingTemplate={
                '<span className="ag-overlay-loading-center">Please wait while your rows are loading...</span>'
              }
              overlayNoRowsTemplate={
                '<span className="ag-overlay-loading-center">No data found to display.</span>'
              }
            />
          </div>
        </div>
      </div>

      {/* Loader */}
      {isLoading && (
        <div className="loader-container">
          <div className="loader">
            <img
              src={"https://storage.googleapis.com/pettag/qr/assets/loader.gif"}
              alt="Loading..."
              loading="lazy"
              style={{ width: "100px", height: "100px" }}
            />
          </div>
        </div>
      )}

      {/* Snack Bar */}
      <Snackbar
        open={snackbarInfo.isOpenClose}
        onClose={autoCloseSnackbar}
        autoHideDuration={5000}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        style={{ top: "25%" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarInfo.color}
          sx={{ width: "100%" }}
        >
          {snackbarInfo.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default UpdateCylinderRequests;
