import React from "react";
import "./footer.css";
const Footer = () => {
  return (
    <>
      <div className="d-flex flex-row align-items-center justify-content-center">
        {/* <Copyright /> */}
        <span className="">&#169; 2023 Powered by Tag8. All rights reserved.</span>
      </div>
    </>
  );
};

export default Footer;
