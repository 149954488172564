import React, { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Delete, Edit, Close, Check } from "@mui/icons-material";
import ChecklistIcon from "../../../assets/checklist.png";
// import FileUploadIcon from "../../../assets/fileupload.png";
import FileDownloadIcon from "../../../assets/filedownload.png";
import {
  Snackbar,
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Tooltip,
} from "@mui/material";
import "./cylinderdetails.css";
import Navbar from "../../Navbar/Navbar";
import axios from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import { switchUrls } from "../../../api/";
import DemoCsvFile from "../../../assets/cylinder.csv";
import { Link } from "react-router-dom";
const baseURL = switchUrls("cylinder");

const getAllCylinder = async () => {
  const { data } = await axios.post(
    `${baseURL}/api/cylinderqr/getAllCylinderDetails`,
    {},
    {
      "Content-Type": "application/json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return data;
};
const CylinderDetails = () => {
  // QUERY : Get Cylinder Data
  const { data, error, isLoading } = useQuery({
    queryKey: ["allCylinders"],
    queryFn: getAllCylinder,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
    retry: 2,
  });

  //
  // MUTATION : CYLINDERS UPLOAD
  // const { mutate, isPending: isPendingUploadCylinderdata } = useMutation({
  //   mutationFn: async (formData) => {
  //     return await axios.post(
  //       `${baseURL}/api/cylinderqr/bulkUploadCylinderDetailsWithoutTag`,
  //       formData,
  //       {
  //         "Content-Type": "multipart/form-data",
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       }
  //     );
  //   },
  //   onSuccess: (data) => {
  //     console.log("data", data);
  //     setRowData((preState) => [...data?.data?.data, ...preState]);
  //     setCsvFile(null);
  //     setSnackbarInfo({
  //       isOpenClose: true,
  //       message: data.data.msg,
  //       color: "success",
  //     });
  //   },
  //   onError: (error) => {
  //     // console.log("error", error);
  //     setSnackbarInfo({
  //       isOpenClose: true,
  //       message: error.response.data.msg,
  //       color: "error",
  //     });
  //   },
  // });
  // MUTATION : UPDATE CYLINDERS DATA
  const {
    mutate: bulkUpdateCylinders,
    isPending: isPendingBulkUpdateCylinderdata,
  } = useMutation({
    mutationFn: async (formData) => {
      return await axios.post(
        `${baseURL}/api/cylinderqr/bulkUpdateCylinderDetails`,
        formData,
        {
          "Content-Type": "multipart/form-data",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    },
    onSuccess: (data) => {
      console.log("data", data);
      setSnackbarInfo({
        isOpenClose: true,
        message: data.data.msg,
        color: "success",
      });
      setCsvFile2(null);
      window.location.reload();
    },
    onError: (error) => {
      console.log("error", error);
      setSnackbarInfo({
        isOpenClose: true,
        message: error.response.data.msg,
        color: "error",
      });
    },
  });

  const RenderTimeDate = ({ value }) => {
    const toReadableDateTime = (st) => {
      if (typeof st !== "string") return null;

      const [date, time] = st?.split("T");

      if (!time && date) return date.split("-").reverse().join("-");

      if (!date || !time) return null;

      return `${date.split("-").reverse().join("-")} ${time.slice(0, 5)}`;
    };

    return <p className="">{toReadableDateTime(value)}</p>;
  };
  const ActionBtnRenderer = ({ value }) => {
    const deleteDetails = () => {
      setDeletingValue(value);
      setDeleteAlert(true);
    };
    return (
      <>
        {value ? (
          <p>
            <Link to={`/scan/${value}`}>
              <Edit color="success" className="cursor-pointer me-2" />
            </Link>
            <span onClick={deleteDetails}>
              <Delete color="error" className="cursor-pointer ms-2" />
            </span>
          </p>
        ) : null}
      </>
    );
  };

  const RenderTagnumber = ({ value }) => {
    return (
      <>
        <p>{value} </p>
      </>
    );
  };
  const RenderCylinderNo = ({ value }) => {
    const copyText = (e) => {
      const textToCopy = e.target.innerText;

      // Create a temporary textarea element
      const textarea = document.createElement("textarea");
      textarea.value = textToCopy;
      document.body.appendChild(textarea);

      // Select the text in the textarea
      textarea.select();
      textarea.setSelectionRange(0, 99999); // For mobile devices

      // Copy the selected text
      document.execCommand("copy");

      // Remove the temporary textarea element
      document.body.removeChild(textarea);
    };

    return (
      <p className="cursor-pointer" onClick={copyText}>
        {value}
      </p>
    );
  };
  // eslint-disable-next-line
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "QR Code",
      field: "tagNumber",
      maxWidth: 100,
      cellRenderer: RenderTagnumber,
    },
    {
      headerName: "Cylinder Code",
      field: "cylinderSerialNo",
      maxWidth: 130,
      cellRenderer: RenderCylinderNo,
    },
    { headerName: "Cylinder Type", field: "typeOfCylinder", maxWidth: 120 },
    { headerName: "Cylinder Brand", field: "cylinderBrand", maxWidth: 130 },
    {
      headerName: "Cylinder Material",
      field: "cylinderMaterial",
      maxWidth: 150,
    },
    {
      headerName: "Cylinder Location",
      field: "cylinderLocation",
      maxWidth: 150,
    },
    {
      headerName: "Cylinder Mfg Date",
      field: "cylinderMfgDate",
      cellRenderer: RenderTimeDate,
      maxWidth: 150,
    },
    { headerName: "Peso Approval No", field: "pesoApprovalNo", minWidth: 220 },
    {
      headerName: "Peso Approval Date",
      field: "pesoApprovalDate",
      cellRenderer: RenderTimeDate,
      maxWidth: 160,
    },
    { headerName: "Valve Brand", field: "valveBrand", maxWidth: 130 },
    { headerName: "Valve Mfg Date", field: "valveMfgDate", maxWidth: 130 },
    { headerName: "Working Pressure", field: "workingPressure", maxWidth: 150 },
    { headerName: "Test Pressure", field: "testPressure", maxWidth: 130 },
    { headerName: "Water Capacity", field: "waterCapacity", maxWidth: 130 },
    { headerName: "Gas Capacity", field: "gasCapacity", maxWidth: 130 },
    { headerName: "Original T.W", field: "originalTareWeight", maxWidth: 130 },
    { headerName: "Specification", field: "specification", maxWidth: 130 },
    { headerName: "Test Frequency", field: "testFrequency", maxWidth: 130 },

    {
      headerName: "Modify Date",
      field: "updateDate",
      cellRenderer: RenderTimeDate,
      maxWidth: 130,
    },
    {
      headerName: "Create Date",
      field: "creationDate",
      cellRenderer: RenderTimeDate,
      maxWidth: 130,
    },
    {
      headerName: "Actions",
      field: "tagNumber",
      cellRenderer: ActionBtnRenderer,
      maxWidth: 130,
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      // flex: 1,
      // filter: true,
      // minWidth: 100,
      resizable: true,
    };
  }, []);

  const [rowData, setRowData] = useState([]);
  // const [csvFile, setCsvFile] = useState(null);
  // const [fileName, setFileName] = useState("");
  const [csvFile2, setCsvFile2] = useState(null);
  const [fileName2, setFileName2] = useState("");

  //  SNACKBAR
  const [snackbarInfo, setSnackbarInfo] = useState({
    isOpenClose: false,
    message: "",
    color: "",
  });
  const handleCloseSnackbar = () => {
    setSnackbarInfo({
      isOpenClose: false,
    });
  };
  const autoCloseSnackbar = () => {
    setSnackbarInfo({
      isOpenClose: false,
    });
  };
  const [totalTags, setTotalTags] = useState(0);
  const [activeTags, setActiveTags] = useState(0);
  const [cylinderAssets, setCylinderAssets] = useState(0);
  // USEEFFECT : Cylinder Details
  useEffect(() => {
    if (error) {
      console.log("error: ", error);
    }
    if (!isLoading && !error) {
      if (data) {
        // console.log("cylinder all : ", data);
        setTimeout(() => {
          setRowData(data?.data);
          setCylinderAssets(data?.data?.length);
          setTotalTags(data?.totalTags);
          setActiveTags(data?.activeCount);
        }, 500);
      }
    }
  }, [isLoading, error, data]);

  // const handleChangeCSVfile = (e) => {
  //   const file = e.target.files[0];

  //   if (file && file.name.toLowerCase().endsWith(".csv")) {
  //     const reader = new FileReader();

  //     if (file.size > 0) {
  //       reader.onload = (evt) => {
  //         setCsvFile(file);
  //         setFileName(file.name);
  //       };
  //       reader.readAsBinaryString(file);
  //     } else {
  //       alert(`${file.name} file size is ${file.size} which is empty`);
  //     }
  //   } else {
  //     alert("Please select a CSV file.");
  //   }
  // };
  const handleChangeCSVfile2 = (e) => {
    const file = e.target.files[0];
    if (file && file.name.toLowerCase().endsWith(".csv")) {
      const reader = new FileReader();
      if (file.size > 0) {
        reader.onload = (evt) => {
          setCsvFile2(file);
          setFileName2(file.name);
        };
        reader.readAsBinaryString(file);
      } else {
        alert(`${file.name} file size is ${file.size} which is empty`);
      }
    } else {
      alert("Please select a CSV file.");
    }
  };

  // const onClickUploadFile = (v) => {
  //   let formData = new FormData();
  //   formData.append("csv", csvFile);

  //   mutate(formData);
  // };
  const onClickUpdateFile = (v) => {
    let formData = new FormData();
    formData.append("csv", csvFile2);

    bulkUpdateCylinders(formData);
  };

  const [setsearchQuery, setSearchQuery] = useState("");
  const search = () => {
    const filtered = data?.data?.filter(
      (item) =>
        item.cylinderSerialNo.includes(setsearchQuery) ||
        item.tagNumber.includes(setsearchQuery)
    );
    setRowData(filtered);
  };
  useEffect(() => {
    const timeToSearch = setTimeout(async () => {
      if (setsearchQuery.length > 0) {
        search();
      } else {
        setRowData(data?.data);
      }
    }, 300);
    return () => {
      clearTimeout(timeToSearch);
    };
    // eslint-disable-next-line
  }, [setsearchQuery]);

  const onChnageSearch = (e) => {
    setSearchQuery(e.target.value);
  };
  // const colomnUpdate = () => {
  //   setColumnDefs(columnDefs.filter((item) => item.field !== "tagNumber"));
  // };

  // Parent Checkbox state
  const [parentCheckboxState, setParentCheckboxState] = useState(true);
  const handleParentCheckboxChange = (event) => {
    setParentCheckboxState(event.target.checked);
    if (!event.target.checked) {
      setCheckboxStates(
        Object.fromEntries(columnDefs.map((column) => [column.field, false]))
      );
    } else {
      setCheckboxStates(
        Object.fromEntries(columnDefs.map((column) => [column.field, true]))
      );
    }
  };

  // useEffect(() => {}, [parentCheckboxState, columnDefs]);

  // Child Checkbox state
  const [checkboxStates, setCheckboxStates] = useState(
    Object.fromEntries(columnDefs.map((column) => [column.field, true]))
  );

  const handleCheckboxChange = (field) => (event) => {
    setCheckboxStates((prevStates) => ({
      ...prevStates,
      [field]: event.target.checked,
    }));

    if (!event.target.checked) {
      setParentCheckboxState(false);
    } else {
      let allChecked = true;
      for (const key in checkboxStates) {
        if (!checkboxStates[key]) {
          allChecked = false;
          break;
        }
      }
      setParentCheckboxState(allChecked);
    }
  };

  const [isViewlist, setIsViewlist] = useState(false);
  const showHideViewlist = () => {
    setIsViewlist(!isViewlist);
  };

  const [deletingValue, setDeletingValue] = useState("");
  const [deleteAlert, setDeleteAlert] = useState(false);
  const skipAlert = () => {
    setDeleteAlert(false);
  };

  // DELETE MASTER DATA
  const onClickDeleteCylinder = () => {
    mutateDeleteCylinder(deletingValue);
  };

  const { mutate: mutateDeleteCylinder } = useMutation({
    mutationFn: async (tagnumber) => {
      return await axios.post(
        `${baseURL}/api/cylinderqr/deleteOneCylinderDetails`,
        { tagNumber: tagnumber },
        {
          "Content-Type": "application/json",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    },
    onSuccess: (data) => {
      // console.log("success", data);
      setSnackbarInfo({
        isOpenClose: true,
        message: "Cylinder data Deleted Successfully",
        color: "success",
      });
      window.location.reload();
    },
    onError: (error) => {
      // console.log("error", error);
      setSnackbarInfo({
        isOpenClose: true,
        message: error.response.data.msg,
        color: "error",
      });
    },
  });
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  return (
    <>
      <div className="main-container">
        <Navbar />
        {/* ______________ Data Grid ______________ */}
        <div className="data-grid-main-div d-flex flex-column justify-content-center align-items-center">
          <div
            className="d-flex flex-row justify-content-between align-items-center px-2 py-1 gap-3"
            style={{ width: "100%" }}
          >
            <div className="left">
              <span className="text-bold">
                <span className="text-secondary">Total Qr Tags: </span>
                {totalTags}
              </span>
              <span className="text-bold">
                {" "}
                <span className="text-secondary">Total Cylinders: </span>
                {cylinderAssets}
              </span>
              <span className="text-bold">
                {" "}
                <span className="text-secondary">Total Tagged Cylinders: </span>
                {activeTags}
              </span>
            </div>
            <div className="right d-flex flex-row justify-content-center align-items-center">
              <div className="d-flex align-items-center justify-content-center gap-2">
                {/* BULK ADD CYLINDER DATA */}
                {/* <div className="d-flex align-items-center justify-content-center gap-2">
                  <input
                    id="upload"
                    type="file"
                    name="UPLOADCYLINDER"
                    onChange={handleChangeCSVfile}
                    className="input-csv-tag-none "
                    accept=".csv"
                  />
                  {!csvFile ? (
                    <label htmlFor="upload" className="csv-upload-btn">
                      Upload Cylinder Details
                    </label>
                  ) : (
                    <label className="csv-upload-btn d-flex align-items-center justify-content-center gap-2">
                      {fileName}
                      <span
                        className="delete-btn"
                        onClick={() => setCsvFile(null)}
                      >
                        <Close color="error" className="cursor-pointer" />
                      </span>
                      <span className="check-btn" onClick={onClickUploadFile}>
                        <Check color="success" className="cursor-pointer" />
                      </span>
                    </label>
                  )}
                </div> */}
                {/* BULK UPDATE CYLINDER DATA */}
                <div className="d-flex align-items-center justify-content-center gap-2">
                  <input
                    id="upload2"
                    type="file"
                    name="UPDATECYLINDER"
                    onChange={handleChangeCSVfile2}
                    className="input-csv-tag-none "
                    accept=".csv"
                  />
                  {!csvFile2 ? (
                    <label htmlFor="upload2" className="csv-upload-btn">
                      {/* Update Cylinder Details note: both create & update operation */}
                      Upload Cylinder Details
                    </label>
                  ) : (
                    <label className="csv-upload-btn d-flex align-items-center justify-content-center gap-2">
                      {fileName2}
                      <span
                        className="delete-btn"
                        onClick={() => setCsvFile2(null)}
                      >
                        <Close color="error" className="cursor-pointer" />
                      </span>
                      <span className="check-btn" onClick={onClickUpdateFile}>
                        <Check color="success" className="cursor-pointer" />
                      </span>
                    </label>
                  )}
                </div>
              </div>
              <input
                className="search-input"
                type="text"
                id="filter-text-box"
                placeholder="Search By Tagnumber, Cylinder No"
                onInput={onChnageSearch}
              />
              <div className={`view-list-div`}>
                <Tooltip title="Column List">
                  <img
                    src={ChecklistIcon}
                    alt="check_list_png"
                    style={{ width: "2rem", height: "2rem" }}
                    onClick={showHideViewlist}
                    className="cursor-pointer"
                  />
                </Tooltip>

                <div
                  className={`${!isViewlist ? "check-box-hide" : ""} check-box`}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="success"
                          checked={parentCheckboxState}
                          onChange={handleParentCheckboxChange}
                        />
                      }
                      label="Select All"
                    />
                    {columnDefs.map((column, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              color="success"
                              checked={checkboxStates[column.field]}
                              onChange={handleCheckboxChange(column.field)}
                            />
                          }
                          label={column.headerName}
                        />
                      );
                    })}
                  </FormGroup>
                </div>

                <Tooltip title="Download Cylinder CSV Template">
                  <a
                    href={DemoCsvFile}
                    // className="csv-upload-btn"
                    download="cylindercsv"
                  >
                    <img
                      src={FileDownloadIcon}
                      alt="check_list_png"
                      style={{ width: "2rem", height: "2rem" }}
                      className="cursor-pointer"
                    />
                  </a>
                </Tooltip>
              </div>
            </div>
          </div>
          <div
            className="ag-theme-alpine data-grid-div"
            style={{ height: "32.4rem", width: "99%" }}
          >
            <AgGridReact
              columnDefs={columnDefs}
              rowData={rowData}
              pagination={true}
              paginationPageSize={10}
              defaultColDef={defaultColDef}
              overlayLoadingTemplate={
                '<span className="ag-overlay-loading-center">Please wait while your rows are loading...</span>'
              }
              overlayNoRowsTemplate={
                '<span className="ag-overlay-loading-center">No data found to display.</span>'
              }
            />
          </div>
        </div>
      </div>

      {/* Loader */}
      {(isLoading || isPendingBulkUpdateCylinderdata) && (
        <div className="loader-container">
          <div className="loader">
            <img
              src={"https://storage.googleapis.com/pettag/qr/assets/loader.gif"}
              alt="Loading..."
              loading="lazy"
              style={{ width: "100px", height: "100px" }}
            />
          </div>
        </div>
      )}
      {/* Snack Bar */}
      <Snackbar
        open={snackbarInfo.isOpenClose}
        onClose={autoCloseSnackbar}
        autoHideDuration={5000}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        style={{ top: "25%" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarInfo.color}
          sx={{ width: "100%" }}
        >
          {snackbarInfo.message}
        </Alert>
      </Snackbar>

      {/* Delete Alert */}
      {deleteAlert && (
        <div className="loader-container ">
          <div
            className="loader d-flex flex-column align-items-center justify-content-center border rounded-3 p-3 "
            style={{ background: "#f5f5f5" }}
          >
            <h5 className="">Are you sure ? you want to delete.</h5>
            <div className="d-flex flex-row align-items-center justify-content-center mt-2 gap-4">
              <span
                className="cursor-pointer p-2 border rounded-3 text-center"
                onClick={skipAlert}
                style={{ width: "5rem", background: "#f3e0e0" }}
              >
                Cancel
              </span>
              <span
                className="cursor-pointer p-2 border rounded-3 text-center"
                onClick={onClickDeleteCylinder}
                style={{ width: "5rem", background: "#d8ffd8" }}
              >
                Ok
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CylinderDetails;
