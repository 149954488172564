import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./statuslocation.css";
import Navbar from "../../Navbar/Navbar";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { Parser } from "@json2csv/plainjs";

import { switchUrls } from "../../../api/";
const baseURL = switchUrls("cylinder");

const getAllStatusLocation = async () => {
  const { data } = await axios.post(
    `${baseURL}/api/cylinderqr/getAllTransactionHistoryLocation`,
    {},
    {
      "Content-Type": "application/json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return data;
};

const StatusLocation = () => {
  // QUERY : Maintenace Data
  const { data, error, isLoading } = useQuery({
    queryKey: ["statusLocation"],
    queryFn: getAllStatusLocation,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
    retry: 2,
  });

  const RenderTimeDate = ({ value }) => {
    const toReadableDateTime = (st) => {
      if (typeof st !== "string") return null;

      const [date, time] = st?.split("T");

      if (!time && date) return date.split("-").reverse().join("-");

      if (!date || !time) return null;

      return `${date.split("-").reverse().join("-")} ${time.slice(0, 5)}`;
    };

    return <p className="">{toReadableDateTime(value)}</p>;
  };
  const RenderTranasactionId = ({ value }) => {
    const copyText = (e) => {
      const textToCopy = e.target.innerText;

      // Create a temporary textarea element
      const textarea = document.createElement("textarea");
      textarea.value = textToCopy;
      document.body.appendChild(textarea);

      // Select the text in the textarea
      textarea.select();
      textarea.setSelectionRange(0, 99999); // For mobile devices

      // Copy the selected text
      document.execCommand("copy");

      // Remove the temporary textarea element
      document.body.removeChild(textarea);
    };

    return (
      <p className="cursor-pointer" onClick={copyText}>
        {value}
      </p>
    );
  };

  const columnDefs = [
    { headerName: "QR Code", field: "qrCode" },
    { headerName: "Cylinder Code", field: "cylinderCode" },
    { headerName: "Location", field: "toLocation" },
    { headerName: "Status", field: "cylinderStatus" },
    {
      headerName: "Transaction Id",
      field: "transactionId",
      cellRenderer: RenderTranasactionId,
    },
    {
      headerName: "Create Date",
      field: "createDate",
      cellRenderer: RenderTimeDate,
    },
    { headerName: "Modified By", field: "updatedBy" },
  ];

  const [rowData, setRowData] = useState([]);

  // USEEFFECT : Status Location
  useEffect(() => {
    if (error) {
      console.log("error: ", error);
    }
    if (!isLoading && !error) {
      if (data) {
        // console.log("Maintenance all : ", data);
        setTimeout(() => {
          setRowData(data?.data);
        }, 500);
      }
    }
  }, [isLoading, error, data]);

  const onChnageSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const [setsearchQuery, setSearchQuery] = useState("");
  const search = () => {
    const filtered = data?.data?.filter(
      (item) =>
        item?.cylinderCode?.includes(setsearchQuery) ||
        item?.qrCode?.includes(setsearchQuery) ||
        item?.transactionId?.includes(setsearchQuery)
    );
    setRowData(filtered);
  };
  useEffect(() => {
    const timeToSearch = setTimeout(async () => {
      if (setsearchQuery.length > 0) {
        search();
      } else {
        setRowData(data?.data);
      }
    }, 300);
    return () => {
      clearTimeout(timeToSearch);
    };
    // eslint-disable-next-line
  }, [setsearchQuery]);

  const convertToCSV = () => {
    const opts = {
      fields: [
        {
          label: "Sr No",
          value: "srNo",
        },
        {
          label: "Qr Code",
          value: "qrCode",
        },
        {
          label: "Cylinder Code",
          value: "cylinderCode",
        },
        {
          label: "Location",
          value: "toLocation",
        },
        {
          label: "Status",
          value: "cylinderStatus",
        },
        {
          label: "Transaction Id",
          value: "transactionId",
        },
        {
          label: "Transaction By",
          value: "updatedBy",
        },
      ],
    };
    const parser = new Parser(opts);
    let i = 0;
    rowData?.map((item) => (item.srNo = ++i));
    const csv = parser.parse(rowData);
    return csv;
  };

  const downloadCSV = () => {
    const csv = convertToCSV();
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "transaction.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  return (
    <>
      <Navbar />
      {/* ______________ Data Grid ______________ */}
      <div className="data-grid-main-div d-flex flex-column justify-content-center align-content-center">
        <div
          className="d-flex flex-row justify-content-between align-items-center px-2 py-1 gap-3"
          style={{ width: "100%" }}
        >
          <div className="left"></div>
          <div className="right d-flex flex-row justify-content-center align-items-center">
            <div className="d-flex align-items-center justify-content-center gap-2">
              <input
                className="search-input"
                type="text"
                id="filter-text-box"
                placeholder="Search By Tagnumber, Cylinder No"
                onInput={onChnageSearch}
              />
              <button className="csv-upload-btn" onClick={downloadCSV}>
                Download Transaction
              </button>
            </div>
          </div>
        </div>
        <div
          className="ag-theme-alpine data-grid-div"
          style={{ height: "33.5rem", width: "99%" }}
        >
          <AgGridReact
            columnDefs={columnDefs}
            rowData={rowData}
            pagination={true}
            paginationPageSize={10}
            defaultColDef={{
              flex: 1,
              resizable: true,
            }}
            overlayLoadingTemplate={
              '<span className="ag-overlay-loading-center">Please wait while your rows are loading...</span>'
            }
            overlayNoRowsTemplate={
              '<span className="ag-overlay-loading-center">No data found to display.</span>'
            }
          />
        </div>
      </div>

      {/* Loader */}
      {isLoading && (
        <div className="loader-container">
          <div className="loader">
            <img
              src={"https://storage.googleapis.com/pettag/qr/assets/loader.gif"}
              alt="Loading..."
              loading="lazy"
              style={{ width: "100px", height: "100px" }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default StatusLocation;
