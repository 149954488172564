import React, { useEffect, useState } from "react";
import {
  Visibility,
  VisibilityOff,
  Close as CloseIcon,
  Person,
} from "@mui/icons-material";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Snackbar,
  Alert as MuiAlert,
  Modal,
  DialogContent,
} from "@mui/material";
import "./login.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { switchUrls } from "../../api";
import AsteriskIcon from "../../assets/asterisk.png";
import { useMutation } from "@tanstack/react-query";
import Footer from "../Footer/Footer";
const baseURL = switchUrls("cylinder");

// forgotPasswordOtp
const Login = () => {
  // MUTATION
  const { mutate: loginMutate, isPending: isPendingLogin } = useMutation({
    mutationKey: ["user"],
    mutationFn: async (obj) => {
      return await axios.post(`${baseURL}/api/cylinderqr/login`, obj);
    },
    onSuccess: (data) => {
      // console.log("success", data);
      const { token, companyType, role, companyId, email, user } = data.data;
      setLoginSate((preState) => ({ ...preState, role: role }));
      setSnackbarInfo({
        isOpenClose: true,
        message: "Login Successfully",
        color: "success",
      });

      console.log("user", user);
      const jsonString = JSON.stringify(user);
      localStorage.setItem("user", jsonString);
      localStorage.setItem("token", token);
      localStorage.setItem("email", email);
      localStorage.setItem("role", role);
      localStorage.setItem("companyId", companyId);
      localStorage.setItem("companyType", companyType);
      if (companyType === "Cylinder") {
        if (role === "user") {
          !tag_number
            ? (window.location.href = `/cylinder-management`)
            : (window.location.href = `/scan/${tag_number}`);
        } else {
          !tag_number
            ? (window.location.href = `/dashboard/cylinder-details`)
            : (window.location.href = `/scan/${tag_number}`);
        }
      } else if (companyType === "Passport") {
        if (role !== "user") {
          window.location.href = `/dashboard/passport-details`;
        }
      } else if (companyType === "Tag8") {
        if (role === "tag8admin") {
          window.location.href = `/company-details`;
        }
      }
    },
    onError: (error) => {
      // console.log("error", error);
      setSnackbarInfo({
        isOpenClose: true,
        message: error.response.data.msg,
        color: "error",
      });
    },
  });
  const { mutate: otpSend } = useMutation({
    mutationFn: async (email) => {
      return await axios.post(`${baseURL}/api/cylinderqr/forgotPasswordOtp`, {
        email,
      });
    },
    onSuccess: (data) => {
      // console.log("success", data);

      setSnackbarInfo({
        isOpenClose: true,
        message: "OTP Send",
        color: "success",
      });
    },
    onError: (error) => {
      // console.log("error", error);
      setSnackbarInfo({
        isOpenClose: true,
        message: error.response.data.msg,
        color: "error",
      });
    },
  });
  const { mutate: verifyOTP, isPending: isPendingVerifyOTP } = useMutation({
    mutationFn: async (otpObj) => {
      return await axios.post(`${baseURL}/api/cylinderqr/verifyOTP`, otpObj);
    },
    onSuccess: (data) => {
      // console.log("success", data);

      setSnackbarInfo({
        isOpenClose: true,
        message: "OTP Verified",
        color: "success",
      });

      setLoginSate({ ...loginState, isOtpVerified: true });
    },
    onError: (error) => {
      // console.log("error", error);
      setSnackbarInfo({
        isOpenClose: true,
        message: error.response.data.msg,
        color: "error",
      });
    },
  });
  const { mutate: updatePassword, isPending: isPendingUpdatePassword } =
    useMutation({
      mutationFn: async (obj) => {
        return await axios.post(
          `${baseURL}/api/cylinderqr/updatePassword`,
          obj
        );
      },
      onSuccess: (data) => {
        // console.log("success", data);

        setSnackbarInfo({
          isOpenClose: true,
          message: "Password Updated Successfully",
          color: "success",
        });

        setLoginSate({ ...loginState, isOtpVerified: true });
        windowsReload();
      },
      onError: (error) => {
        // console.log("error", error);
        setSnackbarInfo({
          isOpenClose: true,
          message: error.response.data.msg,
          color: "error",
        });
      },
    });

  let { tag_number } = useParams();
  const [loginState, setLoginSate] = useState({
    email: localStorage.getItem("login_email"),
    password: "",
    role: "",
    showPassword: false,
    otp: "",
    isOtpVerified: false,
    newPassword: "",
  });
  const handleClickShowPassword = () => {
    setLoginSate((preState) => ({
      ...preState,
      showPassword: !loginState.showPassword,
    }));
  };

  //   email password change handler
  const handleChangeInput = (e) => {
    setLoginSate({ ...loginState, [e.target.name]: e.target.value });
  };

  const [flag, setFlag] = useState({
    openLoginModal: false,
  });

  const initialTime = 59;
  const [timeLeft, setTimeLeft] = useState(initialTime);
  const [isTimerRunning, setIsTimerRunning] = useState(false);

  const startTimer = () => {
    setIsTimerRunning(true);
  };

  const resetTimer = () => {
    setTimeLeft(initialTime);
    setIsTimerRunning(false);
  };

  useEffect(() => {
    let timer;

    if (isTimerRunning) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);

        if (timeLeft <= 0) {
          clearInterval(timer);
          resetTimer();
        }
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [isTimerRunning, timeLeft]);

  const handleCloseModal = () => {
    setFlag((preState) => ({
      ...preState,
      openLoginModal: false,
    }));
  };

  const windowsReload = () => {
    window.location.reload();
  };

  //  SNACKBAR
  const [snackbarInfo, setSnackbarInfo] = useState({
    isOpenClose: false,
    message: "",
    color: "",
  });
  const handleCloseSnackbar = () => {
    setSnackbarInfo({
      isOpenClose: false,
    });
  };
  const autoCloseSnackbar = () => {
    setSnackbarInfo({
      isOpenClose: false,
    });
  };

  //   Submit Login Form
  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    localStorage.setItem("login_email", loginState.email);
    if (!loginState.email || !loginState.password) {
      setSnackbarInfo({
        isOpenClose: true,
        message: "Please Fill The Required Field",
        color: "error",
      });
    } else {
      loginMutate({
        email: loginState.email,
        password: loginState.password,
      });
    }
  };

  const onClickVerifyOtp = () => {
    const { email, otp } = loginState;
    if (!otp) {
      setSnackbarInfo({
        isOpenClose: true,
        message: "Enter Your OTP",
        color: "error",
      });
    } else {
      const otpObj = {
        email: email,
        otp: otp,
      };
      verifyOTP(otpObj);
    }
  };
  const onClickUpdatePassword = () => {
    const { email, newPassword } = loginState;
    if (!newPassword) {
      setSnackbarInfo({
        isOpenClose: true,
        message: "Enter Your New Password",
        color: "error",
      });
    } else {
      const obj = {
        email: email,
        newPassword: newPassword,
      };
      updatePassword(obj);
    }
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #ffc107",
    borderRadius: "5px",
    boxShadow: 24,
    padding: 0,
  };

  useEffect(() => {
    const loginField = document.getElementsByClassName(
      "MuiOutlinedInput-notchedOutline"
    );
    Array.from(loginField).forEach((element) => {
      element.classList.add("login-field");
    });
    // console.log("loginF: ", loginField);
  }, []);

  // console.log("login state: ", loginState);
  return (
    <>
      <div className="container h-90 d-flex flex-column align-items-center justify-content-center">
        <div className="row w-100p d-flex flex-column align-items-center justify-content-center gap-6">
          <div className="col-12 d-flex align-items-center justify-content-center">
            <img
              className="logo-img"
              alt="logo"
              src="https://storage.googleapis.com/pettag/qr/assets/Tag8-Logo-slst--black.png"
            />
          </div>
          <div className="col-10 m-auto d-flex flex-column justify-content-center align-items-center">
            <form onSubmit={handleLoginSubmit} className="login-form">
              {/* email and password */}
              <div
                className="two-field-wrapper"
                style={{ flexDirection: "column" }}
              >
                <div className="mui-text-field div-border d-flex flex-row align-items-center justify-content-between">
                  <div className="icon-div">
                    <Person fontSize="large" />
                  </div>
                  <div className="icon-text">
                    <TextField
                      // label="Email"
                      placeholder="Email"
                      required
                      variant="outlined"
                      name="email"
                      value={loginState.email}
                      onChange={handleChangeInput}
                      className="email-textField"
                    />
                  </div>
                </div>
                <div className="password-div">
                  <div className="mui-text-field div-border d-flex flex-row">
                    <div className="icon-div">
                      <img
                        src={AsteriskIcon}
                        alt="asteriskIcon"
                        style={{ width: "1.7rem" }}
                      />
                    </div>
                    <div className="icon-text">
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        {/* <InputLabel htmlFor="outlined-adornment-password">
                      Password *
                    </InputLabel> */}
                        <OutlinedInput
                          // id="outlined-adornment-password"
                          type={loginState.showPassword ? "text" : "password"}
                          name="password"
                          placeholder="Password"
                          value={loginState.password}
                          onChange={handleChangeInput}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end"
                              >
                                {loginState.showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          // label="Password *"
                        />
                      </FormControl>
                    </div>
                  </div>
                  {/* button  */}
                  <div className="mui-text-field forgot-btn-div">
                    <button
                      type="button"
                      disabled={!loginState.email}
                      className="forgot-password-btn"
                      onClick={() => {
                        otpSend(loginState.email);
                        setFlag({
                          ...flag,
                          openLoginModal: true,
                        });
                      }}
                    >
                      Forgot Password
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="two-field-wrapper"
                style={{ justifyContent: "center" }}
              >
                <div className="mui-text-field">
                  <Button
                    type="submit"
                    variant="contained"
                    className="login-sumit-btn"
                    disabled={isPendingLogin}
                  >
                    Log In
                  </Button>
                </div>
              </div>
            </form>
          </div>
          <div className="">
            <Footer />
          </div>
        </div>
      </div>

      {/* Snack Bar */}
      <Snackbar
        open={snackbarInfo.isOpenClose}
        onClose={autoCloseSnackbar}
        autoHideDuration={5000}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        style={{ top: "25%" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarInfo.color}
          sx={{ width: "100%" }}
        >
          {snackbarInfo.message}
        </Alert>
      </Snackbar>

      {/* _____________________* Forgot Password MODAL *_____________________ */}
      <Modal
        open={flag.openLoginModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogContent sx={style}>
          {!loginState.isOtpVerified ? (
            <div className="verify-otp-div d-flex flex-column align-items-center p-3 gap-2">
              <img
                src={
                  "https://storage.googleapis.com/pettag/qr/assets/email.png"
                }
                alt="Email_Icon"
                style={{
                  width: "50px",
                  height: "50px",
                }}
              />
              <h5 className="m-3">Verify your Email Address</h5>
              <div className="d-flex flex-column align-items-center">
                <TextField
                  required
                  variant="outlined"
                  name="otp"
                  value={loginState.otp}
                  onChange={handleChangeInput}
                  placeholder="Enter OTP"
                />
                <p className="" style={{ fontSize: "13px" }}>
                  Email verification code has been sent on your email
                </p>

                <Button
                  type="button"
                  variant="contained"
                  className="forgot-pass-btn"
                  onClick={onClickVerifyOtp}
                  disabled={isPendingVerifyOTP}
                >
                  Verify OTP
                </Button>
              </div>
              <div className="d-flex flex-column align-items-center gap-2">
                <div className="d-flex flex-column align-items-center gap-1">
                  {isTimerRunning ? (
                    <p className="text-muted" style={{ fontSize: "14px" }}>
                      Resend OTP in <span className="">{timeLeft}</span> secs
                    </p>
                  ) : (
                    <button
                      type="button"
                      className="resend-otp-btn"
                      onClick={() => {
                        otpSend(loginState.email);
                        startTimer();
                      }}
                    >
                      Resend OTP
                    </button>
                  )}
                </div>
                <p style={{ fontSize: "13px" }}>
                  If email is not received, please check the spam folder or
                  click on the resend button.
                </p>
              </div>
            </div>
          ) : (
            <div className="update-password-div d-flex flex-column align-items-center p-3 gap-2">
              <img
                src={
                  "https://storage.googleapis.com/pettag/qr/assets/email.png"
                }
                alt="Email_Icon"
                style={{
                  width: "50px",
                  height: "50px",
                }}
              />
              <h5 className="m-3">Update Password</h5>
              <div className=" d-flex flex-column align-items-center gap-3 py-2">
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <InputLabel htmlFor="outlined-adornment-password">
                    New Password *
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={loginState.showPassword ? "text" : "password"}
                    name="newPassword"
                    value={loginState.newPassword}
                    onChange={handleChangeInput}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {loginState.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="New Password *"
                  />
                </FormControl>
                <Button
                  type="button"
                  variant="contained"
                  className="login-sumit-btn"
                  onClick={onClickUpdatePassword}
                  disabled={isPendingUpdatePassword}
                >
                  Change Password
                </Button>
              </div>
            </div>
          )}

          <div className="close-btn-div" onClick={handleCloseModal}>
            <CloseIcon className="close-btn" />
          </div>
        </DialogContent>
      </Modal>
    </>
  );
};

export default Login;
