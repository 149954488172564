import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import {
  Company,
  CompanyDetails,
  CylinderDetails,
  CylinderManagement,
  Datamaster,
  Home,
  MaintenanceHistory,
  MaintenanceLogs,
  MaintenanceScan,
  Scan,
  StatusLocation,
  TransactionLogs,
  TransactionScan,
  UpdateCylinderRequests,
  UserManagement,
} from "./components";
import PassportDetails from "./components/Admin/PassportDetails/PassportDetails";
// import ProtectedRoute from "./ProtectedRoute";
const App = () => {
  const [authStatus, setAuthStatus] = useState({
    isAuth: localStorage.getItem("token") ? true : false,
    isUser:
      localStorage.getItem("token") && localStorage.getItem("role") === "user"
        ? true
        : false,
    isAdmin:
      localStorage.getItem("token") &&
      localStorage.getItem("role") !== "user" &&
      localStorage.getItem("companyType") === "Cylinder"
        ? true
        : false,
    isPassportAdmin:
      localStorage.getItem("token") &&
      localStorage.getItem("role") === "admin" &&
      localStorage.getItem("companyType") === "Passport"
        ? true
        : false,
    isTag8Admin:
      localStorage.getItem("token") &&
      localStorage.getItem("role") === "tag8admin" &&
      localStorage.getItem("companyType") === "Tag8"
        ? true
        : false,
  });

  useEffect(() => {
    setAuthStatus({
      isAuth: localStorage.getItem("token") ? true : false,
      isUser:
        localStorage.getItem("token") && localStorage.getItem("role") === "user"
          ? true
          : false,
      isAdmin:
        localStorage.getItem("token") &&
        localStorage.getItem("role") !== "user" &&
        localStorage.getItem("companyType") === "Cylinder"
          ? true
          : false,
      isPassportAdmin:
        localStorage.getItem("token") &&
        localStorage.getItem("role") === "admin" &&
        localStorage.getItem("companyType") === "Passport"
          ? true
          : false,
      isTag8Admin:
        localStorage.getItem("token") &&
        localStorage.getItem("role") === "tag8admin" &&
        localStorage.getItem("companyType") === "Tag8"
          ? true
          : false,
    });
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              authStatus.isUser ? (
                <Navigate to={"/cylinder-management"} />
              ) : authStatus.isAdmin ? (
                <Navigate to={"/dashboard/cylinder-details"} />
              ) : authStatus.isPassportAdmin ? (
                <Navigate to={"/dashboard/passport-details"} />
              ) : authStatus.isTag8Admin ? (
                <Navigate to={"/company-details"} />
              ) : (
                <Home />
              )
            }
          />
          <Route path="/scan/:tag_number" element={<Scan />} />

          {/* ________________________ Protected Route For User ________________________ */}
          <Route
            path="/cylinder-management"
            element={
              authStatus.isUser || authStatus.isAdmin ? (
                <CylinderManagement />
              ) : (
                <Navigate to={"/"} />
              )
            }
          />
          <Route
            path="/cylinder-maintenance"
            element={
              authStatus.isUser || authStatus.isAdmin ? (
                <MaintenanceScan />
              ) : (
                <Navigate to={"/"} />
              )
            }
          />
          <Route
            path="/maintenance-logs"
            element={
              authStatus.isUser || authStatus.isAdmin ? (
                <MaintenanceLogs />
              ) : (
                <Navigate to={"/"} />
              )
            }
          />
          <Route
            path="/cylinder-transaction"
            element={
              authStatus.isUser || authStatus.isAdmin ? (
                <TransactionScan />
              ) : (
                <Navigate to={"/"} />
              )
            }
          />
          <Route
            path="/transaction-logs"
            element={
              authStatus.isUser || authStatus.isAdmin ? (
                <TransactionLogs />
              ) : (
                <Navigate to={"/"} />
              )
            }
          />
          {/* ________________________ Protected Route For Admin ________________________ */}
          <Route
            path="/dashboard/cylinder-details"
            element={
              authStatus.isAdmin ? <CylinderDetails /> : <Navigate to={"/"} />
            }
          />
          <Route
            path="/dashboard/passport-details"
            element={
              authStatus.isPassportAdmin ? (
                <PassportDetails />
              ) : (
                <Navigate to={"/"} />
              )
            }
          />
          <Route
            path="/dashboard/master-data"
            element={
              authStatus.isAdmin ? <Datamaster /> : <Navigate to={"/"} />
            }
          />
          <Route
            path="/dashboard/maintenace-histroy"
            element={
              authStatus.isAdmin ? (
                <MaintenanceHistory />
              ) : (
                <Navigate to={"/"} />
              )
            }
          />
          <Route
            path="/dashboard/status-location"
            element={
              authStatus.isAdmin ? <StatusLocation /> : <Navigate to={"/"} />
            }
          />
          <Route
            path="/dashboard/user-management"
            element={
              authStatus.isAdmin ? <UserManagement /> : <Navigate to={"/"} />
            }
          />
          <Route
            path="/dashboard/cylinder-change-request"
            element={
              authStatus.isAdmin ? (
                <UpdateCylinderRequests />
              ) : (
                <Navigate to={"/"} />
              )
            }
          />

          {/* ________________________ Company Creation ________________________ */}
          <Route
            path="/create-company"
            element={
              authStatus.isTag8Admin ? <Company /> : <Navigate to={"/"} />
            }
          />
          <Route
            path="/company/:companyId"
            element={
              authStatus.isTag8Admin ? <Company /> : <Navigate to={"/"} />
            }
          />
          <Route
            path="/company-details"
            element={
              authStatus.isTag8Admin ? (
                <CompanyDetails />
              ) : (
                <Navigate to={"/"} />
              )
            }
          />
        </Routes>
      </Router>
    </>
  );
};

export default App;
