import React, { useState, useEffect } from "react";
import "./cylindermanagement.css";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
// import MaintenanceIcon from "../../assets/maintenance.png";
// import LocationIcon from "../../assets/location.png";
import Gascylinder from "../../assets/gascylinder.png";
import Footer from "../Footer/Footer";

// // Get Bulk Transaction Cards
// const getBulkTransactionCards = async () => {
//   const { data } = await axios.post(
//     `${baseURL}/api/cylinderqr/getAllBulkTransactionCards`,
//     {},
//     {
//       "Content-Type": "application/json",
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem("token")}`,
//       },
//     }
//   );
//   return data;
// };
const CylinderManagement = () => {
  // // QUERY : GET BULK TRANSACTION CARDS
  // const {
  //   data: dataTransactionCard,
  //   error: errorTransactionCard,
  //   isLoading: isLoadingTransactionCard,
  // } = useQuery({
  //   queryKey: ["bulktransactioncards"],
  //   queryFn: getBulkTransactionCards,
  //   refetchOnWindowFocus: false,
  //   staleTime: 1000 * 60 * 5,
  //   retry: 2,
  // });

  // _________________ USE-STATE ______________________________
  const [flag, setFlag] = useState({
    isAdmin:
      localStorage.getItem("role") === "admin" ||
      localStorage.getItem("role") === "superadmin"
        ? true
        : false,
    isHydrotestingUser: false,
    isTransactionUser: false,
  });

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user !== null) {
      const userInfo = JSON.parse(user);

      if (userInfo?.accessActivities?.includes("Transaction")) {
        setFlag((preState) => ({
          ...preState,
          isTransactionUser: true,
        }));
      }
      if (userInfo?.accessActivities?.includes("Hydrotesting")) {
        setFlag((preState) => ({
          ...preState,
          isHydrotestingUser: true,
        }));
      }
    }
  }, []);

  useEffect(() => {
    const navBg = document.getElementsByClassName("bg-body-tertiary");
    Array.from(navBg).forEach((element) => {
      element.classList.add("remove-bg");
    });
    // console.log("navBg: ", navBg);
  }, []);

  return (
    <>
      <div className="cylinder-management-screen">
        <Navbar />
        <div className="container-fluid py-3 d-flex flex-column gap-5">
          <div className="row cylinder-management-row-div">
            <div className="col-12 d-flex align-items-center justify-content-center ">
              <div className="col-4">
                <img
                  src={Gascylinder}
                  alt="cylindermanagement"
                  style={{ width: "4rem" }}
                />
              </div>
              <div className="col-4 d-flex flex-column align-items-center justify-content-center text-bold ">
                <span className="">CYLINDER</span>
                <span className=""> MANAGEMENT</span>
              </div>
              <div className="col-4"></div>
            </div>
          </div>

          <div className="row d-flex align-items-center justify-content-center gap-3">
            {(flag.isAdmin || flag.isTransactionUser) && (
              <Link to={"/cylinder-transaction"} className="col-5 dfcc">
                <span className="fa-20px">Transaction</span>
              </Link>
            )}
            {(flag.isAdmin || flag.isHydrotestingUser) && (
              <Link to={"/cylinder-maintenance"} className="col-5 dfcc">
                <span className="fa-20px">Maintenance</span>
              </Link>
            )}
            {(flag.isAdmin || flag.isTransactionUser) && (
              <Link to={"/transaction-logs"} className="col-5 dfcc flex-column">
                <span className="fa-20px">Transaction</span>
                <span className="fa-20px">Logs</span>
              </Link>
            )}
            {(flag.isAdmin || flag.isHydrotestingUser) && (
              <Link to={"/maintenance-logs"} className="col-5 dfcc flex-column">
                <span className="fa-20px">Maintenance</span>
                <span className="fa-20px">Logs</span>
              </Link>
            )}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default CylinderManagement;
