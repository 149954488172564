import React, { useEffect, useState } from "react";
// import Navbar from "../Navbar/Navbar";
import {
  Button,
  TextField,
  Tab,
  Tabs,
  Snackbar,
  Alert as MuiAlert,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import axios from "axios";
import { switchUrls } from "../../api";
import { useMutation } from "@tanstack/react-query";
import QrReader from "react-qr-reader";
import Gascylinder from "../../assets/gascylinder.png";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
const baseURL = switchUrls("cylinder");

const MaintenanceScan = () => {
  // TEST PARAMETERS STATE & FUNCTIONS
  const [testParameters, setTestParameters] = useState({
    hydrotesting_date: "",
    tare_weight: "",
    test_comment: "",
  });
  const handleChangeTestParameters = (e) => {
    setTestParameters({ ...testParameters, [e.target.name]: e.target.value });
  };

  const handleHydortestingDateChange = (event) => {
    const selectedDate = event.target.value;
    const today = new Date().toISOString().split("T")[0];

    if (selectedDate <= today) {
      setTestParameters({ ...testParameters, hydrotesting_date: selectedDate });
    } else {
      setTestParameters({
        ...testParameters,
        hydrotesting_date: today,
      });
    }
  };

  const [tabValue, setTabValue] = useState(0);
  const handleTabChang = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  const [scanQrCodes, setScanQrCodes] = useState(() => {
    const storedData = localStorage.getItem("scannedTags");
    return storedData ? JSON.parse(storedData) : [];
  });
  const [isScanned, setIsScanned] = useState(false);
  const [isCheckedTagStatus, setIsCheckedTagStatus] = useState(true);
  // const [alreadyScanTag, SetAlreadyScanTag] = useState();
  const [recentScannedTag, setRecentScannedTag] = useState("");
  const onScanResult = (result) => {
    if (result) {
      // console.log("result: ", result);
      const qrCode = result?.split("/scan/")[1];
      if (qrCode) {
        if (!scanQrCodes?.includes(qrCode)) {
          if (isCheckedTagStatus) {
            checkTagStatus(qrCode);
          }
        } else {
          setIsScanned(true);
          // SetAlreadyScanTag(qrCode);
          setTimeout(() => {
            setIsScanned(false);
          }, 1000);
        }
      }
    }
  };

  const onScanError = (error) => {
    if (error) {
      console.info("scan error: ", error);
    }
  };

  // SAVE SCANNED TAG IN LOCAL STORAGE
  useEffect(() => {
    localStorage.setItem("scannedTags", JSON.stringify(scanQrCodes));
    if (scanQrCodes?.length >= 50) {
      setShowLimitAlert(true);
    }
  }, [scanQrCodes]);

  const [showLimitAlert, setShowLimitAlert] = useState(false);
  const [showRemoveTagAlert, setShowRemoveTagAlert] = useState(false);
  const [removingTag, setRemovingTag] = useState("");
  const skipAlert = () => {
    setShowLimitAlert(false);
    setShowRemoveTagAlert(false);
  };

  const handleLongPress = (tag) => {
    console.log("rt: ", tag);
    setRemovingTag(tag);
    setShowRemoveTagAlert(true);
  };

  // REMOVE SCANNED TAG
  const confirmRemoveScannedTag = () => {
    console.log("rt: ", removingTag);
    if (removingTag) {
      setScanQrCodes((preState) => {
        return [...preState].filter((item) => item !== removingTag);
      });
      setTagsToTest((preState) => {
        return [...preState].filter((item) => {
          console.log("ic: ", item.qrCode);
          return item.qrCode !== removingTag;
        });
      });
    }
    setShowRemoveTagAlert(false);
    setRemovingTag("");
  };

  // MUTATION : STATUS & LOCATION
  const { mutate, isPending } = useMutation({
    mutationFn: async (transactionObj) => {
      return await axios.post(
        `${baseURL}/api/cylinderqr/bulkAddMaintenanceDetails`,
        transactionObj,
        {
          "Content-Type": "application/json",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    },
    onSuccess: (data) => {
      // console.log("success", data);
      setSnackbarInfo({
        isOpenClose: true,
        message: "Bulk Maintenance Done",
        color: "success",
      });
      windowsReload();
      localStorage.removeItem("scannedTags");
      localStorage.removeItem("testingTags");
    },
    onError: (error) => {
      setSnackbarInfo({
        isOpenClose: true,
        message: error.response.data.msg,
        color: "error",
      });
    },
  });
  // Get Tag Status
  const { mutate: checkTagStatus } = useMutation({
    mutationFn: async (tagNumber) => {
      setIsCheckedTagStatus(false);
      return await axios.post(`${baseURL}/api/cylinderqr/getTagStatus`, {
        tagNumber,
      });
    },
    onSuccess: (data) => {
      // console.log("data: ", data);
      if (!scanQrCodes?.includes(data?.data?.tagNumber)) {
        setScanQrCodes((preState) => [...preState, data?.data?.tagNumber]);
        setRecentScannedTag(data?.data?.tagNumber);
        setSetselectedTab(1);
        setTabValue(1);
      }
      setTimeout(() => {
        setIsCheckedTagStatus(true);
      }, 300);
    },
    onError: (error) => {
      setSnackbarInfo({
        isOpenClose: true,
        message: error.response.data.msg,
        color: "error",
      });
      setTimeout(() => {
        setIsCheckedTagStatus(true);
      }, 1500);
    },
  });

  //  SNACKBAR
  const [snackbarInfo, setSnackbarInfo] = useState({
    isOpenClose: false,
    message: "",
    color: "",
  });
  const handleCloseSnackbar = () => {
    setSnackbarInfo({
      isOpenClose: false,
    });
  };
  const autoCloseSnackbar = () => {
    setSnackbarInfo({
      isOpenClose: false,
    });
  };

  const windowsReload = () => {
    window.location.href = `/cylinder-management`;
  };

  const addBulkTransaction = () => {
    const { hydrotesting_date, test_comment } = testParameters;

    if (!hydrotesting_date || !test_comment) {
      setSnackbarInfo({
        isOpenClose: true,
        message: "Please Fill the Maintenance Value",
        color: "error",
      });
    } else if (!tagsToTest.length > 0) {
      setSnackbarInfo({
        isOpenClose: true,
        message: "Please Scan QR Code & Add Tare weight",
        color: "error",
      });
    } else {
      const obj = {
        qrCodesTareweight: tagsToTest,
        hydrotestDate: hydrotesting_date,
        testComment: test_comment,
      };
      mutate(obj);
    }
  };

  const [setselectedTab, setSetselectedTab] = useState(0);
  const handleTabClick = (v) => {
    if (v === 0) {
      setSetselectedTab(0);
    } else if (v === 1) {
      setSetselectedTab(1);
    }
  };

  const [tagsToTest, setTagsToTest] = useState(() => {
    const storedData = localStorage.getItem("testingTags");
    return storedData ? JSON.parse(storedData) : [];
  });

  useEffect(() => {
    localStorage.setItem("testingTags", JSON.stringify(tagsToTest));
  }, [tagsToTest]);
  const clickToNextScan = (v) => {
    if (!testParameters.tare_weight) {
      setSnackbarInfo({
        isOpenClose: true,
        message: "Please Fill the Tare Weight",
        color: "error",
      });
    } else {
      let obj = {
        qrCode: recentScannedTag,
        tareWeight: testParameters.tare_weight,
      };
      setTagsToTest((preState) => [...preState, obj]);
      setRecentScannedTag("");
      setTestParameters({ ...testParameters, tare_weight: "" });
      setSetselectedTab(0);
      setTabValue(0);
    }
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  // console.log("testParameters: ", testParameters);
  return (
    <>
      {/* <Navbar />  */}
      <div className="transaction-main-div">
        <div className="container-fluid" style={{ marginTop: "1.2rem" }}>
          <div className="row cylinder-management-row-div">
            <div className="col-12 d-flex align-items-center justify-content-center ">
              <div className="col-4">
                <Link to={"/cylinder-management"}>
                  <img
                    src={Gascylinder}
                    alt="cylindermanagement"
                    style={{ width: "4rem" }}
                  />
                </Link>
              </div>
              <div className="col-4 d-flex flex-column align-items-center justify-content-center text-bold ">
                <span className="">CYLINDER</span>
                <span className=""> MANAGEMENT</span>
              </div>
              <div className="col-4"></div>
            </div>
          </div>
          <div className="row" style={{ color: "black", zIndex: 999 }}>
            <Tabs
              variant="fullWidth"
              value={tabValue}
              onChange={handleTabChang}
              style={{ padding: 0 }}
            >
              <Tab
                label="Scan"
                value={0}
                className={`tab-btn0 ${
                  setselectedTab === 0 ? "bg-tag8-clr" : ""
                }`}
                onClick={() => handleTabClick(0)}
              />
              <Tab
                label="Maintenance"
                value={1}
                className={`tab-btn1 ${
                  setselectedTab === 1 ? "bg-tag8-clr" : ""
                }`}
                onClick={() => handleTabClick(1)}
              />
            </Tabs>
          </div>
          {/* transaction */}
          {tabValue === 0 && (
            <div className="row">
              <div className="col-12 mx-auto">
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <span className="">
                    Scan:{" "}
                    <span className="text-secondary">{scanQrCodes.length}</span>
                  </span>
                </div>
                <div className="scanner-main-div">
                  <div className="scanner-div">
                    <QrReader
                      delay={100}
                      onScan={onScanResult}
                      onError={onScanError}
                      legacyMode={false}
                      facingMode={"environment"}
                    />
                  </div>
                  {isScanned && (
                    <div className="scanned-message">
                      <h6 className="text-danger">Tag Already Scanned</h6>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {/* Scan */}
          {tabValue === 1 && (
            <>
              <div className="row py-2">
                <div className="col-12 d-flex flex-column align-items-center justify-content-center mt-4">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "2rem",
                    }}
                  >
                    {/*  Tare Weight */}

                    {recentScannedTag && (
                      <div className="recent-maintenance-main-div">
                        <div className="recent-maintenance-div">
                          <div className="recent-scanTag-div">
                            <TextField
                              label="Recent Scan Tag"
                              type="text"
                              variant="outlined"
                              value={recentScannedTag}
                              InputProps={{ readOnly: true }}
                            />
                          </div>
                          <div className="recent-tareWeight-div">
                            <TextField
                              label="Tare Weight (Kgs) *"
                              type="number"
                              variant="outlined"
                              name="tare_weight"
                              value={testParameters.tare_weight}
                              inputProps={{ maxLength: 3 }}
                              onChange={handleChangeTestParameters}
                            />
                          </div>
                        </div>
                        <div className="recent-addBtn-div">
                          <Button
                            type="button"
                            variant="contained"
                            className="test-add-btn mt-2"
                            onClick={clickToNextScan}
                          >
                            Scan Next
                          </Button>
                        </div>
                      </div>
                    )}
                    {scanQrCodes.length !== 0 && (
                      <div className="table-div">
                        <table className="">
                          <tr>
                            <th>Sr No</th>
                            <th className="">Qr Code</th>
                            <th className="">Tare Weight</th>
                          </tr>
                          {tagsToTest.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td className="">{item.qrCode}</td>
                                <td className="">{item.tareWeight}</td>
                                <td
                                  className="cursor-pointer"
                                  onClick={() => handleLongPress(item.qrCode)}
                                >
                                  <Close color="error" />
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      </div>
                    )}

                    {/* Hydrotesting Date and Test message */}
                    <div className="two-field-wrapper mb-4">
                      <div className="mui-text-field">
                        <TextField
                          label="Hydrotesting Date *"
                          variant="outlined"
                          type="date"
                          name="hydrotesting_date"
                          value={testParameters.hydrotesting_date}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            max: new Date().toISOString().split("T")[0],
                          }}
                          onChange={handleHydortestingDateChange}
                        />
                      </div>
                      <div
                        className="mui-text-field"
                        style={{ position: "relative" }}
                      >
                        <TextField
                          label="Test Comment"
                          type="text"
                          variant="outlined"
                          name="test_comment"
                          value={testParameters.test_comment}
                          inputProps={{ maxLength: 40 }}
                          onChange={handleChangeTestParameters}
                        />
                        <span
                          style={{
                            position: "absolute",
                            bottom: "-2px",
                            right: "2px",
                            color: "gray",
                          }}
                        >{`${testParameters.test_comment.length}/40`}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* {scanQrCodes.length !== 0 && (
                <div className="row bt-gray mt-2">
                  <div className=" d-flex flex-column align-items-center justify-content-center py-2">
                    <h6 className="text-center text-decoration-underline">
                      Scanned Tagged
                    </h6>
                    <div className={`scroll-container scan-tag-div `}>
                      <div className="scan-tags-wrapped">
                        {scanQrCodes.map((tag, index) => {
                          return (
                            <h6
                              className={`scan-tag-number ${
                                isScanned && alreadyScanTag === tag
                                  ? "bg-tag"
                                  : ""
                              } ${scanQrCodes.length < 4 ? "bb-1" : ""}`}
                              key={index}
                            >
                              <span className="dfjcac gap-4">
                                <span
                                  className=""
                                  style={{ width: "2rem", height: "1rem" }}
                                >
                                  {index + 1}
                                </span>
                                <span className="">{tag}</span>
                              </span>
                              <span
                                className="cursor-pointer"
                                onClick={() => handleLongPress(tag)}
                              >
                                <Close color="error" />
                              </span>
                            </h6>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )} */}

              <Button
                type="button"
                variant="contained"
                className="test-add-btn mt-2"
                disabled={isPending}
                onClick={addBulkTransaction}
              >
                Proceed
              </Button>
            </>
          )}
        </div>
        <Footer />
      </div>

      {/* Snack Bar */}
      <Snackbar
        open={snackbarInfo.isOpenClose}
        onClose={autoCloseSnackbar}
        autoHideDuration={5000}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        style={{ top: "25%" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarInfo.color}
          sx={{ width: "100%" }}
        >
          {snackbarInfo.message}
        </Alert>
      </Snackbar>

      {showLimitAlert && (
        <div className="loader-container ">
          <div
            className="loader d-flex flex-column align-items-center justify-content-center border rounded-3 p-3 "
            style={{ background: "#f5f5f5" }}
          >
            <h5 className="">The scan tag limit has been exceeded</h5>
            <div className="d-flex flex-row align-items-center justify-content-center mt-2 gap-4">
              <span
                className="cursor-pointer p-2 border rounded-3 text-center"
                onClick={skipAlert}
                style={{ width: "5rem", background: "#f3e0e0" }}
              >
                Cancel
              </span>
              <span
                className="cursor-pointer p-2 border rounded-3 text-center"
                onClick={skipAlert}
                style={{ width: "5rem", background: "#d8ffd8" }}
              >
                OK
              </span>
            </div>
          </div>
        </div>
      )}
      {showRemoveTagAlert && (
        <div className="loader-container ">
          <div
            className="loader d-flex flex-column align-items-center justify-content-center border rounded-3 p-3 "
            style={{ background: "#f5f5f5" }}
          >
            <h5 className="">Are you sure ? you want to remove the Tag</h5>
            <div className="d-flex flex-row align-items-center justify-content-center mt-2 gap-4">
              <span
                className="cursor-pointer p-2 border rounded-3 text-center"
                onClick={skipAlert}
                style={{ width: "5rem", background: "#f3e0e0" }}
              >
                Cancel
              </span>
              <span
                className="cursor-pointer p-2 border rounded-3 text-center"
                onClick={confirmRemoveScannedTag}
                style={{ width: "5rem", background: "#d8ffd8" }}
              >
                Ok
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MaintenanceScan;
