import React, { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Delete, Edit } from "@mui/icons-material";
import "../CylinderDetails/cylinderdetails.css";
import Navbar from "../../Navbar/Navbar";
const PassportDetails = () => {
  const ActionBtnRenderer = ({ value }) => {
    const editDetails = () => {
      console.log("id: ", value);
    };
    const deleteDetails = () => {
      console.log("id: ", value);
    };
    return (
      <p>
        <span onClick={editDetails}>
          <Edit color="success" className="cursor-pointer me-2" />
        </span>
        <span onClick={deleteDetails}>
          <Delete color="error" className="cursor-pointer ms-2" />
        </span>
      </p>
    );
  };

  const columnDefs = [
    { headerName: "tag_number", field: "tag_number" },
    { headerName: "Full Name", field: "fullName" },
    { headerName: "Phone", field: "phone" },
    { headerName: "City", field: "city" },
    { headerName: "Order Number", field: "orderNumber" },
    { headerName: "Certificate Start Date", field: "certificateStartDate" },
    { headerName: "Certificate End Date", field: "certificateEndDate" },
    { headerName: "Extension Of Travel Days", field: "extensionOfTravelDays" },
    { headerName: "Destination", field: "destination" },
    { headerName: "Departure Date", field: "departuredate" },
    {
      headerName: "Actions",
      field: "actions",
      cellRenderer: ActionBtnRenderer,
    },
  ];
  // eslint-disable-next-line
  const [rowData, setRowData] = useState([
    {
      tag_number: "CYLD785",
      fullName: "10001",
      phone: "Medical Cylinder",
      city: "Everest Canto",
      orderNumber: "Plant 2",
      certificateStartDate: "25 Aug, 2016",
      certificateEndDate: "45.86",
      extensionOfTravelDays: "Saki Naka",
      destination: "Filled",
      departuredate: "In Plant",
    },
  ]);
  return (
    <>
      <Navbar />
      {/* ______________ Data Grid ______________ */}
      <div className="data-grid-main-div d-flex justify-content-center align-content-center">
        <div
          className="ag-theme-alpine data-grid-div"
          style={{ height: "33.5rem", width: "99%" }}
        >
          <AgGridReact
            columnDefs={columnDefs}
            rowData={rowData}
            pagination={true}
            paginationPageSize={10}
            defaultColDef={{
              flex: 1,
              resizable: true,
            }}
            overlayLoadingTemplate={
              '<span className="ag-overlay-loading-center">Please wait while your rows are loading...</span>'
            }
            overlayNoRowsTemplate={
              '<span className="ag-overlay-loading-center">No data found to display.</span>'
            }
          />
        </div>
      </div>
    </>
  );
};

export default PassportDetails;
