export const APP_ENV = "live"; // local or staging or live

const urlLookUp = {
  cylinder: {
    local: "http://localhost:3386",
    staging: "https://adminportal.tag8.co.in",
    live: "https://adminportal.tag8.in",
  },
};

export const switchUrls = (domain) => {
  switch (APP_ENV) {
    case "local":
      return urlLookUp[domain][APP_ENV];
    case "staging":
      return urlLookUp[domain][APP_ENV];
    case "live":
      return urlLookUp[domain][APP_ENV];
    default:
      break;
  }
};
