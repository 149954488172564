import React, { useEffect, useState } from "react";
import { DialogContent, Drawer, Fab, Modal, Avatar } from "@mui/material";
import {
  LastPage as LastPageIcon,
  FirstPage as FirstPageIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import "./navbar.css";
import Login from "../Login/Login";
import { useQuery } from "@tanstack/react-query";
import { switchUrls } from "../../api";
const baseURL = switchUrls("cylinder");
const navDetails = [
  {
    title: "Cylinder Details",
    path: "/dashboard/cylinder-details",
    // icon: <BsIcons.BsLockFill />,
    cName: "nav-text",
  },
  {
    title: "Maintenance Histroy",
    path: "/dashboard/maintenace-histroy",
    // icon: <BiIcons.BiTrip />,
    cName: "nav-text",
  },
  {
    title: "Status & Location",
    path: "/dashboard/status-location",
    // icon: <BiIcons.BiTrip />,
    cName: "nav-text",
  },
  {
    title: "Master Data",
    path: "/dashboard/master-data",
    // icon: <BiIcons.BiTrip />,
    cName: "nav-text",
  },
  {
    title: "User Management",
    path: "/dashboard/user-management",
    // icon: <BiIcons.BiTrip />,
    cName: "nav-text",
  },
  {
    title: "Change Request",
    path: "/dashboard/cylinder-change-request",
    // icon: <BiIcons.BiTrip />,
    cName: "nav-text",
  },
];

const tag8AdminNav = [
  {
    title: "Company Details",
    path: "/company-details",
    cName: "nav-text",
  },
  {
    title: "Create Company",
    path: "/create-company",
    cName: "nav-text",
  },
];

const getUser = async (email) => {
  const { data } = await axios.post(
    `${baseURL}/api/cylinderqr/profile`,
    {
      email,
    },
    {
      "Content-Type": "application/json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return data;
};

const Navbar = ({ isScanPage, clickToLogin }) => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const [flag, setFlag] = useState({
    openLoginModal: false,
    isAdmin:
      localStorage.getItem("token") &&
      localStorage.getItem("role") !== "user" &&
      localStorage.getItem("companyType") === "Cylinder"
        ? true
        : false,
    isTag8Admin:
      localStorage.getItem("token") &&
      localStorage.getItem("role") === "tag8admin" &&
      localStorage.getItem("companyType") === "Tag8"
        ? true
        : false,
    isAuth: localStorage.getItem("token") ? true : false,
    isUser: localStorage.getItem("role") === "user" ? true : false,
  });

  const closeDrawer = () => {
    setOpenDrawer(!openDrawer);
  };
  const handleCloseModal = () => {
    setFlag((preState) => ({
      ...preState,
      openLoginModal: false,
    }));
  };

  useEffect(() => {
    setFlag((preState) => ({
      ...preState,
      isAdmin:
        localStorage.getItem("token") &&
        localStorage.getItem("role") !== "user" &&
        localStorage.getItem("companyType") === "Cylinder"
          ? true
          : false,
      isAuth: localStorage.getItem("token") ? true : false,
      isUser:
        localStorage.getItem("token") && localStorage.getItem("role") === "user"
          ? true
          : false,
    }));
  }, [flag.isAdmin, flag.isAuth]);
  const logoutUser = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("email");
    localStorage.removeItem("role");
    localStorage.removeItem("companyId");
    localStorage.removeItem("companyType");
    console.log("Log Out User");
    window.location.reload();
  };

  useEffect(() => {
    const usertoken = localStorage.getItem("token");
    let interval;
    if (usertoken) {
      interval = setInterval(() => {
        let decoded = JSON.parse(atob(usertoken.split(".")[1]));
        const expiry = decoded.exp;
        let currentTime = Date.now() / 1000;
        if (currentTime >= expiry) {
          logoutUser();
        }
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  });

  const [userInfo, setUserInfo] = useState({
    email: localStorage.getItem("email"),
    name: "",
    role: "",
    accessActivities: [],
    companyImgUrl: "",
  });

  // QUERY : Get User Data
  const { data, error, isLoading } = useQuery({
    queryKey: ["user"],
    queryFn: () => getUser(userInfo.email),
    enabled: !!userInfo.email,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
    retry: 2,
  });

  // USEEFFECT : USER DETAILS
  useEffect(() => {
    if (error) {
      console.log("error: ", error);
    }
    if (!isLoading && !error) {
      if (data) {
        // console.log("data: ", data);
        setUserInfo(() => ({
          name:
            data?.data?.role === "superadmin" ||
            data?.data?.role === "tag8admin"
              ? data?.data?.name
              : data?.data?.firstName,
          email: data?.data?.email,
          role: data?.data?.role,
          accessActivities:
            data?.data?.role !== "superadmin"
              ? data?.data?.accessActivities
              : [],
          companyImgUrl: data?.data?.companyImgUrl,
        }));
      }
    }
  }, [isLoading, error, data]);

  const [isHide, setIsHide] = useState(true);
  const showUserInfo = () => {
    setIsHide(!isHide);
  };

  useEffect(() => {
    // console.log("clickToLogin", clickToLogin);
    setFlag((preState) => ({
      ...preState,
      openLoginModal: clickToLogin === undefined ? false : true,
    }));
  }, [clickToLogin]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    bgcolor: "background.paper",
    border: "2px solid #ffc107",
    borderRadius: "5px",
    boxShadow: 24,
    padding: 0,
  };

  // console.log("flag: ", flag, isScanPage);
  return (
    <>
      <div className="container-navbar">
        <nav className="navbar bg-body-tertiary">
          <div className="container-fluid">
            <div className="sidebar-logo-div d-flex flex-row gap-3">
              <div onClick={closeDrawer} className="drawer-btn">
                {flag.isAdmin || flag.isTag8Admin ? (
                  <LastPageIcon fontSize="large" />
                ) : null}
              </div>
              <Link className="logo-div" to={"/"}>
                <img
                  className="nav-logo-img"
                  alt="logo"
                  src="https://storage.googleapis.com/pettag/qr/assets/Tag8-Logo-slst--black.png"
                />
              </Link>
            </div>
            <div className="logout-div d-flex align-items-center">
              {flag.isAuth && (
                <div className="pos-relative">
                  <Avatar
                    alt="companyLogo"
                    sx={{ bgcolor: "#febd1f" }}
                    src={`${
                      userInfo.companyImgUrl ? userInfo.companyImgUrl : ""
                    }`}
                    className="avatar"
                    onClick={showUserInfo}
                  >
                    {userInfo.name?.slice(0, 1)}
                  </Avatar>

                  <div
                    className={`pos-absolute ${isHide ? "d-hide" : "d-show"}`}
                  >
                    <div className="p-3">
                      <div className="d-flex flex-row gap-2 py-2">
                        <span className="text-secondary">
                          {userInfo.role === "superadmin" ||
                          userInfo.role === "tag8admin"
                            ? "Company"
                            : "Name"}
                          :{" "}
                        </span>
                        <span className="">{userInfo.name}</span>
                      </div>
                      <div className="d-flex flex-row gap-2 py-2">
                        <span className="text-secondary">Email: </span>
                        <span className="">{userInfo.email}</span>
                      </div>
                      <div className="d-flex flex-row gap-2 py-2">
                        <span className="text-secondary">Role: </span>
                        <span className="">{userInfo.role}</span>
                      </div>
                      {userInfo.role === "user" && (
                        <div className="d-flex flex-row gap-2 py-2">
                          <span className="text-secondary">Access: </span>
                          <div className="d-flex flex-column align-items-start justify-content-center">
                            {userInfo.accessActivities.map((item) => {
                              return (
                                <span className="activity-text" key={item}>
                                  {item},{" "}
                                </span>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="logout-btn-div" onClick={logoutUser}>
                      LOGOUT
                    </div>
                  </div>
                </div>
              )}
              {isScanPage && !flag.isAuth && (
                <Fab
                  className="edit-btn ms-1"
                  onClick={() => {
                    setFlag((preState) => ({
                      ...preState,
                      openLoginModal: preState.isAuth ? false : true,
                    }));
                  }}
                >
                  <EditIcon />
                </Fab>
              )}
              {isScanPage && !flag.isAuth && (
                <Fab className="edit-btn ms-1">
                  <a className="anchor-tag" href="mailto:pankajx404@gmail.com">
                    <img
                      alt="contact_email"
                      className="email-img-vector"
                      src={
                        "https://storage.googleapis.com/pettag/qrtagdev/assets/genievectors/email.png"
                      }
                    />
                  </a>
                </Fab>
              )}
            </div>
          </div>
        </nav>
      </div>

      <Drawer open={openDrawer} onClose={closeDrawer}>
        <div className="drawer-div">
          <div
            onClick={closeDrawer}
            className="drawer-btn d-flex justify-content-end "
          >
            <FirstPageIcon fontSize="large" />
          </div>
          {flag.isAdmin &&
            navDetails.map((item, index) => (
              <ul className="navbar-nav drawer-ul" key={index}>
                <li className="nav-item">
                  {/* <span className="nav-text-icon">{item.icon}</span> */}
                  <Link
                    className="nav-link active"
                    aria-current="page"
                    to={item.path}
                  >
                    {item.title}
                  </Link>
                </li>
              </ul>
            ))}
          {flag.isTag8Admin &&
            tag8AdminNav.map((item, index) => (
              <ul className="navbar-nav drawer-ul" key={index}>
                <li className="nav-item">
                  {/* <span className="nav-text-icon">{item.icon}</span> */}
                  <Link
                    className="nav-link active"
                    aria-current="page"
                    to={item.path}
                  >
                    {item.title}
                  </Link>
                </li>
              </ul>
            ))}
        </div>
      </Drawer>

      {/* _____________________* LOGIN MODAL *_____________________ */}
      <Modal
        open={flag.openLoginModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogContent sx={style}>
          <Login />
          <div className="close-btn-div" onClick={handleCloseModal}>
            <CloseIcon className="close-btn" />
          </div>
        </DialogContent>
      </Modal>
    </>
  );
};

export default Navbar;
