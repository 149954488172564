import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Gascylinder from "../../assets/gascylinder.png";
import {
  styled,
  Paper,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Button,
} from "@mui/material";
import { PinDrop, ExpandMore } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Parser } from "@json2csv/plainjs";

import { switchUrls } from "../../api";
import { Link } from "react-router-dom";
const baseURL = switchUrls("cylinder");

// // Get ALL Transaction Details
const getAllTransactions = async () => {
  const { data } = await axios.post(
    `${baseURL}/api/cylinderqr/getBulkTransactions`,
    {},
    {
      "Content-Type": "application/json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return data;
};
const TransactionLogs = () => {
  // // QUERY : GET ALL TRANSACTIONS DETAILS
  const {
    data: dataAllTransactions,
    error: errorAllTransactions,
    isLoading: isLoadingAllTransactions,
  } = useQuery({
    queryKey: ["AllTransactions"],
    queryFn: getAllTransactions,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
    retry: 2,
  });

  const [statusLocation, setStatusLocation] = useState([]);

  // USEEFFECT : all Transactions Details
  useEffect(() => {
    if (errorAllTransactions) {
      console.log("error: ", errorAllTransactions);
    }
    if (!isLoadingAllTransactions && !errorAllTransactions) {
      // console.log("All Transactions: ", dataAllTransactions.data);
      if (dataAllTransactions?.data?.length > 0) {
        setStatusLocation(dataAllTransactions?.data);
      }
    }
  }, [isLoadingAllTransactions, errorAllTransactions, dataAllTransactions]);

  // FOR STATUS LOCATION
  const toReadableDateTime = (st) => {
    if (typeof st !== "string") return null;

    const [date, time] = st?.split("T");

    if (!time && date) return date.split("-").reverse().join("-");

    if (!date || !time) return null;

    return `${date.split("-").reverse().join("-")} ${time.slice(0, 5)}`;
  };

  const convertToCSV = (json) => {
    const opts = {
      fields: [
        {
          label: "Sr No",
          value: "srNo",
        },
        {
          label: "Qr Code",
          value: "qrCode",
        },
        {
          label: "Cylinder Code",
          value: "cylinderCode",
        },
        {
          label: "Time Stamp",
          value: "createDate",
        },
        {
          label: "Location",
          value: "toLocation",
        },
        {
          label: "Status",
          value: "cylinderStatus",
        },
        {
          label: "Transaction Id",
          value: "transactionId",
        },
        {
          label: "Transaction By",
          value: "updatedBy",
        },
      ],
    };
    const parser = new Parser(opts);
    const csv = parser.parse(json);
    return csv;
  };
  const onclickDownloadTransaction = (json) => {
    let i = 0;
    json?.map((item) => (item.srNo = ++i));
    const csv = convertToCSV(json);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "transaction.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <>
      <div className="">
        <Navbar />
        <div className="container-fluid py-3 d-flex flex-column gap-2">
          <div className="row cylinder-management-row-div">
            <div className="col-12 d-flex align-items-center justify-content-center ">
              <div className="col-4">
                <Link to={"/cylinder-management"}>
                  <img
                    src={Gascylinder}
                    alt="cylindermanagement"
                    style={{ width: "4rem" }}
                  />
                </Link>
              </div>
              <div className="col-4 d-flex flex-column align-items-center justify-content-center text-bold ">
                <span className="">CYLINDER</span>
                <span className=""> MANAGEMENT</span>
              </div>
              <div className="col-4"></div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-end">
            <div className="d-flex flex-column align-items-start justify-content-center">
              <div className="d-flex align-items-center justify-content-center gap-1">
                <div className="box-gray"></div>
                <span className="">Single</span>
              </div>
              <div className="d-flex align-items-center justify-content-center gap-1">
                <div className="box-green"></div>
                <span className="">Bulk</span>
              </div>
            </div>
          </div>

          <div className="row d-flex align-items-center justify-content-center">
            {/* <div className="col-12 col-md-6"> */}
            {!statusLocation?.length > 0 ? (
              <p className="text-muted text-center">No Logs </p>
            ) : (
              <>
                {statusLocation?.map((item, index) => {
                  if (item.length > 1) {
                    return (
                      <Accordion key={index} className="bulk-transaction">
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          <div className="location-ifo">
                            <span className="text-content-lebal">
                              {item[0]?.location === "In Plant" ? "IN" : "OUT"}{" "}
                              :{" "}
                            </span>
                            <span className="text-content">
                              {toReadableDateTime(item[0]?.createDate)}
                            </span>
                            <span className="text-content-lebal ms-2">
                              Status :{" "}
                            </span>
                            <span className="">{item[0]?.cylinderStatus}</span>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          {item?.map((val, i) => {
                            return (
                              <Stack spacing={0.2} key={i}>
                                <Item className="item-card-bulk">
                                  <div className="transaction-info d-flex flex-column justify-content-between  gap-2">
                                    <div className="location-ifo">
                                      <span className="text-content-lebal">
                                        {val?.location === "In Plant"
                                          ? "IN"
                                          : "OUT"}{" "}
                                        :{" "}
                                      </span>
                                      <span className="text-content">
                                        {toReadableDateTime(val.createDate)}
                                      </span>
                                      <span className="text-content-lebal ms-2">
                                        Status :{" "}
                                      </span>
                                      <span className="">
                                        {val.cylinderStatus}
                                      </span>
                                    </div>

                                    <div className="location-info">
                                      <span className="text-content-lebal">
                                        Qr Code :{" "}
                                      </span>
                                      <span className="text-content">
                                        {val.qrCode}
                                      </span>
                                      <span className="text-content ms-2">
                                        <PinDrop />
                                      </span>
                                      <span className="text-content ms-1">
                                        {val.toLocation}
                                      </span>
                                    </div>
                                  </div>
                                </Item>
                              </Stack>
                            );
                          })}
                        </AccordionDetails>
                        <AccordionActions>
                          <Button
                            onClick={() => onclickDownloadTransaction(item)}
                          >
                            Download
                          </Button>
                        </AccordionActions>
                      </Accordion>
                    );
                  } else if (item.length === 1) {
                    return (
                      <>
                        {item?.map((val, i) => {
                          return (
                            <Stack
                              className="single-transaction"
                              spacing={0.2}
                              key={i}
                            >
                              <Item className="item-card-single">
                                <div className="transaction-info d-flex flex-column justify-content-between  gap-2">
                                  <div className="location-ifo">
                                    <span className="text-content-lebal">
                                      {val?.location === "In Plant"
                                        ? "IN"
                                        : "OUT"}{" "}
                                      :{" "}
                                    </span>
                                    <span className="text-content">
                                      {toReadableDateTime(val.createDate)}
                                    </span>
                                    <span className="text-content-lebal ms-2">
                                      Status :{" "}
                                    </span>
                                    <span className="">
                                      {val.cylinderStatus}
                                    </span>
                                  </div>

                                  <div className="location-info">
                                    <span className="text-content-lebal">
                                      Qr Code :{" "}
                                    </span>
                                    <span className="text-content">
                                      {val.qrCode}
                                    </span>
                                    <span className="text-content ms-2">
                                      <PinDrop />
                                    </span>
                                    <span className="text-content ms-1">
                                      {val.toLocation}
                                    </span>
                                  </div>
                                </div>
                              </Item>
                            </Stack>
                          );
                        })}
                      </>
                    );
                  }
                })}
              </>
            )}
            {/* </div> */}
          </div>
          <Footer />
        </div>
      </div>

      {/* Loader */}
      {isLoadingAllTransactions && (
        <div className="loader-container">
          <div className="loader">
            <img
              src={"https://storage.googleapis.com/pettag/qr/assets/loader.gif"}
              alt="Loading..."
              loading="lazy"
              style={{ width: "100px", height: "100px" }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default TransactionLogs;
